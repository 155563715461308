import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { VStack, Heading, HStack, Flex, Box } from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  useColorMode,
  Stat,
  StatLabel,
  StatNumber,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { useAdvanceUserStore } from "../../../../store/user-advance.store";
import dayjs from "dayjs";
import { Pagination } from "../../../../components/pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { ArrowDown, ArrowUp, X } from "@phosphor-icons/react";
import { convertCentsToReais } from "../../../../utils/convert-money";
import { LoadingLogo } from "../../../../components/loading";
import "react-day-picker/dist/style.css";
import { formatCPF } from "../../../../utils/format-document";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../services/basicService";
import { useExportCsv } from "../../../../hooks/adiantamento/use-export-csv";
import { ExportOfx } from "./components/export-ofx";

const startHours = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const endHours = startHours.slice();
endHours.shift();
endHours.push("23:59");

const filterAdvanceFormSchema = z
  .object({
    cpf: z.string().max(11, "O CPF deve ter 11 caracteres"),
    startHour: z
      .string()
      .refine(
        (value) =>
          value === "" || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
      ),
    endHour: z
      .string()
      .refine(
        (value) =>
          value === "" || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
      ),
    company: z.union([z.string(), z.array(z.string())]),
  })
  .refine(
    ({ startHour, endHour }) => {
      if (startHour === "" && endHour === "") {
        return true;
      }
      const startTime = new Date(`2000-01-01T${startHour}:00`);
      const endTime = new Date(`2000-01-01T${endHour}:00`);
      return startTime <= endTime;
    },
    {
      message: `O horário de encerramento deve ser maior do que o horário inicial para aplicar o filtro.`,
      path: ["endHour"],
    }
  );

type filterAdvanceFormInputs = z.infer<typeof filterAdvanceFormSchema>;

export function CreditManagement() {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [range, setRange] = useState<DateRange | undefined>();
  const [rangeDue, setRangeDue] = useState<DateRange | undefined>();

  const [isOrderByDesc, setisOrderByDesc] = useState<boolean>(true);

  const { exportCsv, loading: loadingExportCsv } = useExportCsv();

  const advanceUserData = useAdvanceUserStore((state) => state.advanceUserData);

  const getAdvanceUserdata = useAdvanceUserStore(
    (state) => state.getAdvanceUserData
  );

  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  const toast = useToast();

  const fetchAdvanceData = async () => {
    const dataForm = getValues();
    const filters = urlGenerate(dataForm);
    try {
      const advances = await getAdvanceUserdata(
        `/api/v1/userAdvance/proposal?${filters}`
      );
      return advances;
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Não foi possível buscar os dados de adiantamentos",
      });
    }
  };

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["advances", page, limit, isOrderByDesc, range, rangeDue],
    queryFn: fetchAdvanceData,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as { name: string; id: string }[];
    },
  });

  const handleItemsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
    setPage(1);
    setisOrderByDesc(true);
  };

  const handleFilterCleaning = async () => {
    setRange(undefined);
    setRangeDue(undefined);
    reset();
    setLimit(10);
    setisOrderByDesc(true);
  };

  const urlGenerate = (data: filterAdvanceFormInputs) => {
    const params = [];

    params.push(`status=approved`);

    if (data.cpf) {
      params.push(`cpf=${data.cpf}`);
    }

    if (data.startHour) {
      params.push(`startHour=${data.startHour}`);
    }

    if (data.endHour) {
      params.push(`endHour=${data.endHour}`);
    }

    if (rangeDue?.from !== undefined) {
      params.push(`startDayDue=${rangeDue.from?.toISOString()}`);
    }

    if (rangeDue?.to !== undefined) {
      params.push(`endDayDue=${dayjs(rangeDue.to).toISOString()}`);
    }

    if (rangeDue?.to === undefined && rangeDue?.from !== undefined) {
      params.push(`endDayDue=${dayjs(rangeDue.from).toISOString()}`);
    }

    if (range) {
      params.push(`startDay=${range.from?.toISOString()}`);
    }

    if (range?.to) {
      let date = range.to.toISOString();

      if (data.startHour === "") {
        date = dayjs(range.to).toISOString();
      }

      params.push(`endDay=${date}`);
    }

    if (range?.to === undefined && range?.from !== undefined) {
      let date = range.from.toISOString();

      if (data.startHour === "") {
        date = dayjs(range.from).toISOString();
      }

      params.push(`endDay=${date}`);
    }

    if (data.company) {
      params.push(`companies=${data.company}`);
    }

    let url = `page=${page}&limit=${limit}`;

    if (isOrderByDesc) {
      url += `&orderBy=DESC`;
    } else {
      url += `&orderBy=ASC`;
    }

    url += `&${params.join("&")}`;

    return url;
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm<filterAdvanceFormInputs>({
    resolver: zodResolver(filterAdvanceFormSchema),
  });

  return (
    <VStack gap={8} w="full" alignItems="flex-start" marginTop={8}>
      {isLoading ? (
        <LoadingLogo />
      ) : (
        <>
          <Flex gap={8}>
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize="xl">Total de registros:</StatLabel>
                <StatNumber fontSize="3xl" color="green.500">
                  {advanceUserData.totalRecords}
                </StatNumber>
              </Stat>
            </Box>
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize="xl">Valor total: </StatLabel>
                <StatNumber fontSize="3xl" color="green.500">
                  {convertCentsToReais(advanceUserData.totalAmount)}
                </StatNumber>
              </Stat>
            </Box>

            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize="xl">Valor creditado: </StatLabel>
                <StatNumber fontSize="3xl" color="green.500">
                  {convertCentsToReais(advanceUserData.totalAmountCredited)}
                </StatNumber>
              </Stat>
            </Box>

            <Button
              role="a"
              size="lg"
              color="white"
              onClick={() => navigate("/portfolio-management/conciliacao")}
              alignSelf="center"
            >
              Conciliação
            </Button>
            <Flex alignItems="center" gap={15}>
              <ExportOfx />
              <Button
                isLoading={loadingExportCsv}
                loadingText="Exportando"
                disabled={loadingExportCsv}
                role="a"
                size="lg"
                color="white"
                onClick={() =>
                  exportCsv(
                    "/api/v1/userAdvance/support/csv",
                    "adiantamentos",
                    urlGenerate(getValues())
                  )
                }
              >
                Exportar CSV
              </Button>
              <Tooltip
                hasArrow
                placement="top"
                label="Os dados exportados serão baseados no filtro dessa página"
                fontSize="md"
              >
                <Icon fontSize={20} color="black" />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex
            w="full"
            flex={1}
            p={8}
            gap={4}
            display="flex"
            flexDir="column"
            border="1px"
            borderRadius={8}
            bg={colorMode === "dark" ? "gray.700" : "whiteAlpha.800"}
            borderColor={colorMode === "dark" ? "" : "gray.300"}
            shadow="base"
          >
            <Flex
              as="form"
              flexDir="column"
              gap={4}
              id="form"
              w="full"
              onSubmit={handleSubmit(() => {
                setPage(1);
                refetch();
              })}
            >
              <Heading
                fontWeight="bold"
                textAlign="left"
                as="h2"
                fontSize="2xl"
              >
                Filtros
              </Heading>
              <Flex
                flexDir={{ base: "column", lg: "row" }}
                w="full"
                justifyContent="center"
                gap={8}
              >
                <FormControl as="fieldset" isInvalid={!!errors.cpf}>
                  <FormLabel as="legend">CPF</FormLabel>
                  <Input
                    type="text"
                    {...register("cpf")}
                    bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                    fontWeight="bold"
                    color={colorMode === "dark" ? "gray.300" : "gray.700"}
                    placeholder="Apenas números"
                    _placeholder={{
                      color: "gray.500",
                    }}
                  />
                  {errors.cpf ? (
                    <FormErrorMessage>{errors.cpf.message}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl as="fieldset" w="max-content">
                  <FormLabel as="legend">Data do pedido</FormLabel>
                  <Popover placement="right-end">
                    <PopoverTrigger>
                      <Button>Escolha a sua data</Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        bg={colorMode === "dark" ? "gray.900" : "white"}
                        p={2}
                        w="max-content"
                      >
                        <PopoverBody>
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </FormControl>

                <FormControl
                  as="fieldset"
                  id="hoursFilter"
                  isInvalid={!!errors.endHour}
                  gap={4}
                  display="flex"
                >
                  <Flex flex={1} flexDir="column">
                    <FormLabel as="legend">Horário de:</FormLabel>
                    <Select
                      variant="filled"
                      {...register("startHour")}
                      size="md"
                      w="full"
                    >
                      <option value="">Sem filtro</option>
                      {startHours.map((hour) => (
                        <option value={hour} key={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex flex={1} flexDir="column">
                    <FormLabel as="legend" marginTop="2px">
                      Até:
                    </FormLabel>
                    <Select
                      variant="filled"
                      {...register("endHour")}
                      size="md"
                      w="full"
                    >
                      <option value="">Sem filtro</option>
                      {endHours.map((hour) => (
                        <option value={hour} key={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  {errors.endHour ? (
                    <FormErrorMessage>
                      {errors.endHour.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </Flex>

              <Flex
                flexDir={{ base: "column", lg: "row" }}
                w="full"
                justifyContent="center"
                gap={8}
              >
                <FormControl as="fieldset">
                  <FormLabel as="legend">Empresa</FormLabel>
                  <Select
                    variant="filled"
                    {...register("company")}
                    size="md"
                    w="full"
                  >
                    <option value="">Todos</option>
                    {companies?.map((company) => (
                      <option value={company.id} key={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl as="fieldset" w="max-content">
                  <FormLabel as="legend">Vencimento</FormLabel>
                  <Popover placement="right-end">
                    <PopoverTrigger>
                      <Button>Escolha a sua data</Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        bg={colorMode === "dark" ? "gray.900" : "white"}
                        p={2}
                        w="max-content"
                      >
                        <PopoverBody>
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            selected={rangeDue}
                            onSelect={setRangeDue}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </FormControl>

                <Flex alignItems="center" gap={15}>
                  <Button
                    type="submit"
                    form="form"
                    bg="green.600"
                    w="max-content"
                    color="whiteAlpha.900"
                    _hover={{
                      bg: "green.700",
                    }}
                    px={8}
                    size="md"
                    alignSelf="flex-end"
                    isDisabled={!(isDirty || range || rangeDue)}
                    isLoading={(isDirty || range || rangeDue) && isFetching}
                  >
                    Filtrar
                  </Button>

                  {isDirty || range || rangeDue ? (
                    <Button
                      w="max-content"
                      px={8}
                      size="md"
                      alignSelf="flex-end"
                      onClick={handleFilterCleaning}
                      display="flex"
                      gap={4}
                    >
                      <X size={12} />
                      Limpar filtros
                    </Button>
                  ) : null}
                </Flex>
              </Flex>
            </Flex>

            <TableContainer style={{ containerType: "inline-size" }}>
              <Table colorScheme="green">
                <Thead>
                  <Tr>
                    <Th flex={1}>Nome</Th>
                    <Th textAlign="center">CPF</Th>
                    <Th textAlign="center">Empresa</Th>
                    <Th isNumeric>Valor</Th>
                    <Th isNumeric>Taxa</Th>
                    <Th isNumeric>Valor Creditado</Th>
                    <Th
                      textAlign="center"
                      onClick={() => setisOrderByDesc(!isOrderByDesc)}
                      cursor="pointer"
                    >
                      Data de entrada
                      {isOrderByDesc ? (
                        <Icon as={ArrowDown} />
                      ) : (
                        <Icon as={ArrowUp} />
                      )}
                    </Th>
                    <Th textAlign="center">Nº Contrato</Th>
                    <Th textAlign="center">Vendido para</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {advanceUserData?.result.map((advance) => {
                    return (
                      <Tr
                        fontWeight={600}
                        cursor="pointer"
                        onDoubleClick={() =>
                          navigate(`/portfolio-management/${advance.id}`)
                        }
                        key={advance.id}
                      >
                        <Td
                          maxW="200px"
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          textTransform={"capitalize"}
                        >
                          {advance.username
                            ? advance.username.toLowerCase()
                            : "-"}
                        </Td>
                        <Td textAlign="center">
                          {formatCPF(advance.document)}
                        </Td>
                        <Td textAlign="center">{advance.companyName}</Td>
                        <Td isNumeric>{convertCentsToReais(advance.amount)}</Td>
                        <Td isNumeric>
                          {convertCentsToReais(advance.amountFee)}
                        </Td>
                        <Td isNumeric>
                          {convertCentsToReais(advance.amountReceivable)}
                        </Td>
                        <Td textAlign="center">
                          {dayjs(advance.createdAt)
                            .subtract(3, "hours")
                            .format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td textAlign="center">{advance.contractNumber}</Td>
                        <Td textAlign="center">{advance.release_partner}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <HStack gap={8} pt={8} justifyContent="flex-end">
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={advanceUserData.totalPages}
              />
              <Select
                variant="filled"
                size="md"
                w="100px"
                onChange={handleItemsPerPage}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </Select>
            </HStack>
          </Flex>
        </>
      )}
    </VStack>
  );
}
