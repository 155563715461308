import { ReactNode } from "react";
import { Flex, Text } from "@chakra-ui/react";

type IProps = {
  readonly label: string;
  readonly icon: ReactNode;
  readonly value: string;
};

export function DetailCard({ label, icon, value }: IProps) {
  return (
    <Flex
      w="full"
      h="fit-content"
      bg="green.50"
      px={6}
      py={2}
      borderRadius="xl"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text fontSize="xl" fontWeight="extrabold">
        {label}
      </Text>
      <Flex
        w="200px"
        bg="white"
        px={5}
        py={1}
        gap={3}
        border="2px solid black"
        borderRadius="xl"
        alignItems="center"
        justifyContent="center"
      >
        {icon}
        <Text fontSize="medium" fontWeight="extrabold">
          {value}
        </Text>
      </Flex>
    </Flex>
  );
}
