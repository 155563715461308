import { VStack, Button, Box, Flex } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { FormInput } from "./form-input.component";
import { ImageUpload } from "./image-upload.component";
import { usePointsContext } from "../provider/points.provider";

export function CreateGiftsForm() {
  const methods = useForm();
  const { image, handleImageChange, removeImage, onSubmit } =
    usePointsContext();

  const handleResetForm = () => {
    methods.reset();
    removeImage();
  };

  return (
    <FormProvider {...methods}>
      <VStack
        as="form"
        onSubmit={methods.handleSubmit((data) =>
          onSubmit(data, handleResetForm)
        )}
        gap={8}
        flex={1}
        alignItems="flex-start"
        w="full"
      >
        <Box
          w="full"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
        >
          <FormInput
            label="Nome do Prêmio:"
            name="name"
            placeholder="Digite aqui o nome do prêmio"
          />
          <FormInput
            label="Descrição do Prêmio:"
            name="description"
            placeholder="Digite aqui uma descrição"
            isTextarea
          />
          <FormInput
            label="Valor em Pontos:"
            name="points"
            placeholder="Digite o número de pontos necessários"
            isInteger={true}
          />
          <FormInput
            label="Categoria:"
            name="category"
            placeholder="Ex. eletrônicos, gift card"
          />
          <ImageUpload
            image={image}
            handleImageChange={handleImageChange}
            removeImage={removeImage}
          />

          <Flex justify="space-between" mt={4}>
            <Button colorScheme="red" type="button" onClick={handleResetForm}>
              Cancelar
            </Button>
            <Button colorScheme="green" type="submit">
              Ativar
            </Button>
          </Flex>
        </Box>
      </VStack>
    </FormProvider>
  );
}
