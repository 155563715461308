import { Link, LinkProps } from "@chakra-ui/layout";
import { MenuItem } from "@chakra-ui/react";
import {
  Link as LinkRoute,
  LinkProps as LinkRouteProps,
} from "react-router-dom";

type CustomLinkProps = LinkProps &
  LinkRouteProps & {
    isSelected: boolean;
    children: React.ReactNode;
  };

export function MenuItemUi({
  isSelected,
  children,
  ...props
}: CustomLinkProps) {
  if (isSelected) {
    return (
      <Link
        as={LinkRoute}
        w="full"
        textDecoration="none"
        fontFamily="Source Sans 3, sans-serif"
        fontSize="xl"
        _hover={{ textDecoration: "none" }}
        {...props}
      >
        <MenuItem
          w="full"
          borderRadius="md"
          fontFamily="Source Sans 3, sans-serif"
          _hover={{ bg: "#000", color: "#fff" }}
        >
          {children}
        </MenuItem>
      </Link>
    );
  }
  return (
    <Link
      as={LinkRoute}
      w="full"
      textDecoration="none"
      fontFamily="Source Sans 3, sans-serif"
      fontSize="xl"
      _hover={{ textDecoration: "none" }}
      {...props}
    >
      <MenuItem
        fontFamily="Source Sans 3, sans-serif"
        w="full"
        borderRadius="md"
        _hover={{ bg: "#000", color: "#fff" }}
      >
        {children}
      </MenuItem>
    </Link>
  );
}
