import { VStack, Box } from "@chakra-ui/layout";

import RedemptionFilterComponent from "./redemption-filter-form.component";
import RedemptionsTable from "./redemptions-table.component";

export function RedemptionsHistory() {
  return (
    <VStack gap={8} flex={1} alignItems="flex-start" w={"100%"}>
      <Box
        w="full"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
      >
        <RedemptionFilterComponent />
        <RedemptionsTable />
      </Box>
    </VStack>
  );
}
