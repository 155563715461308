import { z } from "zod";

export const formSchema = z.object({
  nameOrDocument: z.string().optional(),
  debtId: z.number().int().min(1).optional(),
  contractNumber: z.string().optional(),
  document: z.string().optional(),
  createdAt: z.date().optional(),
  transactionDate: z.date().optional(),
  debtDate: z.date().optional(),
  transactionType: z.string().optional(),
  orderBy: z.string(),
});

export type FilterFormInputsValidation = z.infer<typeof formSchema>;

export const formDefaultValue = {
  nameOrDocument: "",
  document: "",
  debtId: undefined,
  contractNumber: undefined,
  createdAt: undefined,
  transactionDate: undefined,
  debtDate: undefined,
  transactionType: undefined,
  orderBy: "",
};
