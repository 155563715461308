import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Text,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Box,
  FormControl,
  FormLabel,
  Flex,
  Select,
  Spinner,
} from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import InputDate from "../../../../components-ui/input-date";
import { FilterFormInputsValidation } from ".";
import { CompanyDto } from "../../../../dto/company-dto";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../services/basicService";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  control: Control<FilterFormInputsValidation>;
  register: UseFormRegister<FilterFormInputsValidation>;
  getValues: UseFormGetValues<FilterFormInputsValidation>;
  refetch: () => void;
  setIsFilterActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen: modalIsOpen,
  onClose,
  control,
  register,
  getValues,
  refetch,
  setIsFilterActive,
}) => {
  const formValues = useWatch({ control });
  const isFormFilled = Object.values(formValues).some((value) => value);

  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    data: franchisesToSelect,
    isLoading: loadingFranchises,
    refetch: refetchFranchises,
  } = useQuery({
    queryKey: ["franchises" + inputValue],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/companies/list/franchisees/" + inputValue
      );

      return response?.data as Partial<CompanyDto>[];
    },
    enabled: inputValue.length > 0,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options =
    franchisesToSelect?.map((franchise) => ({
      value: franchise.id,
      label: franchise.name,
    })) || [];

  return (
    <Modal isOpen={modalIsOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px" borderRadius="2xl">
        <ModalHeader
          fontSize="3xl"
          bg="#fffcf2"
          h="100px"
          w="full"
          pb={8}
          borderRadius="3xl"
        >
          Filtro
        </ModalHeader>
        <ModalCloseButton top={6} size="lg" color="gray.500" />

        <ModalBody p={4} fontFamily="Source Sans 3, sans-serif">
          {/* CPF Input */}
          <Flex justify={"space-between"}>
            <Box mb={6}>
              <Text fontSize="xl" mb={2}>
                ID do Débito
              </Text>
              <Input
                {...register("debtId")}
                placeholder="Insira o número"
                borderRadius="md"
                bg="white"
                h="12"
                fontSize="xl"
              />
            </Box>
            <Box mb={6}>
              <Text fontSize="xl" mb={2}>
                Número do Contrato
              </Text>
              <Input
                {...register("contractCode")}
                placeholder="Insira o número"
                borderRadius="md"
                bg="white"
                h="12"
                fontSize="xl"
              />
            </Box>
          </Flex>
          <Flex justify={"space-between"}>
            <Box mb={6}>
              <Text fontSize="xl" mb={2}>
                CPF
              </Text>
              <Input
                {...register("document")}
                placeholder="Insira o CPF"
                size="md"
                borderRadius="md"
                bg="white"
                h="12"
                fontSize="xl"
              />
              <Text fontSize="md" color="gray.500" mt={1}>
                Apenas números
              </Text>
            </Box>
            <Box mb={6}>
              <FormControl w="fit-content">
                <FormLabel
                  display="flex"
                  gap="12px"
                  fontFamily="Source Sans 3, sans-serif"
                  fontSize="xl"
                >
                  Ordenação
                </FormLabel>
                <Controller
                  name="sortDirection"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value}
                      defaultValue="DESC"
                      fontFamily="Source Sans 3, sans-serif"
                      fontSize="xl"
                    >
                      <Stack direction="row">
                        <Radio value="ASC" colorScheme="green">
                          <Text
                            fontFamily="Source Sans 3, sans-serif"
                            fontSize="xl"
                          >
                            Crescente
                          </Text>
                        </Radio>
                        <Radio value="DESC" colorScheme="red">
                          <Text
                            fontFamily="Source Sans 3, sans-serif"
                            fontSize="xl"
                          >
                            Descrescente
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Box>
          </Flex>

          {/* Date Fields */}
          <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={6}>
            <InputDate
              control={control}
              name="createdAt"
              label="Data de criação"
              getValues={getValues}
            />
            <InputDate
              control={control}
              name="transactionDate"
              label="Data de transação"
              getValues={getValues}
            />
            <InputDate
              control={control}
              name="debtDate"
              label="Data de vencimento"
              getValues={getValues}
            />
          </Grid>
          <Box mb={6} position="relative" ref={containerRef}>
            <Text fontSize="xl" mb={2}>
              Nome da empresa
            </Text>
            <Controller
              name="companyId"
              control={control}
              render={({ field: { onChange } }) => (
                <>
                  <Input
                    placeholder="Digite para pesquisar..."
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                      setIsOpen(true);
                      refetchFranchises();
                    }}
                    onFocus={() => setIsOpen(true)}
                  />
                  {isOpen && (
                    <Box
                      position="absolute"
                      top="100%"
                      left={0}
                      right={0}
                      bg="white"
                      border="1px solid"
                      borderColor="gray.200"
                      zIndex="dropdown"
                      maxH="200px"
                      overflowY="auto"
                    >
                      {loadingFranchises ? (
                        <Box p={2} display="flex" alignItems="center">
                          <Spinner size="sm" mr={2} /> Carregando...
                        </Box>
                      ) : options.length > 0 ? (
                        options.map((option) => (
                          <Box
                            key={option.value}
                            p={2}
                            _hover={{ bg: "gray.100", cursor: "pointer" }}
                            onClick={() => {
                              onChange(option.value);
                              setInputValue(option.label || "");
                              setIsOpen(false);
                            }}
                          >
                            {option.label}
                          </Box>
                        ))
                      ) : (
                        <Box p={2}>Nenhum resultado</Box>
                      )}
                    </Box>
                  )}
                </>
              )}
            />
          </Box>
          <Box mb={6}>
            <Text fontSize="xl" mb={2}>
              Status do débito
            </Text>
            <Select
              {...register("companyId")}
              placeholder="Insira o status"
              borderRadius="md"
              bg="white"
              h="12"
              fontSize="xl"
            >
              <option value="Pendente">Pendente</option>
              <option value="Pago">Pago</option>
              <option value="Cancelado">Cancelado</option>
            </Select>
          </Box>

          <Stack direction="row" spacing={4} justify="flex-end">
            <Button
              variant="outline"
              size="md"
              onClick={onClose}
              borderRadius="md"
              px={6}
            >
              Cancelar
            </Button>
            <Button
              bg={isFormFilled ? "green.500" : "gray.200"}
              _hover={{ bg: isFormFilled ? "green.600" : "gray.300" }}
              color={isFormFilled ? "white" : "black"}
              size="md"
              borderRadius="md"
              px={6}
              onClick={() => {
                refetch();
                onClose();
                setIsFilterActive(true);
              }}
            >
              Filtrar
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default FilterModal;
