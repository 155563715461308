import { Box, Flex, IconButton, CloseButton, Input } from "@chakra-ui/react";
import { FileArrowUp } from "@phosphor-icons/react";

type ImageUploadProps = {
  image: File | null;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: () => void;
};

export function ImageUpload({
  image,
  handleImageChange,
  removeImage,
}: ImageUploadProps) {
  return (
    <Flex align="center">
      <Input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        display="none"
        id="upload"
      />
      <IconButton
        as="label"
        htmlFor="upload"
        aria-label="Upload Image"
        size="lg"
        colorScheme="green"
        icon={<FileArrowUp />}
      />
      {image && (
        <Box position="relative" ml={4}>
          <img src={URL.createObjectURL(image)} alt="Preview" width="400px" />
          <CloseButton
            position="absolute"
            top="0"
            right="0"
            colorScheme="red"
            onClick={removeImage}
            size="lg"
          />
        </Box>
      )}
    </Flex>
  );
}
