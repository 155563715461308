import { UserPaymentDebtStatus } from "../enums/user-payment-debt.enum";

export interface IUserDebtDTO {
  id: number;
  companyId: number;
  deliverymanExternalId: string;
  deliverymanCpf: string;
  document?: string;
  deliverymanName: string;
  totalAmount: number;
  remainingAmount?: number;
  paidAmount?: number;
  status: UserPaymentDebtStatus;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  launchDate: Date;
  nextTransferDate: Date;
}

export const debtStatusTextMap = {
  [UserPaymentDebtStatus.CANCELED]: "Cancelado",
  [UserPaymentDebtStatus.PAID]: "Quitado",
  [UserPaymentDebtStatus.PENDING]: "Pendente",
};

export interface IPaginateUserDebtDTO {
  page: number;
  limit: number;
  nextPage: boolean;
  totalPages: number;
  data: IUserDebtDTO[];
}

export interface ISearchUserDebtDTO {
  deliverymanName?: string;
  deliverymanCpf?: string;
  document?: string;
  launchDate?: Date;
  nextTransferDate?: Date;
  status?: UserPaymentDebtStatus;
}
