import { useToast, Box, Flex, Text, CloseButton } from "@chakra-ui/react";
import { CheckCircle, Info } from "@phosphor-icons/react";

export function useCustomToast() {
  const toast = useToast();

  function showCustomToast(message: string, type: "success" | "error") {
    toast({
      position: "top-left",
      duration: 5000,
      isClosable: true,

      render: ({ onClose }) => (
        <Box
          w="100vw"
          bg={type === "success" ? "green.500" : "red.500"}
          color="white"
          p={4}
          borderRadius="none"
          position={"relative"}
        >
          <Flex justify="flex-start" align="center" gap={2}>
            {type === "success" ? (
              <CheckCircle size={24} />
            ) : (
              <Info size={24} />
            )}
            <Text fontWeight="bold" marginRight={"auto"}>
              {message}
            </Text>
            <CloseButton onClick={onClose} />
          </Flex>
        </Box>
      ),
    });
  }

  return { showCustomToast };
}
