import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Button,
  Text,
  Progress,
} from "@chakra-ui/react";
import { useHistoryRedemptionContext } from "../provider/history-remdeption.provider";
import { format } from "date-fns";

function RedemptionsTable() {
  const { RedemptionHistory, handleChangePage, isLoading } =
    useHistoryRedemptionContext();

  return (
    <Box>
      <Progress
        value={isLoading ? 100 : 0}
        size="md"
        isIndeterminate
        colorScheme="green"
        width={"full"}
        display={isLoading ? "block" : "none"}
        mt={2}
        mb={2}
      />
      <Table variant="striped" size={"sm"}>
        <Thead>
          <Tr>
            <Th>Nome do Participante</Th>
            <Th>CPF</Th>
            <Th>Número de telefone</Th>
            <Th>Prêmio Resgatado</Th>
            <Th>Categoria</Th>
            <Th>Valor em Pontos</Th>
            <Th>Data do Regaste</Th>
          </Tr>
        </Thead>
        <Tbody>
          {RedemptionHistory?.data?.map((reward, index) => (
            <Tr key={index}>
              <Td>{reward?.deliveryman?.nameTrampay}</Td>
              <Td>{reward.document}</Td>

              <Td>{reward.phoneNumber}</Td>
              <Td>{reward.gift.name}</Td>
              <Td>{reward.category}</Td>
              <Td>{reward.pointsValue}</Td>
              <Td>
                {reward.redemptionDate
                  ? format(new Date(reward.redemptionDate), "dd/MM/yyyy")
                  : ""}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent="space-between" alignItems="center" mt={4}>
        <Button
          onClick={() =>
            handleChangePage(
              RedemptionHistory?.page ? RedemptionHistory?.page - 1 : 1
            )
          }
          isDisabled={RedemptionHistory?.page === 1}
        >
          Anterior
        </Button>
        <Text>
          Página {RedemptionHistory?.page} de{" "}
          {Math.ceil((RedemptionHistory?.count || 1) / 10)}
        </Text>
        <Button
          onClick={() =>
            handleChangePage(
              RedemptionHistory?.page ? RedemptionHistory?.page + 1 : 1 + 1
            )
          }
          isDisabled={
            RedemptionHistory?.page ===
            Math.ceil((RedemptionHistory?.count || 1) / 10)
          }
        >
          Próxima
        </Button>
      </Flex>
    </Box>
  );
}

export default RedemptionsTable;
