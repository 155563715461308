import React, { useEffect, useState } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  InputRightElement,
  InputGroup,
  Button,
  Box,
  Divider,
  FormLabelProps,
  InputProps,
  Portal,
  PortalManager,
} from "@chakra-ui/react";
import { CalendarBlank, CaretLeft, CaretRight } from "@phosphor-icons/react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormGetValues,
} from "react-hook-form";
import { DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

interface DatePickerWithInputProps<T extends FieldValues> {
  control: Control<T>;
  getValues: UseFormGetValues<T>;
  name: Path<T>;
  label: string;
  formLabelProps?: FormLabelProps;
  inputProps?: InputProps;
}

const InputDate = <T extends FieldValues>({
  control,
  name,
  label,
  getValues,
  formLabelProps,
  inputProps,
}: DatePickerWithInputProps<T>) => {
  const [inputValue, setInputValue] = useState<string>(
    getValues(name) ? dayjs(getValues(name)).format("DD/MM/YYYY") : ""
  );
  const [isOpen, setIsOpen] = useState(false);
  const [month, setMonth] = useState<Date | undefined>(new Date());

  useEffect(() => {
    setInputValue(
      getValues(name) ? dayjs(getValues(name)).format("DD/MM/YYYY") : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(name)]); // Atualiza sempre que o campo for alterado

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (date: Date | null) => void
  ) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
    if (value.length > 8) {
      return;
    }

    const formattedValue = value
      .replace(/^(\d{2})(\d)/, "$1/$2") // Adiciona barra após o dia
      .replace(/^(\d{2})\/(\d{2})(\d)/, "$1/$2/$3"); // Adiciona barra após o mês

    setInputValue(formattedValue);

    if (formattedValue.length === 10) {
      const parsedDate = dayjs(formattedValue, "DD/MM/YYYY", true);
      if (parsedDate.isValid()) {
        onChange(parsedDate.toDate()); // Converte para Date
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    onChange: (date: Date | null) => void
  ) => {
    if (e.key === "Backspace" && inputValue.length === 10) {
      setInputValue("");
      onChange(null);
    }
  };

  return (
    <PortalManager zIndex={2000}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormControl as="fieldset" display="flex" flexDir="column">
            <FormLabel
              position="relative"
              as="legend"
              fontFamily="Source Sans 3, sans-serif"
              fontSize="md"
              {...formLabelProps}
            >
              {label}
            </FormLabel>

            {/* Input para digitação manual */}
            <InputGroup w="fit-content" zIndex={1}>
              <InputRightElement
                h="full"
                display="flex"
                alignItems="center"
                cursor="pointer"
              >
                <Popover
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  placement="auto"
                >
                  <PopoverTrigger>
                    <Button
                      variant="ghost"
                      p={0}
                      minW="auto"
                      bg="white"
                      _hover={{ bg: "white" }}
                      onClick={() => setIsOpen((prev) => !prev)}
                    >
                      <CalendarBlank size={18} />
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent
                      bg="white"
                      p={0}
                      w="320px"
                      borderRadius="lg"
                      boxShadow="lg"
                      border="1px solid #22c55e"
                      overflow="hidden"
                      pointerEvents="auto"
                    >
                      <Box
                        bg="#22c55e"
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        px={3}
                        py={5}
                        width="100%"
                      >
                        <CaretLeft
                          size={24}
                          cursor="pointer"
                          onClick={() =>
                            setMonth((prev) =>
                              prev
                                ? dayjs(prev).subtract(1, "month").toDate()
                                : new Date()
                            )
                          }
                        />
                        <Box fontSize="xl" fontWeight="bold">
                          {dayjs(month)
                            .locale("pt-br")
                            .format("MMMM YYYY")
                            .toUpperCase()}
                        </Box>
                        <CaretRight
                          size={24}
                          cursor="pointer"
                          onClick={() =>
                            setMonth((prev) =>
                              prev
                                ? dayjs(prev).add(1, "month").toDate()
                                : new Date()
                            )
                          }
                        />
                      </Box>

                      <PopoverBody p={2}>
                        <DayPicker
                          id="dateFilter"
                          mode="single"
                          selected={field.value}
                          onSelect={(date) => {
                            if (date) {
                              setInputValue(dayjs(date).format("DD/MM/YYYY"));
                              field.onChange(date);
                            }
                          }}
                          locale={ptBR}
                          month={month}
                          onMonthChange={setMonth}
                          showOutsideDays={true}
                          components={{
                            CaptionLabel: () => <></>,
                            Chevron: () => <></>,
                          }}
                          styles={{
                            root: { fontFamily: "Arial, sans-serif" },
                            head_cell: { fontWeight: "bold", color: "#444" },
                            cell: {
                              width: "40px",
                              height: "40px",
                              textAlign: "center",
                              lineHeight: "40px",
                              borderRadius: "8px",
                              cursor: "pointer",
                              transition: "background 0.2s",
                            },
                            selected: {
                              backgroundColor: "#22c55e",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                        />

                        {/* Divisor */}
                        <Divider my={3} />

                        {/* Botão Confirmar */}
                        <Button
                          width="full"
                          bg="gray.300"
                          color="gray.600"
                          isDisabled={!field.value}
                          onClick={() => setIsOpen(false)} // Fecha o modal ao clicar no botão
                        >
                          Confirmar data de transação
                        </Button>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </InputRightElement>
              <Input
                bg="white"
                border="1px solid #d9d9d9"
                placeholder="00/00/0000"
                h="12"
                fontSize="sm"
                value={inputValue}
                onChange={(e) => handleInputChange(e, field.onChange)}
                onKeyDown={(e) => handleKeyDown(e, field.onChange)}
                maxLength={10}
                {...inputProps}
              />
            </InputGroup>
          </FormControl>
        )}
      />
    </PortalManager>
  );
};

export default InputDate;
