import {
  Box,
  VStack,
  IconButton,
  Text,
  Flex,
  Tooltip,
  FormControl,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Image,
  useDisclosure,
  Button,
  Spinner,
  InputRightElement,
} from "@chakra-ui/react";
import {
  DownloadSimple,
  Eye,
  MagnifyingGlass,
  Minus,
  Plus,
  Question,
  Trash,
  X,
} from "@phosphor-icons/react";
import { LiaSlidersHSolid } from "react-icons/lia";
import { IconBaseProps } from "react-icons";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../../services/basicService";
import { GetDebtCloudTransactionsInfoDto } from "../../../../../types/get-debt-cloud-transactions.type";
import dayjs from "dayjs";
import { formatCPF } from "../../../../../utils/format-document";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import { useRef, useState } from "react";
import { Pagination } from "../../../../../components-ui/pagination";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import EmptySearchIcon from "../../../../../assets/empty-search-icon.svg";
import FiltroModal from "./filter";
import TransactionModal from "./details";
import { useExportCsv } from "../../../../../hooks/adiantamento/use-export-csv";
import {
  ComposableTable,
  ComposableTableBody,
  ComposableTableCell,
  ComposableTableHead,
  ComposableTableHeaderCell,
  ComposableTableRow,
} from "../../../../../components-ui/table";
import {
  FilterFormInputsValidation,
  formDefaultValue,
  formSchema,
} from "./validations/form";

const LiaSlidersHSolidIcon: React.FC<IconBaseProps> =
  LiaSlidersHSolid as unknown as React.FC<IconBaseProps>;

export function CloudManagementConciliation() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure({ id: "modal-filter" });
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure({ id: "modal-details" });
  const debtTransactionIds = useRef<{ debtId: number; transactionId: number }>({
    debtId: 0,
    transactionId: 0,
  });
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { exportCsv, loading: loadingExportCsv } = useExportCsv();

  const {
    register,
    getValues,
    control,
    reset,
    resetField,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FilterFormInputsValidation>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValue,
  });

  const queryParams = () => {
    let query = "";

    const formValues = getValues();

    for (const [key, value] of Object.entries(formValues)) {
      if (value) {
        query += `${key}=${value}&`;
      }
    }

    return query;
  };

  const { data, isFetching, refetch } = useQuery({
    queryKey: [`cloud-transactions-limit`, page, limit],
    queryFn: async () => {
      let PATH = `/api/v1/transaction/cloud?limit=${limit}&page=${page}&`;
      PATH += queryParams();

      const res = await getWithAuth(PATH);

      return res?.data as GetDebtCloudTransactionsInfoDto;
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (_: FilterFormInputsValidation) => refetch();

  const getDebitReversedText = (isDebitReversed: boolean | undefined) => {
    if (typeof isDebitReversed !== "boolean") {
      return "-";
    }
    return isDebitReversed ? "Sim" : "Não";
  };

  return (
    <VStack
      gap={6}
      w="full"
      alignItems="flex-start"
      marginTop={6}
      h="calc(100dvh - 183px)"
    >
      <Flex
        bg="white"
        w="full"
        h="full"
        flexDirection="column"
        overflowY="auto"
        overflowX="auto"
        borderRadius="2xl"
      >
        {/* Cards de valores */}
        <Flex
          w="full"
          py={4}
          px={12}
          bg="#FBFBFB"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="3xl"
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
        >
          <Flex gap={8}>
            <Box
              bg="white"
              border="2px solid"
              borderColor="#E2E2E0"
              borderRadius="md"
              py={2}
              px={6}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                fontWeight="extrabold"
              >
                Valor total
              </Text>
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                color="#1DC161"
                fontWeight="bold"
              >
                {isFetching
                  ? "R$ 0,00"
                  : convertCentsToReais(data?.totalDebtsPaid || 0)}
              </Text>
            </Box>
            <Box
              bg="white"
              border="2px solid"
              borderColor="#E2E2E0"
              borderRadius="md"
              py={2}
              px={6}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                fontWeight="extrabold"
              >
                Valor em aberto
              </Text>
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                color="#1DC161"
                fontWeight="bold"
              >
                {isFetching
                  ? "R$ 0,00"
                  : convertCentsToReais(data?.totalUnpaidDebts || 0)}
              </Text>
            </Box>
          </Flex>

          <Flex gap={4} alignItems="center" w="max-content">
            <Button
              w="fit-content"
              alignItems="center"
              px={7}
              py={2}
              display="flex"
              justifyContent="center"
              gap={3}
              bg="white"
              border="1px"
              borderColor="black"
              borderRadius="3xl"
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              isLoading={loadingExportCsv}
              loadingText="Exportando"
              disabled={loadingExportCsv}
              onClick={() => {
                exportCsv(
                  "/api/v1/transaction/cloud/csv",
                  "debt-transactions-cloud",
                  queryParams()
                );
              }}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontWeight="extrabold"
                fontSize="medium"
                color="#0B0B0B"
              >
                Exportar .CSV
              </Text>
              <DownloadSimple size={25} weight="bold" color="black" />
            </Button>

            <Tooltip
              hasArrow
              placement="top"
              textAlign="center"
              fontSize="md"
              label="Os dados exportados serão baseados no filtro dessa página."
              borderRadius="md"
            >
              <Question size={20} color="#718096" cursor="pointer" />
            </Tooltip>

            <Box
              as="form"
              display="flex"
              flexDir="column"
              gap={8}
              w="fit-content"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={!!errors.nameOrDocument}>
                <InputGroup display="flex" alignItems="center">
                  <InputLeftElement
                    h="full"
                    display="flex"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => refetch()}
                  >
                    <IconButton
                      as="button"
                      aria-label="Ver detalhes"
                      bg="-moz-initial"
                      icon={
                        <MagnifyingGlass
                          size={16}
                          color="#6f6f6f"
                          weight="bold"
                        />
                      }
                      size="md"
                      variant="ghost"
                      type="submit"
                    />
                  </InputLeftElement>
                  <Input
                    id="nameOrDocument"
                    w={350}
                    size="lg"
                    bg="white"
                    _placeholder={{ color: "gray.500" }}
                    {...register("nameOrDocument")}
                    title="nameOrDocument"
                    placeholder="Buscar por nome ou documento"
                  />
                  {(watch("nameOrDocument")?.length || 0) > 0 && (
                    <InputRightElement
                      h="full"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                      onClick={() => {
                        reset();
                        refetch();
                      }}
                    >
                      <X size={20} />
                    </InputRightElement>
                  )}
                </InputGroup>
                <FormErrorMessage>
                  {errors.nameOrDocument?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <IconButton
              aria-label="Ver detalhes"
              bg="-moz-initial"
              icon={<LiaSlidersHSolidIcon size={30} color="#6F6F6F" />}
              size="md"
              variant="ghost"
              onClick={onOpen}
            />

            {isFilterActive && (
              <Button
                bg="red"
                color="white"
                w="fit-content"
                display="flex"
                gap={2}
                px={7}
                borderRadius="3xl"
                _hover={{ bg: "red.700" }}
                onClick={() => {
                  setIsFilterActive(false);
                  reset();
                  refetch();
                }}
              >
                <Text fontFamily="Source Sans 3, sans-serif" fontSize="md">
                  Limpar filtro
                </Text>
                <Trash size={20} weight="bold" color="white" />
              </Button>
            )}
            <FiltroModal
              isOpen={isOpen}
              onClose={onClose}
              control={control}
              refetch={refetch}
              register={register}
              getValues={getValues}
              setIsFilterActive={setIsFilterActive}
              resetField={resetField}
            />
          </Flex>
        </Flex>

        {/* Tabela */}
        {!isFetching && data?.transactions?.totalRecords === 0 ? (
          <Flex
            w="full"
            h="full"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Image
              src={EmptySearchIcon}
              alt="Lupa de busca"
              objectFit="cover"
              loading="eager"
            />
            <Text
              fontFamily="Source Sans 3, sans-serif"
              fontWeight="semibold"
              fontSize="xl"
            >
              Nenhum resultado encontrado...
            </Text>
          </Flex>
        ) : !isFetching ? (
          <ComposableTable>
            <ComposableTableHead>
              <ComposableTableHeaderCell>ID débitos</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Nome</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Documento</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Valor transação
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Data transação
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Data vencimento
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Data quitação
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                <Flex alignItems="center" justifyContent="center" gap={1}>
                  Estorno
                  <Tooltip
                    hasArrow
                    placement="top"
                    textAlign="center"
                    fontSize="md"
                    label="Essa coluna indica se o DÉBITO foi estornado ou não."
                    borderRadius="md"
                  >
                    <Question size={16} color="#6f6f6f" weight="bold" />
                  </Tooltip>
                </Flex>
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Ações</ComposableTableHeaderCell>
            </ComposableTableHead>
            <ComposableTableBody>
              {data?.transactions?.result.map((item, index) => (
                <ComposableTableRow
                  key={item.transactionId}
                  isStriped={index % 2 !== 0}
                >
                  <ComposableTableCell>{item.debtId}</ComposableTableCell>
                  <ComposableTableCell>{item.name}</ComposableTableCell>
                  <ComposableTableCell>
                    {formatCPF(item.document)}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    <Flex alignItems="center" justifyContent="center" gap={1}>
                      {item?.isDebitReversed === undefined ? (
                        <Minus size={14} color="#6F6F6F" weight="bold" />
                      ) : (
                        <Plus size={14} color="#6F6F6F" weight="bold" />
                      )}
                      {" R$ " + item.amount / 100}
                    </Flex>
                  </ComposableTableCell>
                  <ComposableTableCell>
                    {item.transactionDate &&
                      dayjs(item.transactionDate).format("DD/MM/YYYY")}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    {item.dueDate && dayjs(item.dueDate).format("DD/MM/YYYY")}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    {item.quitDate && dayjs(item.quitDate).format("DD/MM/YYYY")}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    <Text color={item?.isDebitReversed ? "#FF3B30" : "#0B0B0B"}>
                      {getDebitReversedText(item?.isDebitReversed)}
                    </Text>
                  </ComposableTableCell>
                  <ComposableTableCell>
                    <IconButton
                      aria-label="Ver detalhes"
                      bg="-moz-initial"
                      icon={<Eye size={22} color="#6f6f6f" weight="bold" />}
                      size="md"
                      variant="ghost"
                      onClick={() => {
                        debtTransactionIds.current = {
                          debtId: item.debtId,
                          transactionId: item.transactionId,
                        };
                        openModal();
                      }}
                    />
                  </ComposableTableCell>
                </ComposableTableRow>
              ))}
            </ComposableTableBody>
          </ComposableTable>
        ) : (
          <Flex w="full" h="full" alignItems="center" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
      </Flex>
      {debtTransactionIds.current.debtId > 0 && (
        <TransactionModal
          isOpen={isModalOpen}
          onClose={closeModal}
          debtId={debtTransactionIds.current.debtId}
          transactionId={debtTransactionIds.current.transactionId}
        />
      )}
      {/* Paginação */}
      {!isFetching &&
        data?.transactions?.totalRecords &&
        data.transactions.totalRecords > 0 && (
          <Pagination
            currentPage={page}
            totalPages={data?.transactions?.totalPages ?? 0}
            itemsPerPage={limit}
            setPage={setPage}
            setItemsPerPage={setLimit}
          />
        )}
    </VStack>
  );
}
