import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

interface ModalProps {
  modalHeader: string;
  modalBody: string | React.ReactNode;
  confirmButtonText?: string;
  buttonText?: string;
  onConfirm?: () => void;
  buttonProps?: object;
  confirmButtonProps?: object;
  modalIsOpen?: boolean;
  confirmButtonIsDisabled?: boolean;
  doNotShowCloseButton?: boolean;
  isLoading?: boolean;
}

export function CustomModal(props: ModalProps) {
  const { modalHeader, modalBody, confirmButtonText } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {props.buttonProps || props.buttonText ? (
        <Button onClick={onOpen} {...props.buttonProps}>
          {props.buttonText}
        </Button>
      ) : null}
      <Modal isOpen={props.modalIsOpen || isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          {props.doNotShowCloseButton || <ModalCloseButton />}
          <ModalBody>
            {typeof modalBody === "string" ? <p>{modalBody}</p> : modalBody}
          </ModalBody>

          <ModalFooter>
            {props.doNotShowCloseButton || (
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
            )}
            {confirmButtonText && (
              <Button
                colorScheme="green"
                onClick={
                  props.onConfirm
                    ? () => {
                        props.onConfirm && props.onConfirm();
                        onClose();
                      }
                    : () => onClose()
                }
                isDisabled={props.confirmButtonIsDisabled}
                isLoading={props.isLoading}
                {...props.confirmButtonProps}
              >
                {confirmButtonText}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
