import { VStack, Heading, Flex, Box } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Stat,
  StatArrow,
  StatLabel,
  StatNumber,
  useToast,
  Text,
} from "@chakra-ui/react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getWithAuth, patchWithAuth } from "../../../../services/basicService";
import { useQuery } from "@tanstack/react-query";
import { advanceBlockedMapper } from "./functions/advance-blocked-mapper";
import { AdvanceBlockedEnum } from "../../../../enums/advance-blocked.enum";

const proposalFormSchema = z.object({
  value: z.string(),
});

type proposalFormInputs = z.infer<typeof proposalFormSchema>;

export function AdvanceBlockedConfig() {
  const { data, refetch } = useQuery({
    queryKey: ["advance-blocked-config"],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/configuration/advance_blocked"
      );
      return response?.data as {
        id: number;
        key: string;
        value: AdvanceBlockedEnum;
      };
    },
  });

  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<proposalFormInputs>({
    resolver: zodResolver(proposalFormSchema),
    defaultValues: {
      value: data?.value,
    },
  });

  async function onSubmit(data: proposalFormInputs) {
    console.log("🚀 ~ onSubmit ~ data:", data);
    try {
      await patchWithAuth(
        `/api/v1/configuration/advance_blocked`,
        { value: data.value },
        {}
      );

      toast({
        status: "success",
        title: `O status de bloqueio do adiantamento foi mudado para ${advanceBlockedMapper(
          data.value as AdvanceBlockedEnum
        )}`,
      });

      refetch();
    } catch (error) {
      toast({
        status: "error",
        title: `Erro ao mudar o status de bloqueio do adiantamento`,
      });
    }
  }

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Configurações de bloqueio de adiantamento
      </Heading>

      {data && (
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDir="column"
          w="full"
          h="full"
          gap={6}
          borderRadius={8}
        >
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="lg"
            h="min-content"
          >
            <Text
              fontFamily="Source Sans 3, sans-serif"
              fontSize="medium"
              fontWeight="semibold"
            >
              Essa configuração tem como objetivo bloquear (ou não) o acesso dos
              usuários a tela de adiantamento
            </Text>
          </Box>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="lg"
            h="min-content"
          >
            <Stat border={1}>
              <StatLabel>Status</StatLabel>
              <StatNumber>
                <StatArrow type="increase" />
                {advanceBlockedMapper(data?.value)}
              </StatNumber>
            </Stat>
          </Box>

          <Flex
            flexDir="column"
            as="form"
            gap={8}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              gap={4}
            >
              <FormLabel htmlFor="changeState" mb="0" fontSize="lg">
                Bloqueio de adiantamento
              </FormLabel>
              <Select {...register("value")}>
                {Object.entries(AdvanceBlockedEnum).map(([_, value]) => {
                  return (
                    <option key={value} value={value}>
                      {advanceBlockedMapper(value)}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              size="lg"
            >
              Salvar Status
            </Button>
          </Flex>
        </Flex>
      )}
    </VStack>
  );
}
