import { DebtStatus } from "../../../../enums/debt-status";
import { TransactionStatus } from "../../../../enums/transaction-status";
import { IInstallment } from "../interfaces/deliverymen-debts.interface";

export function calculateRemainingDebt(
  debt: Omit<IInstallment, "launchedDate" | "quitDate" | "debtDate">
) {
  if (debt.status === DebtStatus.CANCELED) {
    return debt.amount;
  }

  if (debt.transactions.length === 0) {
    return 0;
  }

  const remainingAmount = debt.transactions.reduce(
    (totalAmount, transaction) =>
      transaction.status === TransactionStatus.SUCCESS
        ? (totalAmount += Number(transaction.amount))
        : totalAmount,
    0
  );

  return remainingAmount;
}
