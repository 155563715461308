import { createContext, useState } from "react";
import {
  deleteWithAuth,
  getWithAuth,
  postWithAuth,
} from "../../../../../services/basicService";
import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CompanyDto } from "../../../../../dto/company-dto";

const createB2BUserSchema = z.object({
  messagem: z.string(),
});

type createB2BUserFormInputs = z.infer<typeof createB2BUserSchema>;

type PayrollScheduleSettingsContextType = {
  companyId: number;
  setCompanyId: (companyId: number) => void;
  isLoading: boolean;
  payrollStatusState: boolean;
  handleSubmit: UseFormHandleSubmit<createB2BUserFormInputs>;
  onSubmitByPass: () => void;
  onSubmitMessage: (data: createB2BUserFormInputs) => void;
  register: UseFormRegister<createB2BUserFormInputs>;
  franchisesToSelect: Partial<CompanyDto>[] | undefined;
  loadingFranchises: boolean;
  handleRefetchFranchises: (name: string) => void;
  handleRemoveByPass: () => void;
  setFranchisesSearch: (name: string) => void;
  switchControl: boolean;
  setSwitchControl: React.Dispatch<React.SetStateAction<boolean>>;
  currentMessage: string;
  handleAddByPassMessage: (message: string) => void;
};

export const PayrollScheduleSettingsContext = createContext<{
  companyId: number;
  setCompanyId: (companyId: number) => void;
  isLoading: boolean;
  payrollStatusState: boolean;
  handleSubmit: UseFormHandleSubmit<createB2BUserFormInputs>;
  onSubmitByPass: () => void;
  onSubmitMessage: (data: createB2BUserFormInputs) => void;
  register: UseFormRegister<createB2BUserFormInputs>;
  franchisesToSelect: Partial<CompanyDto>[] | undefined;
  loadingFranchises: boolean;
  handleRefetchFranchises: (name: string) => void;
  handleRemoveByPass: () => void;
  setFranchisesSearch: (name: string) => void;
  switchControl: boolean;
  setSwitchControl: React.Dispatch<React.SetStateAction<boolean>>;
  currentMessage: string;
  handleAddByPassMessage: (message: string) => void;
}>({} as PayrollScheduleSettingsContextType);

export const PayrollScheduleSettingsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const toast = useToast();
  const [companyIdState, setCompanyIdState] = useState(0);
  const [switchControl, setSwitchControl] = useState(false);
  const [configId, setConfigId] = useState(0);

  const { register, handleSubmit } = useForm<createB2BUserFormInputs>({
    resolver: zodResolver(createB2BUserSchema),
  });

  const [payrollStatusState, setPayrollStatusState] = useState(false);

  const addByPassMutation = useMutation({
    mutationFn: async (companyId: number) => {
      await postWithAuth("/api/v1/payroll/bypass", {
        companyId,
      });
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Status alterado com sucesso",
        description: `O status foi alterado com sucesso!`,
      });
    },
  });

  const removeByPassMutation = useMutation({
    mutationFn: async (configId: number) => {
      await deleteWithAuth("/api/v1/payroll/bypass/" + configId);
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Status alterado com sucesso",
        description: `O status foi alterado com sucesso!`,
      });
    },
  });

  const addByPassMessageMutation = useMutation({
    mutationFn: async (message: string) => {
      await postWithAuth("/api/v1/payroll/message-bypass", {
        message,
        companyId: companyIdState,
      });
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Mensagem enviada com sucesso",
        description: `O envio da mensagem foi realizado com sucesso!`,
      });
    },
  });

  const handleAddByPassMessage = (message: string) => {
    addByPassMessageMutation.mutate(message);
  };

  const onSubmitByPass = () => {
    if (switchControl) {
      addByPassMutation.mutate(companyIdState);
    } else {
      removeByPassMutation.mutate(configId);
    }
  };

  const onSubmitMessage = () => {
    addByPassMutation.mutate(companyIdState);
  };

  const { isLoading } = useQuery({
    queryKey: ["payroll-status", companyIdState],
    queryFn: async () => {
      try {
        const response = await getWithAuth(
          "/api/v1/payroll/bypass/" + companyIdState
        );

        if (response?.status === 200 && response?.data?.key === "bypass") {
          setConfigId(response?.data?.id);

          setPayrollStatusState(true);
        } else {
          setPayrollStatusState(false);
        }
      } catch (error) {
        setPayrollStatusState(false);
      }
    },
    enabled: !!companyIdState,
    staleTime: 60 * 60 * 1000,
    refetchInterval: 5000,
  });

  const [franchisesSearch, setFranchisesSearch] = useState("");
  const {
    data: franchisesToSelect,
    isLoading: loadingFranchises,
    refetch: refetchFranchises,
  } = useQuery({
    queryKey: ["franchises", franchisesSearch],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/companies/list/franchisees/" + franchisesSearch
      );

      return response?.data as Partial<CompanyDto>[];
    },
  });

  const handleRefetchFranchises = (name: string) => {
    if (name.length > 2) {
      refetchFranchises();
    }
  };

  const handleRemoveByPass = () => {
    removeByPassMutation.mutate(companyIdState);
  };

  const [currentMessage, setCurrentMessage] = useState("");

  useQuery({
    queryKey: ["message", companyIdState],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/payroll/message-bypass/" + companyIdState
      );

      if (response?.status === 200) {
        console.log(response.data);
        setCurrentMessage(response?.data);
      } else {
        setCurrentMessage("");
      }
    },
    enabled: !!companyIdState,
    staleTime: 60 * 60 * 1000,
    refetchInterval: 5000,
  });

  return (
    <PayrollScheduleSettingsContext.Provider
      value={{
        companyId: companyIdState,
        setCompanyId: setCompanyIdState,
        isLoading,
        payrollStatusState,
        handleSubmit,
        onSubmitByPass,
        onSubmitMessage,
        register,
        franchisesToSelect,
        loadingFranchises,
        handleRefetchFranchises,
        handleRemoveByPass,
        setFranchisesSearch,
        switchControl,
        setSwitchControl,
        currentMessage,
        handleAddByPassMessage,
      }}
    >
      {children}
    </PayrollScheduleSettingsContext.Provider>
  );
};
