import { CustomModal } from "../../../components/modal";
import { CreateShipperForm } from "./create-shipper-form";

export function CreateShipperModalButton() {
  return (
    <>
      <CustomModal
        modalHeader="Adicionar novo embarcador"
        modalBody={<CreateShipperForm />}
        buttonText="Adicionar novo embarcador"
        doNotShowCloseButton
      />
    </>
  );
}
