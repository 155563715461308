import { AdvanceBlockedEnum } from "../../../../../enums/advance-blocked.enum";

export function advanceBlockedMapper(value: AdvanceBlockedEnum) {
  const mapper = {
    [AdvanceBlockedEnum.None]: "Sem bloqueio",
    [AdvanceBlockedEnum.All]: "Todos os entregadores",
    [AdvanceBlockedEnum.Cloud]: "Entregadores nuvem",
    [AdvanceBlockedEnum.NonActiveCloud]: "Entregadores nuvem não ativos",
    [AdvanceBlockedEnum.Ol]: "Entregadores OL",
  };

  return mapper[value];
}
