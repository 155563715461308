import { memo, useState } from "react";
import {
  FormControl,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Portal,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";
import { useCurrentUserStore } from "../../../../../store/current-user.store";
import { RoleEnum } from "../../../../../enums/roles-enum";

const Component = () => {
  const { control } = useForm();
  const toast = useToast();
  const [selectedDate, setSelectedDate] = useState<DateRange | undefined>();
  const userRoles = useCurrentUserStore((state) => state.user?.roles);

  const IsFinancial = userRoles?.find(
    (role) => role.name === RoleEnum.FINANCEIRO_TRAMPAY
  );

  if (!IsFinancial) {
    return null;
  }

  const handleExport = () => {
    if (selectedDate) {
      const { from: startDate, to: endDate } = selectedDate;

      const formattedStartDate = startDate
        ? format(startDate, "yyyy-MM-dd")
        : undefined;

      const formattedEndDate = endDate
        ? format(endDate, "yyyy-MM-dd")
        : formattedStartDate;

      const url = `https://ofxlocal-dot-durable-retina-397715.uc.r.appspot.com/gerar_ofx?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

      window.location.href = url;
      toast({
        title: "OFX gerado com sucesso",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } else {
      console.error("Nenhuma data selecionada");
    }
  };

  return (
    <FormControl as="fieldset" w="max-content">
      <Popover placement="right-end">
        <PopoverTrigger>
          <Button size="lg">Exportar OFX</Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent p={2} w="max-content">
            <PopoverBody>
              <Controller
                control={control}
                name="dateRange"
                render={({ field }) => (
                  <DayPicker
                    id="dateFilter"
                    mode="range"
                    selected={field.value as DateRange}
                    onSelect={(date) => {
                      field.onChange(date);
                      setSelectedDate(date);
                    }}
                    locale={ptBR}
                  />
                )}
              />
              <Flex alignItems="center" gap={15}>
                <Button
                  mt={4}
                  colorScheme="green"
                  size="md"
                  onClick={handleExport}
                  isDisabled={!selectedDate}
                >
                  Exportar OFX
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </FormControl>
  );
};

export const ExportOfx = memo(Component);
