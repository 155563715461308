import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { Debt } from "../../../../../../../dto/debt-dto";
import { useCloudManagementDebtsActionStore } from "../../store/action-store";
import { postWithAuth } from "../../../../../../../services/basicService";
import { useEffect } from "react";

type IProps = {
  readonly debt: Debt;
  readonly debitReceivingAccountId: number;
};

export function CloudManagementDebtsActionsModalContentPayOff({
  debt,
  debitReceivingAccountId,
}: IProps) {
  const { setAction } = useCloudManagementDebtsActionStore();

  useEffect(() => {
    setAction(async (twoAuthCode: number, twoAuthUuid: string) => {
      await postWithAuth("/api/v1/debt/cloud-management/pay-off", {
        debtId: debt.id,
        twoAuthUuid,
        twoAuthCode,
      });
    });
  }, [debt.id, setAction]);

  return (
    <Flex flexDir="column" fontFamily="Source Sans 3" gap={4}>
      <Flex flexDir="column" fontSize="xl" gap={2}>
        <Text fontWeight="bold">
          Você deseja realmente quitar o débito com o ID {debt.id}?
        </Text>
        <Text color="gray.700" fontSize="lg">
          Ele será adicionado à fila de pagamento, e todo o saldo disponível na
          conta do usuário será utilizado para quitar a dívida.
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Box>
          <Text fontFamily="Source Sans 3" fontSize="md">
            ID Conta saída
          </Text>
          <Input
            w="90%"
            fontFamily="Source Sans 3"
            fontSize="xl"
            value={debt.account_id}
            isDisabled={true}
          />
        </Box>
        <Box>
          <Text fontFamily="Source Sans 3" fontSize="md">
            ID Conta recebimento
          </Text>
          <Input
            w="90%"
            fontFamily="Source Sans 3"
            fontSize="xl"
            value={debitReceivingAccountId}
            isDisabled={true}
          />
        </Box>
      </Flex>
      <Text
        fontFamily="Source Sans 3, sans-serif"
        fontSize="medium"
        fontWeight="bold"
      >
        Confirmar essa ação?
      </Text>
    </Flex>
  );
}
