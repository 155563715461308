import { INTEGRATION } from "../enums/company-integration";
import { PixKeyType } from "../enums/pix-type";
import { IntegrationInfo } from "../types/integration-info.type";
import { Plaza } from "./plaza-dto";
import { Subplaza } from "./subplaza-dto";
import { Status } from "./user-dto";

export enum DELIVERYMAN_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  NEW = "new",
  DEBT = "debt",
  ADVANCE_BLOCKED = "advance_blocked",
}

export const deliverymanStatusTextMap = {
  active: "Ativo",
  inactive: "Inativo",
  new: "Novo",
  debt: "Em débito",
};

export interface SimplifiedCompany {
  id: number;
  name: string;
  status?: Status;
  integration?: INTEGRATION;
  integrations?: IntegrationInfo;
}

export interface SimplifiedDeliveryman {
  id?: number;
  idEntregador: string;
  document: string;
  nameIfood: string;
  nameTrampay: string;
}

export interface DeliverymanPlazaDto {
  id: number;
  deliverymanId: number;
  plazaId: number;
  plaza: Plaza;
}

export interface DeliverymanSubplazaDto {
  id: number;
  deliverymanId: number;
  subplazaId: number;
  subplaza: Subplaza;
}

export interface DeliverymanDto {
  companyId?: number;
  company: SimplifiedCompany;
  createdAt: string;
  updatedAt: string;
  document?: string;
  id: number;
  idEntregador?: string;
  status: DELIVERYMAN_STATUS;
  isActive: boolean;
  lastActiveDate: string;
  nameIfood: string;
  nameTrampay: string;
  phone: string | null;
  email: string | null;
  accountId: number | null;
  allowedWorkdays?: { weekdays?: { id: number; value: string }[] };
  plazas?: string[];
  plazaname?: string;
  subplazas: string[];
  deliverymanPlazas: DeliverymanPlazaDto[];
  deliverymanSubplazas: DeliverymanSubplazaDto[];
  pixType?: PixKeyType;
  pixKey?: string;
  stars: number;
  cnpj?: string;
}

export interface DeliverymanData {
  limit: number;
  nextPage: boolean;
  page: number;
  result: DeliverymanDto[];
  totalPages: number;
  totalRecords: number;
}

export interface createDeliveryMan {
  document: string;
  idEntregador?: string;
  nameIfood: string;
  nameTrampay: string;
  deliverymanPlazas?: DeliverymanPlazaDto[];
  deliverymanSubplazas?: DeliverymanSubplazaDto[];
  allowedWorkdays?: { weekdays?: { id: number; value: string }[] };
}

export interface EditDeliverymanDto {
  id: number;
  nameIfood: string;
  nameTrampay: string;
  document?: string | null;
  idEntregador?: string;
  deliverymanPlazas?: DeliverymanPlazaDto[];
  deliverymanSubplazas?: DeliverymanSubplazaDto[];
  allowedWorkdays?: { weekdays?: { id: number; value: string }[] };
  pixType?: PixKeyType;
  pixKey?: string;
  stars?: number;
  cnpj?: string | null;
  companyId?: number | null;
}

export interface ChangeDeliverymanStatus {
  id: number;
  status: DELIVERYMAN_STATUS;
}

export interface IUpdatePix {
  id: number;
  pixType: PixKeyType;
  pixKey: string;
}

export interface IUpdatePixError {
  document: string;
  pixKey: string;
  pixType: string;
  error: string;
}

export interface IUpdatePixResponse {
  validDeliverymen: IUpdatePixError[];
  invalidRows: IUpdatePixError[];
  csv: string;
}
