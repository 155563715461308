import { Box } from "@chakra-ui/react";
import { DebtStatus } from "../../../../enums/debt-status";

type DebtStatusType = "Reabrir" | "Reaberto";

const statusColors = {
  Reabrir: {
    bg: "#F4F4F4",
    color: "#6F6F6F",
  },
  Reaberto: {
    bg: "#E9F9F0",
    color: "#1DC161",
  },
};

function StatusBadge({ status }: { status: DebtStatus }) {
  const statusMaped: { [key in DebtStatus]: DebtStatusType } = {
    [DebtStatus.CLOSED]: "Reabrir",
    [DebtStatus.OPEN]: "Reaberto",
    [DebtStatus.DRAFT]: "Reabrir",
    [DebtStatus.SCHEDULED]: "Reabrir",
    [DebtStatus.CANCELED]: "Reabrir",
    [DebtStatus.EARLY_OPENED]: "Reabrir",
  };

  const { bg, color } = statusColors[statusMaped[status]] || {
    bg: "gray.100",
    color: "gray.700",
  };

  return (
    <Box
      bg={bg}
      color={color}
      display="flex"
      width="4.1875rem"
      height="1.125rem"
      padding="0.125rem 0.5rem"
      justifyContent="center"
      alignItems="center"
      gap="0.25rem"
      borderRadius={"0.25rem"}
    >
      <Box
        textAlign="center"
        fontFamily="'Source Sans 3'"
        fontSize="0.625rem"
        fontStyle="normal"
        fontWeight="800"
        lineHeight="1.25rem"
      >
        {statusMaped[status]}
      </Box>
    </Box>
  );
}

export default StatusBadge;
