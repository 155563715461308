import { DebtStatus } from "../../../../../../enums/debt-status";

export function debtStatusMapper(status: DebtStatus) {
  const statusMap = {
    [DebtStatus.CLOSED]: {
      label: "Quitado",
      background: "#e9f9f0",
      color: "#1DC161",
    },
    [DebtStatus.DRAFT]: {
      label: "Rascunho",
      background: "#f0f0f0",
      color: "#6f6f6f",
    },
    [DebtStatus.OPEN]: {
      label: "Aberto",
      background: "#FFF3ED",
      color: "#FF7E46",
    },
    [DebtStatus.SCHEDULED]: {
      label: "Agendado",
      background: "#faf4fd",
      color: "#D08CEF",
    },
    [DebtStatus.CANCELED]: {
      label: "Cancelado",
      background: "#F4F4F4",
      color: "#6F6F6F",
    },
    [DebtStatus.EARLY_OPENED]: {
      label: "Aberto antecipadamente",
      background: "#FFF3ED",
      color: "#FF7E46",
    },
  };

  return statusMap[status];
}
