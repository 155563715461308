import { DebtStatus } from "../enums/debt-status";
import { DebtType } from "../enums/debt-type";
import { CompanyDto } from "./company-dto";
import { DebtAuditDto } from "./debt-audit-dto";
import { DebtRefundDto } from "./debt-refund-dto";
import { Transaction } from "./transactions-dto";

export interface CreateDebt {
  description: string;
  amount: number;
  company_id: number;
  debt_date: Date;
}

export interface Debt {
  id: number;
  description: string;
  amount: number;
  debt_date: Date;
  account_id: number;
  launched_date: Date | null;
  quit_date: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  status: DebtStatus;
  type: DebtType;
  company: Partial<CompanyDto>;
  transactions: Transaction[];
  debtsAudit?: DebtAuditDto[];
  debtRefunds?: DebtRefundDto[];

  deliveryman?: {
    id: number;
    document: string;
    nameIfood: string;
    nameTrampay: string;
    accountId?: number;
  };
  userAdvances?: {
    id: number;
    amount: number;
    amount_fee_percentage: number;
    amount_fee_fixed: number;
    release_partner: string;
    contractNumber: string;
  }[];
}

export const debtTextMap = {
  [DebtStatus.CLOSED]: "Quitado",
  [DebtStatus.DRAFT]: "Rascunho",
  [DebtStatus.OPEN]: "Em aberto",
  [DebtStatus.SCHEDULED]: "Agendado",
  [DebtStatus.CANCELED]: "Cancelado",
  [DebtStatus.EARLY_OPENED]: "Aberto antecipadamente",
};

export const debtColorMap = {
  [DebtStatus.CLOSED]: "green",
  [DebtStatus.DRAFT]: "yellow",
  [DebtStatus.OPEN]: "red",
  [DebtStatus.SCHEDULED]: "blue",
  [DebtStatus.CANCELED]: "gray",
  [DebtStatus.EARLY_OPENED]: "orange",
};

export const debtTypeTextMap = {
  [DebtType.ADVANCES]: "Adiantamento",
  [DebtType.LOAN_DEFAULTS]: "Inadiplência de empréstimo",
  [DebtType.SERVICES]: "Serviços",
  [DebtType.OTHERS]: "Outros",
};

export type DebtTypeTextMapKey = keyof typeof debtTypeTextMap;
