import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import { patchWithAuth } from "../../../../services/basicService";
import { useMutation } from "@tanstack/react-query";

import { useCustomToast } from "./toast";
import { DebtStatus } from "../../../../enums/debt-status";

interface ReopenDebtModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  amount?: number;
  refetch: () => void;
}

const ReopenDebtModal: React.FC<ReopenDebtModalProps> = ({
  isOpen,
  onClose,
  id,
  amount,
  refetch,
}) => {
  const { showCustomToast } = useCustomToast();

  const mutation = useMutation({
    mutationFn: async () => {
      return await patchWithAuth(`/api/v1/debt/editDebt/${id.toString()}`, {
        status: DebtStatus.OPEN,
      });
    },
    onSuccess: () => {
      showCustomToast("Debito reaberto com sucesso", "success");
      refetch();
      onClose();
    },
    onError: (error) => {
      showCustomToast("Erro em abrir o débito", "error");
      console.log(error);
    },
  });

  const handleAccountChange = () => {
    mutation.mutate();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="23.75rem" borderRadius="2xl">
        <ModalHeader
          fontSize="3xl"
          bg="#fffcf2"
          h="100px"
          pb={8}
          borderTopRadius="3xl"
          boxShadow={" 0px 1px 4px 0px rgba(144, 144, 144, 0.25)"}
        >
          Reabrir débito
        </ModalHeader>
        <ModalCloseButton top={6} size="lg" color="gray.500" />

        <ModalBody p={4} fontFamily="Source Sans 3, sans-serif">
          <Flex direction={"column"} gap={2}>
            <Text fontSize={"1rem"}>
              O débito no valor abaixo será reaberto:
            </Text>
            <Text fontWeight={"bold"} fontSize={"1.375rem"}>
              R$ {amount && amount / 100}
            </Text>
          </Flex>
          <Flex marginTop={4}>
            <Text fontWeight={"bold"} fontSize={"1rem"}>
              Confirmar essa ação?
            </Text>
          </Flex>
          <Flex
            width={"100%"}
            justifyContent={"flex-end"}
            gap={4}
            marginTop={4}
          >
            <Button
              marginLeft={"auto"}
              borderRadius={"6.25rem"}
              bgColor={"#fff"}
              _hover={{ bg: "#f1f1f1" }}
              color={"#0B0B0B"}
              onClick={onClose}
              border={"1px"}
            >
              Cancelar
            </Button>
            <Button
              borderRadius={"6.25rem"}
              bgColor={"#0B0B0B"}
              _hover={{ bg: "#1A1A1A" }}
              onClick={() => {
                handleAccountChange();
              }}
            >
              Confirmar
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ReopenDebtModal;
