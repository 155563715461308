import { Box } from "@chakra-ui/layout";
import { Outlet, useNavigation } from "react-router-dom";
import { LoadingLogo } from "../../components/loading";
import { SubMenu } from "../../components-ui/sub-menu";

export function PortfolioManagementLayout() {
  const { state } = useNavigation();

  return (
    <Box gap={8} w="full" h="full" position="relative">
      <SubMenu
        menuTitle="Gestão de Carteira"
        routes={[
          {
            title: "Gestão de Crédito",
            path: "/portfolio-management",
          },
          {
            title: "Gestão de Folha",
            path: "/portfolio-management/scheduled-payroll",
          },
          {
            title: "Nuvem",
            routes: [
              {
                title: "Conciliação",
                path: "/portfolio-management/cloud-conciliation",
              },
              {
                title: "Gestão de débitos",
                path: "/portfolio-management/cloud-debts",
              },
            ],
          },
        ]}
      />
      {state === "loading" ? <LoadingLogo /> : <Outlet />}
    </Box>
  );
}
