import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import { useHistoryPointsContext } from "../provider/history-points.provider";

function GiftFilterComponent() {
  const { handleFilter, setWhereParams, categories } =
    useHistoryPointsContext();
  return (
    <Stack>
      <Flex gap={4}>
        <FormControl id="nome-premio" flex="1">
          <FormLabel>Nome do Prêmio:</FormLabel>
          <Input
            placeholder="Digite aqui o nome do prêmio"
            bg="gray.100"
            onChange={(e) =>
              setWhereParams({
                name: e.target.value,
              })
            }
          />
        </FormControl>

        <FormControl id="categoria" flex="1">
          <FormLabel>Categoria:</FormLabel>
          <Select
            placeholder="Selecione uma categoria"
            bg="gray.100"
            onChange={(e) => setWhereParams({ category: e.target.value })}
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>

      <Flex
        justifyContent={"space-between"}
        gap={4}
        display={"flex"}
        flexDir={"row"}
      >
        <FormControl id="data-ativacao" width="50%">
          <FormLabel>Data de Ativação:</FormLabel>
          <Input
            type="date"
            bg="gray.100"
            onChange={(e) =>
              setWhereParams({ createdAt: new Date(e.target.value) })
            }
          />
        </FormControl>

        <Flex alignItems={"flex-end"}>
          <Button colorScheme="green" onClick={handleFilter}>
            Filtrar
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
}

export default GiftFilterComponent;
