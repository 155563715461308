import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ReactNode, ComponentProps } from "react";

type ComposableTableProps = ComponentProps<typeof TableContainer> & {
  children: ReactNode;
};

type ComposableTableHeadProps = ComponentProps<typeof Thead> & {
  children: ReactNode;
};

type ComposableTableBodyProps = ComponentProps<typeof Tbody> & {
  children: ReactNode;
};

type ComposableTableRowProps = ComponentProps<typeof Tr> & {
  children: ReactNode;
  isStriped?: boolean;
};

type ComposableTableCellProps = ComponentProps<typeof Td> & {
  children: ReactNode;
};

type ComposableTableHeaderCellProps = ComponentProps<typeof Th> & {
  children: ReactNode;
};

export const ComposableTable = ({
  children,
  ...props
}: ComposableTableProps) => {
  return (
    <TableContainer
      w="full"
      bg="white"
      boxShadow="md"
      borderRadius="md"
      overflowY="auto"
      overflowX="auto"
      {...props}
    >
      <Table variant="unstyled">{children}</Table>
    </TableContainer>
  );
};

export const ComposableTableHead = ({
  children,
  ...props
}: ComposableTableHeadProps) => {
  return (
    <Thead
      bg="white"
      boxShadow="0 1px 0 0 #E2E2E0"
      position="sticky"
      top={0}
      zIndex="1"
      {...props}
    >
      <Tr>{children}</Tr>
    </Thead>
  );
};

export const ComposableTableBody = ({
  children,
  ...props
}: ComposableTableBodyProps) => {
  return <Tbody {...props}>{children}</Tbody>;
};

export const ComposableTableRow = ({
  children,
  isStriped,
  ...props
}: ComposableTableRowProps) => {
  return (
    <Tr
      bg={isStriped ? "gray.50" : "white"}
      fontSize="1rem"
      borderBottom="1px solid var(--Neutral-200, #E2E2E0)"
      borderColor="gray.200"
      alignSelf={"stretch"}
      _hover={{ bg: "gray.200" }}
      {...props}
    >
      {children}
    </Tr>
  );
};

export const ComposableTableCell = ({
  children,
  ...props
}: ComposableTableCellProps) => {
  return (
    <Td
      textAlign="center"
      backgroundImage="url('/assets/line.svg')"
      backgroundRepeat="no-repeat"
      backgroundPosition="right center"
      backgroundSize="1px"
      {...props}
    >
      {children}
    </Td>
  );
};

export const ComposableTableHeaderCell = ({
  children,
  ...props
}: ComposableTableHeaderCellProps) => {
  return (
    <Th
      textAlign="center"
      fontSize="md"
      py={3}
      backgroundImage="url('/assets/line.svg')"
      backgroundRepeat="no-repeat"
      backgroundPosition="right center"
      backgroundSize="1px 60%"
      textTransform="none"
      {...props}
    >
      {children}
    </Th>
  );
};
