import { useRef, useState } from "react";
import { useUserBalanceStore } from "../../../store/user-balance.store";
import {
  VStack,
  Heading,
  Badge,
  HStack,
  Flex,
  Box,
  Text,
  Stack,
} from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Icon,
  useColorMode,
  useToast,
  Stat,
  StatLabel,
  StatNumber,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  RadioGroup,
  Radio,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  Popover,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowDown, ArrowUp, Eye, List, Trash } from "@phosphor-icons/react";
import { documentCpfCnpjFormatter } from "../../../utils/format-document";
import { convertCentsToReais } from "../../../utils/convert-money";
import dayjs from "dayjs";
import { Pagination } from "../../../components/pagination";
import { useQuery } from "@tanstack/react-query";
import { LoadingLogo } from "../../../components/loading";
import { deleteWithAuth, getWithAuth } from "../../../services/basicService";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { fortnightValidForToday } from "../../../utils/fortnight-valid-for-today";
import { DrawerBalanceDetails } from "./drawer-balance-details";
import { UserBalanceDto } from "../../../dto/user-balance-dto";

const filterBalanceSchema = z.object({
  search: z.string(),
  balancesUsed: z.string(),
  getDeletedBalances: z.string(),
  companyId: z.string(),
});

type filterBalanceFormInputs = z.infer<typeof filterBalanceSchema>;

type DeleteBalanceData = {
  id: number;
  name: string;
  balanceDate: string;
  createdAt: string;
};

export function UserBalanceSupport() {
  const selectedBalance = useRef<UserBalanceDto>();
  const deleteBalanceData = useRef<DeleteBalanceData>();
  const [deleteBalanceLoading, setDeleteBalanceLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [range, setRange] = useState<DateRange | undefined>(
    fortnightValidForToday()
  );
  const [isOrderByDesc, setIsOrderByDesc] = useState<boolean>(true);

  const userBalance = useUserBalanceStore((state) => state.userBalance);
  const getUserBalance = useUserBalanceStore((state) => state.getUserBalance);

  const { colorMode } = useColorMode();
  const toast = useToast({ duration: 4000, isClosable: true, position: "top" });

  const fetchBalanceData = async () => {
    const dataForm = getValues();
    const filters = urlGenerate(dataForm);
    try {
      return await getUserBalance(`/api/v1/userAdvanceBalance?${filters}`);
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao buscar saldos",
        description:
          "Não foi possível buscar saldos. Tente novamente ou contate o suporte.",
        status: "error",
      });
    }
  };

  const { isLoading, isFetching, refetch } = useQuery(
    ["balance-data", page, limit, isOrderByDesc],
    fetchBalanceData
  );

  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as { name: string; id: string }[];
    },
  });

  const handleItemsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
    setPage(1);
    setIsOrderByDesc(true);
  };

  const urlGenerate = (data: filterBalanceFormInputs) => {
    const params = [];

    if (data.search) {
      params.push(`search=${data.search}`);
    }

    if (data.companyId) {
      params.push(`companyId=${data.companyId}`);
    }

    if (data.balancesUsed !== "all" && data.balancesUsed !== undefined) {
      params.push(`balancesUsed=${data.balancesUsed}`);
    }

    if (JSON.parse(data.getDeletedBalances)) {
      params.push(`getDeletedBalances=${data.getDeletedBalances}`);
    }

    if (range) {
      params.push(`startDay=${range.from?.toISOString()}`);
    }

    if (range?.to) {
      const date = range.to.toISOString();

      params.push(`endDay=${date}`);
    }

    if (range?.to === undefined && range?.from !== undefined) {
      const date = range.from.toISOString();

      params.push(`endDay=${date}`);
    }

    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    let url = `page=${page}&limit=${limit}&timezoneOffset=${timezoneOffset}`;

    if (isOrderByDesc) {
      url += `&orderBy=DESC`;
    } else {
      url += `&orderBy=ASC`;
    }

    url += `&${params.join("&")}`;

    return url;
  };

  const deleteBalance = useDisclosure({ id: "delete-balance" });
  const drawerBalanceDetails = useDisclosure({ id: "drawer-balance-details" });

  const { register, handleSubmit, getValues, control } =
    useForm<filterBalanceFormInputs>({
      resolver: zodResolver(filterBalanceSchema),
      defaultValues: { getDeletedBalances: "false" },
    });

  const handleOpenDeleteBalancesModal = (data: DeleteBalanceData) => {
    deleteBalanceData.current = data;

    deleteBalance.onOpen();
  };

  const handleDeleteBalances = () => {
    if (deleteBalanceLoading) {
      return;
    }

    setDeleteBalanceLoading(true);

    const URL = `/api/v1/userAdvanceBalance/${deleteBalanceData.current?.id}`;

    deleteWithAuth(URL)
      .then(() => {
        refetch();

        deleteBalance.onClose();

        toast({
          status: "success",
          title: "Sucesso ao deletar o saldo",
          description: `Saldo do(a) ${deleteBalanceData.current?.name} foi deletado`,
        });
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Erro ao deletar o saldo",
          description: `Não foi possível deletar o saldo`,
        });
      })
      .finally(() => setDeleteBalanceLoading(false));
  };

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Saldos
      </Heading>
      <Flex gap={8}>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize="xl">Total de registros:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {userBalance?.totalRecords || 0}
            </StatNumber>
          </Stat>
        </Box>
      </Flex>
      {userBalance && userBalance?.result?.length >= 0 ? (
        <TableContainer
          w="full"
          flex={1}
          p={8}
          gap={4}
          display="flex"
          flexDir="column"
          border="1px"
          borderRadius={8}
          bg={colorMode === "dark" ? "gray.700" : "whiteAlpha.800"}
          borderColor={colorMode === "dark" ? "" : "gray.300"}
          shadow="base"
        >
          <Flex
            as="form"
            flexDir="column"
            gap={4}
            id="form"
            w="full"
            p={4}
            onSubmit={handleSubmit(() => refetch())}
          >
            <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
              Filtros
            </Heading>
            <Flex
              flexDir={{ base: "column", lg: "row" }}
              w="full"
              justifyContent="center"
              alignItems="center"
              gap={8}
            >
              <FormControl as="fieldset">
                <FormLabel as="legend">CPF/ID/Nome</FormLabel>
                <Input
                  type="text"
                  {...register("search")}
                  bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                  fontWeight="bold"
                  color={colorMode === "dark" ? "gray.300" : "gray.700"}
                  placeholder="Busque pelo CPF, ID ou nome do entregador"
                  _placeholder={{
                    color: "gray.500",
                  }}
                />
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Empresa</FormLabel>
                <Select
                  variant="filled"
                  {...register("companyId")}
                  size="md"
                  w="full"
                >
                  <option value="">Todos</option>
                  {companies?.map((company) => (
                    <option value={company.id} key={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl as="fieldset" w="max-content">
                <FormLabel as="legend">Data de referência</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button>Escolha a sua data</Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent
                      bg={colorMode === "dark" ? "gray.900" : "white"}
                      p={2}
                      w="max-content"
                    >
                      <PopoverBody>
                        <DayPicker
                          id="dateFilter"
                          mode="range"
                          selected={range}
                          onSelect={setRange}
                          locale={ptBR}
                          modifiersClassNames={{
                            disabled: "btn-disabled",
                            selected: "custom-selected",
                            outside: "outside-day",
                          }}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Saldos</FormLabel>
                <Select
                  variant="filled"
                  size="md"
                  {...register("balancesUsed")}
                  w="full"
                >
                  <option value="all">Todos</option>
                  <option value="true">Utilizados</option>
                  <option value="false">Não Utilizados</option>
                </Select>
              </FormControl>
              <FormControl w="fit-content">
                <FormLabel display="flex" gap="12px">
                  Buscar deletados?
                </FormLabel>
                <Controller
                  name="getDeletedBalances"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Stack direction="row">
                        <Radio value="true" colorScheme="green">
                          Sim
                        </Radio>
                        <Radio value="false" colorScheme="red">
                          Não
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>

              <Button
                type="submit"
                form="form"
                bg="green.600"
                w="max-content"
                color="whiteAlpha.900"
                _hover={{
                  bg: "green.700",
                }}
                px={8}
                size="md"
                alignSelf="flex-end"
                isDisabled={isFetching}
                isLoading={isFetching}
              >
                Filtrar
              </Button>
            </Flex>
          </Flex>
          <Table>
            <Thead>
              <Tr>
                <Th flex={1}>Nome</Th>
                <Th textAlign="center">DOCUMENTO</Th>
                <Th textAlign="center">ID Entregador</Th>
                <Th textAlign="center">Empresa</Th>
                <Th isNumeric>Saldo</Th>
                <Th textAlign="center">Status</Th>
                <Th
                  textAlign="center"
                  onClick={() => setIsOrderByDesc(!isOrderByDesc)}
                  cursor="pointer"
                >
                  Data de referência
                  {isOrderByDesc ? (
                    <Icon as={ArrowDown} />
                  ) : (
                    <Icon as={ArrowUp} />
                  )}
                </Th>
                <Th textAlign="center">Data de criação</Th>
                <Th textAlign="center">Ação</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading ? (
                userBalance?.result.map((balance) => {
                  return (
                    <Tr fontWeight={600} key={balance.id}>
                      <Td
                        maxW="200px"
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        textTransform={"capitalize"}
                      >
                        {balance ? balance?.name?.toLowerCase() : "-"}
                      </Td>
                      <Td textAlign="center">
                        {documentCpfCnpjFormatter(balance.document)}
                      </Td>
                      <Td textAlign="center">
                        {balance.idEntregador ? balance.idEntregador : "-"}
                      </Td>
                      <Td textAlign="center">{balance.company}</Td>
                      <Td isNumeric>{convertCentsToReais(balance.balance)}</Td>
                      <Td textAlign="center">
                        {balance.deletedAt ? (
                          <Badge colorScheme="red" p={2} borderRadius={7}>
                            Deletado
                          </Badge>
                        ) : balance.wasUsed ? (
                          <Badge colorScheme="green" p={2} borderRadius={7}>
                            Utilizado
                          </Badge>
                        ) : (
                          <Badge colorScheme="purple" p={2} borderRadius={7}>
                            Não utilizado
                          </Badge>
                        )}
                      </Td>
                      <Td textAlign="center">
                        {dayjs(balance.balanceDate).format("DD/MM/YYYY")}
                      </Td>
                      <Td textAlign="center">
                        {dayjs(balance.createdAt).format("DD/MM/YYYY HH:mm")}
                      </Td>
                      <Td textAlign="center">
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<List size={24} />}
                            variant="solid"
                          />
                          <MenuList>
                            <MenuItem
                              fontSize="md"
                              icon={<Eye size={24} color="green" />}
                              onClick={() => {
                                selectedBalance.current = balance;

                                drawerBalanceDetails.onOpen();
                              }}
                            >
                              Detalhar
                            </MenuItem>
                            {(balance.deletedAt === null ||
                              (!balance.wasUsed &&
                                balance.deletedAt === null)) && (
                              <MenuItem
                                fontSize="md"
                                icon={<Trash size={24} color="red" />}
                                onClick={() =>
                                  handleOpenDeleteBalancesModal({
                                    id: balance.id,
                                    name: balance.name,
                                    balanceDate: balance.balanceDate,
                                    createdAt: balance.createdAt,
                                  })
                                }
                              >
                                Deletar
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <LoadingLogo />
              )}
            </Tbody>
          </Table>
          <HStack gap={8} pt={8} justifyContent="flex-end">
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={userBalance?.totalPages || 1}
            />
            <Select
              variant="filled"
              size="md"
              w="100px"
              onChange={handleItemsPerPage}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Select>
          </HStack>
        </TableContainer>
      ) : (
        <Text fontSize="x-large" fontWeight="extrabold">
          Ainda não foram feitos adiantamentos no dia de hoje.
        </Text>
      )}
      <Modal
        isOpen={deleteBalance.isOpen}
        onClose={deleteBalance.onClose}
        size="5xl"
        id="delete-balance"
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader fontSize="3xl">
            Confirmar Ação de Deletar Saldo
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <Flex flexDirection="column" fontSize="2xl">
              <Flex marginBottom="5">
                Essa ação ira deletar o saldo do(a){" "}
                {deleteBalanceData.current?.name}
              </Flex>
              <Flex as="ul" flexDirection="column" gap="5px">
                <li>
                  data de referencia:{" "}
                  {dayjs(deleteBalanceData.current?.balanceDate).format(
                    "DD/MM/YYYY"
                  )}
                </li>
                <li>
                  data de criação:{" "}
                  {dayjs(deleteBalanceData.current?.createdAt).format(
                    "DD/MM/YYYY"
                  )}
                </li>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter my={4}>
            <Button
              onClick={deleteBalance.onClose}
              isDisabled={deleteBalanceLoading}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteBalances}
              ml={3}
              isLoading={deleteBalanceLoading}
              isDisabled={deleteBalanceLoading}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {selectedBalance.current && (
        <DrawerBalanceDetails
          balance={selectedBalance.current}
          isOpen={drawerBalanceDetails.isOpen}
          onClose={drawerBalanceDetails.onClose}
        />
      )}
    </VStack>
  );
}
