import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Box,
  Text,
  Flex,
  Grid,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
  AccordionIcon,
  Tooltip,
  DrawerFooter,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import {
  CalendarBlank,
  CurrencyCircleDollar,
  DownloadSimple,
  Info,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../../services/basicService";
import { GetDebtCloudTransactionsInfoDto } from "../../../../../types/get-debt-transaction-info.type";
import { formatCPF } from "../../../../../utils/format-document";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import dayjs from "dayjs";
import { debtStatusMap } from "./functions/status-mapper";
import { useExportCsv } from "../../../../../hooks/adiantamento/use-export-csv";
import {
  ComposableTable,
  ComposableTableHead,
  ComposableTableHeaderCell,
  ComposableTableBody,
  ComposableTableRow,
  ComposableTableCell,
} from "../../../../../components-ui/table";
import { transactionStatusMapper } from "../debts/functions/transaction-status-mapper";
import { DetailCard } from "./detail-card";

interface TransactionDrawerProps {
  debtId: number;
  transactionId: number;
  isOpen: boolean;
  onClose: () => void;
}

const InfoRow: React.FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => (
  <Flex
    justifyContent="space-between"
    px={4}
    py={2}
    borderBottom="1px solid"
    borderColor="gray.200"
    _last={{ borderBottom: "none" }}
  >
    <Text fontSize="md">{label}</Text>
    <Text fontSize="md" fontWeight="extrabold">
      {value}
    </Text>
  </Flex>
);

const TransactionDrawer: React.FC<TransactionDrawerProps> = ({
  debtId,
  transactionId,
  isOpen,
  onClose,
}) => {
  const btnRef = React.useRef(null);
  const { data, isLoading } = useQuery({
    queryKey: [`get-debt:${debtId}-transaction:${transactionId}`],
    queryFn: async () => {
      const PATH = `/api/v1/debt/${debtId}/transaction/${transactionId}`;

      const res = await getWithAuth(PATH);

      return res?.data as GetDebtCloudTransactionsInfoDto;
    },
  });
  console.log("🚀 ~ data:", data);
  const { exportCsv, loading: loadingExportCsv } = useExportCsv();

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="xl"
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent maxW="900px" maxH="100vh" borderRadius="md">
        <DrawerHeader
          bg="#fffcf2"
          shadow="sm"
          px={10}
          py={8}
          fontFamily="Source Sans 3, sans-serif"
          fontSize="3xl"
          fontWeight="bold"
          borderRadius="md"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Detalhes da transação</Text>
            <Button
              onClick={onClose}
              fontSize="3xl"
              bg="inherit"
              color="gray.500"
              _hover={{ color: "gray.700" }}
            >
              ✕
            </Button>
            {/* <DrawerCloseButton /> */}
          </Flex>
        </DrawerHeader>

        <DrawerBody overflowY="auto" p={0}>
          {!isLoading && data ? (
            <Box p={6}>
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="2xl"
                mb={4}
              >
                Informações gerais
              </Text>

              <Flex flexDir="column" gap={4} mb={8}>
                {(data?.debtRefunds?.length || 0) > 0 && (
                  <Accordion
                    allowToggle
                    bg="#f4f4f4"
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                  >
                    <AccordionItem border="none">
                      <AccordionButton
                        display="flex"
                        alignItems="center"
                        _hover={{ bg: "transparent" }}
                      >
                        <Text
                          flex="1"
                          textAlign="left"
                          fontWeight="semibold"
                          fontSize="lg"
                        >
                          Estornos
                        </Text>
                        <AccordionIcon
                          color="#6F6F6F"
                          fontSize="3xl"
                          fontWeight="extrabold"
                        />
                      </AccordionButton>
                      <AccordionPanel p="inherit">
                        <ComposableTable>
                          <ComposableTableHead>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              ID
                            </ComposableTableHeaderCell>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              Valor
                            </ComposableTableHeaderCell>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              Status
                            </ComposableTableHeaderCell>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              Data transação
                            </ComposableTableHeaderCell>
                          </ComposableTableHead>
                          <ComposableTableBody>
                            {data?.debtRefunds?.map((item, index) => {
                              const { background, color, label } =
                                transactionStatusMapper(
                                  item?.transactionStatus
                                );

                              return (
                                <ComposableTableRow
                                  key={item.id}
                                  isStriped={index % 2 !== 0}
                                >
                                  <ComposableTableCell fontSize="sm" py={2}>
                                    {item.id}
                                  </ComposableTableCell>
                                  <ComposableTableCell fontSize="sm" py={2}>
                                    {convertCentsToReais(item.amount)}
                                  </ComposableTableCell>
                                  <ComposableTableCell fontSize="sm" py={0}>
                                    <Flex w="full" justifyContent="center">
                                      <Text
                                        w="fit-content"
                                        px={4}
                                        py={1}
                                        bg={background}
                                        color={color}
                                        borderRadius="xl"
                                        fontFamily="Source Sans 3, sans-serif"
                                        fontWeight="bold"
                                      >
                                        {label}
                                      </Text>
                                    </Flex>
                                  </ComposableTableCell>
                                  <ComposableTableCell fontSize="sm" py={2}>
                                    {item?.createdAt &&
                                      dayjs(item?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                  </ComposableTableCell>
                                </ComposableTableRow>
                              );
                            })}
                          </ComposableTableBody>
                        </ComposableTable>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}

                {(data?.userAdvances?.length || 0) > 0 && (
                  <Accordion
                    allowToggle
                    bg="#f4f4f4"
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                  >
                    <AccordionItem border="none">
                      <AccordionButton
                        display="flex"
                        alignItems="center"
                        _hover={{ bg: "transparent" }}
                      >
                        <Text
                          flex="1"
                          textAlign="left"
                          fontWeight="semibold"
                          fontSize="lg"
                        >
                          Empréstimos
                        </Text>
                        <AccordionIcon
                          color="#6F6F6F"
                          fontSize="3xl"
                          fontWeight="extrabold"
                        />
                      </AccordionButton>
                      <AccordionPanel p="inherit">
                        <ComposableTable>
                          <ComposableTableHead>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              N.º Contrato
                            </ComposableTableHeaderCell>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              Valor
                            </ComposableTableHeaderCell>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              Taxa (%)
                            </ComposableTableHeaderCell>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              Taxa (R$)
                            </ComposableTableHeaderCell>
                            <ComposableTableHeaderCell fontSize="sm" py={2}>
                              Vendido para
                            </ComposableTableHeaderCell>
                          </ComposableTableHead>
                          <ComposableTableBody>
                            {data?.userAdvances?.map((item, index) => (
                              <ComposableTableRow
                                key={item.id}
                                isStriped={index % 2 !== 0}
                              >
                                <ComposableTableCell fontSize="md" py={2}>
                                  {item.contractNumber}
                                </ComposableTableCell>
                                <ComposableTableCell fontSize="md" py={2}>
                                  {convertCentsToReais(item.amount)}
                                </ComposableTableCell>
                                <ComposableTableCell fontSize="md" py={2}>
                                  {item?.amount_fee_percentage}
                                </ComposableTableCell>
                                <ComposableTableCell fontSize="md" py={2}>
                                  {item?.amount_fee_fixed}
                                </ComposableTableCell>
                                <ComposableTableCell fontSize="md" py={2}>
                                  {item.release_partner}
                                </ComposableTableCell>
                              </ComposableTableRow>
                            ))}
                          </ComposableTableBody>
                        </ComposableTable>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
              </Flex>
              <Grid templateColumns="1fr 1fr" gap={6}>
                {/* Left Column */}
                <Box>
                  <Box
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                    mb={6}
                  >
                    <InfoRow label="ID do débito:" value={data.id} />
                    <InfoRow label="Nome:" value={data.deliveryman.nameIfood} />
                    <InfoRow
                      label="Documento:"
                      value={formatCPF(data.deliveryman.document)}
                    />
                    <InfoRow
                      label="Conta driver:"
                      value={data.deliveryman.accountId}
                    />
                    <InfoRow
                      label="Conta saída: (Driver)"
                      value={data.transaction.accountIdFrom}
                    />
                    <InfoRow
                      label="Conta entrada:"
                      value={data.transaction.accountIdTo}
                    />
                    <InfoRow label="Empresa:" value={data.company.name} />
                    <InfoRow
                      label="Foi estornado:"
                      value={data.transaction.debtId ? "Não" : "Sim"}
                    />
                  </Box>

                  {/* Activity Log */}
                  {data.debtStatusAudits?.length > 0 && (
                    <Box bg="white" w="full" mt={5}>
                      <Flex justify="space-between" alignItems="center">
                        <Flex align="center" gap={2} mb={2}>
                          <Text fontWeight="semibold" fontSize="xl">
                            Registro de atividades:
                          </Text>
                          <Tooltip
                            hasArrow
                            placement="top"
                            textAlign="center"
                            fontSize="md"
                            label="Registros de atividades referentes a dívida."
                            borderRadius="md"
                          >
                            <Info size={18} />
                          </Tooltip>
                        </Flex>

                        <Button
                          variant="outline"
                          size="md"
                          leftIcon={<Icon as={DownloadSimple} boxSize={4} />}
                          mb={4}
                          colorScheme="gray"
                          borderColor="gray.300"
                          isLoading={loadingExportCsv}
                          loadingText="Exportando"
                          disabled={loadingExportCsv}
                          onClick={() => {
                            exportCsv(
                              `/api/v1/debt/${debtId}/status-audit/csv`,
                              "debt-status-audit",
                              ""
                            );
                          }}
                        >
                          Exportar .CSV
                        </Button>
                      </Flex>

                      <Box>
                        {data.debtStatusAudits.map((item) => {
                          const { bg, color, label } = debtStatusMap(
                            item.newStatus
                          );

                          return (
                            <Flex
                              key={item.id}
                              mb={4}
                              bg={bg}
                              p={2}
                              borderRadius="xl"
                              gap={2}
                              fontFamily="Source Sans 3, sans-serif"
                            >
                              <CalendarBlank size={20} color={color} />
                              <Flex>
                                <Text
                                  fontSize="small"
                                  fontWeight="extrabold"
                                  color={color}
                                >
                                  {dayjs(item.createdAt).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </Text>
                                <Flex>
                                  <Text fontSize="small" color={color}>
                                    &nbsp;-&nbsp;[{label}] por:&nbsp;
                                  </Text>
                                  <Text
                                    fontWeight="extrabold"
                                    fontSize="small"
                                    color={color}
                                  >
                                    {item.changedByUserId
                                      ? item.user.firstName + item.user.lastName
                                      : "Sistema"}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* Right Column */}
                <Box display="flex" flexDirection="column" gap={4}>
                  <DetailCard
                    label="Total transação"
                    icon={<CurrencyCircleDollar size={32} />}
                    value={convertCentsToReais(data.transaction.amount)}
                  />
                  <DetailCard
                    label="Valor débito"
                    icon={<CurrencyCircleDollar size={32} />}
                    value={convertCentsToReais(data.amount)}
                  />
                  <DetailCard
                    label="Valor creditado"
                    icon={<CurrencyCircleDollar size={32} />}
                    value={convertCentsToReais(
                      data.totalSuccessDebtTransactions - data.debtRefundsAmount
                    )}
                  />
                  <DetailCard
                    label="Data transação"
                    icon={<CalendarBlank size={32} />}
                    value={dayjs(
                      data.transaction.transactionDate ??
                        data.transaction.transactionResponse?.transactionDate ??
                        data.transaction.createdAt
                    ).format("DD/MM/YYYY")}
                  />

                  {data.quit_date && (
                    <DetailCard
                      label="Data quitação"
                      icon={<CalendarBlank size={32} />}
                      value={dayjs(data.quit_date).format("DD/MM/YYYY")}
                    />
                  )}
                </Box>
              </Grid>
            </Box>
          ) : (
            <Flex w="full" h="full" alignItems="center" justifyContent="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          )}
        </DrawerBody>
        <DrawerFooter h="fit-content" w="full">
          <Flex
            h="fit-content"
            w="full"
            flexDirection="column"
            alignItems="end"
            gap={5}
          >
            <Divider />
            <Button
              variant="solid"
              w="fit-content"
              bg="black"
              size="lg"
              borderRadius="full"
              fontSize="medium"
              onClick={onClose}
              _hover={{ bg: "black" }}
            >
              Fechar
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default TransactionDrawer;
