import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { postWithAuth } from "../../../services/basicService";
import { AxiosError } from "axios";
import { AxiosDataError } from "../../../dto/axios-error-dto";

const createShipperFormSchema = z.object({
  name: z.string().nonempty(),
  cnpj: z
    .string()
    .nullable()
    .refine((cnpj) => !cnpj || cnpj.length === 14, {
      message: "CNPJ deve ter exatamente 14 caracteres.",
    }),
});

type createShipperFormInputs = z.infer<typeof createShipperFormSchema>;

export function CreateShipperForm() {
  const toast = useToast();
  const client = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<createShipperFormInputs>({
    resolver: zodResolver(createShipperFormSchema),
  });

  const createShipperMutation = useMutation({
    mutationFn: (data: createShipperFormInputs) =>
      postWithAuth(`/api/v1/shipper`, { ...data, status: "active" }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Sucesso",
        description: `Embarcador criado com sucesso`,
      });

      client.invalidateQueries(["all-shippers"]);
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao criar embarcador",
        description: `Erro! ${error.message}`,
      });
    },
  });

  const handleCreate = (data: createShipperFormInputs) => {
    if (createShipperMutation.isLoading) return;

    if (!data.cnpj) data.cnpj = null;

    createShipperMutation.mutate(data);
  };

  return (
    <Flex
      gap={4}
      as="form"
      flexDir="column"
      id="form"
      onSubmit={handleSubmit(handleCreate)}
    >
      <FormControl as="fieldset" isInvalid={!!errors.name}>
        <FormLabel as="legend">Razão social</FormLabel>
        <Input
          type="text"
          {...register("name")}
          bg={"gray.100"}
          fontWeight="bold"
          color={"gray.700"}
          placeholder="Razão social"
          _placeholder={{
            color: "gray.500",
          }}
        />
        {errors.name ? (
          <FormErrorMessage>{errors.name.message}</FormErrorMessage>
        ) : null}
      </FormControl>

      <FormControl as="fieldset" isInvalid={!!errors.cnpj}>
        <FormLabel as="legend">CNPJ (Opcional)</FormLabel>
        <Input
          type="text"
          {...register("cnpj")}
          bg={"gray.100"}
          fontWeight="bold"
          color={"gray.700"}
          placeholder="Apenas números"
          _placeholder={{
            color: "gray.500",
          }}
        />
        {errors.cnpj ? (
          <FormErrorMessage>{errors.cnpj.message}</FormErrorMessage>
        ) : null}
      </FormControl>

      <Button
        type="submit"
        form="form"
        bg="green.600"
        w="max-content"
        color="whiteAlpha.900"
        _hover={{
          bg: "green.700",
        }}
        px={8}
        size="md"
        alignSelf="flex-end"
        isDisabled={!isDirty}
        isLoading={createShipperMutation.isLoading}
      >
        Adicionar
      </Button>
    </Flex>
  );
}
