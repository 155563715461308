import { z } from "zod";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { Plaza } from "../../../../../dto/plaza-dto";
import { Subplaza } from "../../../../../dto/subplaza-dto";
import { useSubplazaCachedService } from "../../../../../services/api/subplazas";
import { usePlazaCachedService } from "../../../../../services/api/plaza";
import { Shift } from "../../../../../dto/shift-dto";
import { useShiftCachedService } from "../../../../../services/api/shifts";

const buildFormFields = (
  plazas: Plaza[] | undefined,
  subplazas: Subplaza[] | undefined,
  shifts: Shift[] | undefined
) => {
  const formFields: FormField[] = [
    {
      label: "Status",
      name: "status",
      type: "select",
      options: [
        { value: "pending", label: "pendente" },
        { value: "accepted", label: "aceito" },
        { value: "unaccepted", label: "rejeitado" },
      ],
    },
    {
      label: "Praça",
      name: "plazaId",
      type: "select",
      options: plazas?.map((plaza: Plaza) => {
        const { name, id } = plaza;
        return { label: name, value: id };
      }),
    },
    {
      label: "Sub-praça",
      name: "subplazaId",
      type: "select",
      options: subplazas?.map((plaza: Subplaza) => {
        const { name, id } = plaza;
        return { label: name, value: id };
      }),
    },
    {
      label: "Turno",
      name: "shiftId",
      type: "select",
      options: shifts?.map((shift: Shift) => {
        const { name, id } = shift;
        return { label: name, value: id };
      }),
    },
    {
      label: "Data da sinalização a partir de",
      name: "fromCreatedAt",
      type: "date",
    },
    {
      label: "Data da sinalização até",
      name: "toCreatedAt",
      type: "date",
    },
  ];
  return formFields;
};

// TODO: change to use correctly form schema
const formSchema = z.object({
  status: z.string().optional(),
  plazaId: z.string().optional(),
  subplazaId: z.string().optional(),
  shiftId: z.string().optional(),
  fromCreatedAt: z.string().optional(),
  toCreatedAt: z.string().optional(),
});

interface SearchProps {
  updateFilter: (data: Record<string, unknown>) => void;
}

export function ShiftAssignmentPreferenceFormSearch({
  updateFilter,
}: SearchProps) {
  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();
  const subplazaQuery = useSubplazaQueryAction("filter", {});
  const subplazas = subplazaQuery.data;

  const { useQueryAction: usePlazaQueryAction } = usePlazaCachedService();
  const plazaQuery = usePlazaQueryAction("filter", {});
  const plazas = plazaQuery.data;

  const { useQueryAction: useShiftQueryAction } = useShiftCachedService();
  const shiftQuery = useShiftQueryAction("filter", {});
  const shifts = shiftQuery.data;

  const formFields = buildFormFields(plazas, subplazas, shifts);

  return (
    <GenericForm
      layout="row"
      fields={formFields}
      formSchema={formSchema}
      actionLabel="Filtrar"
      initialData={{}}
      onSubmit={updateFilter as (data: unknown) => void}
    />
  );
}
