import { Box, Flex, Heading, Stack, Text, VStack } from "@chakra-ui/layout";
import { Button, Input, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { TableUserDebt } from "./components/tableUserDebt";
import { useUserPaymentDebtContext } from "./context/use-user-payment-debt-context";
import { Pagination } from "../../../components/pagination";
import { DebtModal } from "./components/debtModal";
import { LoadingLogo } from "../../../components/loading";
import { convertCentsToReais } from "../../../utils/convert-money";

export function UserDebt() {
  const {
    register,
    handleFetchData,
    handleSetPage,
    currentPage,
    totalPages,
    data,
    openModal,
    modalIfon,
    handleChangeModalStatus,
    isFetching,
    totalValue,
  } = useUserPaymentDebtContext();

  const totalInReais = convertCentsToReais(totalValue);

  return (
    <>
      <DebtModal
        isOpen={openModal}
        onClose={handleChangeModalStatus}
        modalInfo={modalIfon}
      />

      <VStack gap={8} w={"full"} overflow={"auto"} h={"full"}>
        <Flex w="full" justifyContent="space-between" alignItems="center">
          <Heading fontWeight="extrabold" textAlign="left">
            Entregadores Negativos
          </Heading>
        </Flex>
        <Box
          border="1px"
          shadow="base"
          bg="whiteAlpha.800"
          borderRadius={8}
          borderColor={"gray.300"}
          alignSelf={"flex-start"}
        >
          <Stat w="max-content">
            <Flex direction={"column"} borderRadius={8} p={8}>
              <StatLabel>Valor total de débitos:</StatLabel>
              <StatNumber color="red.300" fontSize={"3xl"}>
                {totalInReais}
              </StatNumber>
            </Flex>
          </Stat>
        </Box>
        <Box
          w="full"
          display="flex"
          flexDir="column"
          gap={4}
          flex={1}
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
        >
          <Flex
            w="full"
            justifyContent="space-between"
            alignItems="center"
            gap={8}
          >
            <Stack w={"full"}>
              <Text fontWeight="bold">Nome</Text>
              <Input
                {...register("deliverymanName")}
                placeholder="Busque pelo nome..."
              />
            </Stack>
            <Stack w={"full"}>
              <Text fontWeight="bold">CPF/CNPJ</Text>
              <Input
                {...register("document")}
                placeholder="Busque pelo CPF/CNPJ..."
              />
            </Stack>
          </Flex>
          <Flex w="full" justifyContent="flex-end" alignItems="center" gap={8}>
            <Button
              colorScheme="green"
              isLoading={isFetching}
              onClick={handleFetchData}
            >
              Filtrar
            </Button>
          </Flex>
          {isFetching ? <LoadingLogo /> : <TableUserDebt data={data} />}
          <Pagination
            page={currentPage}
            setPage={(e) => {
              handleSetPage(Number(e));
            }}
            totalPages={totalPages}
          />
        </Box>
      </VStack>
    </>
  );
}
