import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Img,
} from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RewardDetailsModal({ isOpen, onClose, reward }: any) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Detalhes do Prêmio</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {reward && (
            <Box>
              <Text>
                <strong>Nome:</strong> {reward.name}
              </Text>
              <Text>
                <strong>Categoria:</strong> {reward.category}
              </Text>
              <Text>
                <strong>Valor em Pontos:</strong> {reward.value}
              </Text>
              <Text>
                <strong>Descrição:</strong> {reward.description}
              </Text>
              <Text>
                <strong>Data de Ativação:</strong>{" "}
                {new Date(reward.createdAt).toLocaleDateString()}
              </Text>
              <Text>
                <strong>Data de Inativação:</strong>{" "}
                {reward.deactivatedAt
                  ? new Date(reward.deactivatedAt).toLocaleDateString()
                  : "Data indisponível"}
              </Text>
              <Img
                src={reward.imageUrl}
                alt={reward.name}
                width={200}
                height={200}
              />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RewardDetailsModal;
