import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getWithAuth,
  postWithAuth,
} from "../../../../../services/basicService";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { GiftType } from "../../../../../types/gift-type";
import { useToast } from "@chakra-ui/react";

type WhereParams = {
  name?: string;
  category?: string;
  page?: number;
  createdAt?: Date;
};

type HistoryPointsContextType = {
  pointsHistory:
    | {
        data: GiftType[];
        count: number;
        hasNextPage: boolean;
        page: number;
      }
    | undefined;
  isLoading: boolean;
  setCompanyId: (companyId: number) => void;
  companyId: number;
  handleFilter: () => void;
  handleChangePage: (page: number) => void;
  mutate: ({ giftId, status }: { giftId: number; status: boolean }) => void;
  isLoadingMutation: boolean;
  setWhereParams: (newWhere: Partial<WhereParams>) => void;
  categories: string[];
};

export const HistoryPointsContext = createContext<{
  pointsHistory:
    | {
        data: GiftType[];
        count: number;
        hasNextPage: boolean;
        page: number;
      }
    | undefined;
  isLoading: boolean;
  setCompanyId: (companyId: number) => void;
  companyId: number;
  handleFilter: () => void;
  handleChangePage: (page: number) => void;
  mutate: ({ giftId, status }: { giftId: number; status: boolean }) => void;
  isLoadingMutation: boolean;
  setWhereParams: (newWhere: Partial<WhereParams>) => void;
  categories: string[];
}>({} as HistoryPointsContextType);

export const HistoryPointsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [companyId, setCompanyId] = useState<number>(0);
  const toast = useToast();
  const [pointsHistory, setPointsHistory] = useState<
    | {
        data: GiftType[];
        count: number;
        hasNextPage: boolean;
        page: number;
      }
    | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [where, setWhere] = useState<{
    name?: string;
    category?: string;
    page?: number;
    cretedAt?: Date;
  }>({
    page: 1,
  });

  const { isLoading: isLoadingQuery, refetch } = useQuery(
    ["pointsHistory"],
    async () => {
      setIsLoading(true);
      let query = "";
      Object.entries(where).forEach(([key, value]) => {
        if (value) {
          query += `&${key}=${value}`;
        }
      });
      const response = await getWithAuth(
        `/api/v1/trampoints/find-gifts?` + query
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setPointsHistory(response?.data as any);
      setIsLoading(false);
      return response;
    }
  );

  const [categories, setCategories] = useState<string[]>([]);

  useQuery(
    ["categories"],
    async () => {
      const response = await getWithAuth(
        "/api/v1/trampoints/get-grouped-category"
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCategories(response?.data?.map((category: any) => category.name));
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isLoadingMutation } = useMutation(
    ["updateStatus"],
    async ({ giftId, status }: { giftId: number; status: boolean }) => {
      try {
        await postWithAuth(`/api/v1/trampoints/update-gift-status`, {
          id: giftId,
          status,
        });

        toast({
          title: "Sucesso",
          description: "Status atualizado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Erro",
          description: "Ocorreu um erro ao atualizar o status",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        refetch();
      }
    }
  );

  useEffect(() => {
    if (isLoadingQuery) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingQuery]);

  const handleFilter = () => {
    refetch();
  };

  const handleChangePage = async (page: number) => {
    await setWhere({ ...where, page });
    refetch();
  };

  const setWhereParams = (newWhere: Partial<typeof where>) => {
    setWhere((prevWhere) => ({ ...prevWhere, ...newWhere }));
  };

  return (
    <HistoryPointsContext.Provider
      value={{
        pointsHistory,
        isLoading,
        setCompanyId,
        companyId,
        handleFilter,
        handleChangePage,
        mutate,
        isLoadingMutation,
        setWhereParams,
        categories,
      }}
    >
      {children}
    </HistoryPointsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useHistoryPointsContext = () => useContext(HistoryPointsContext);
