/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useRef, useState, useEffect } from "react";
import {
  VStack,
  Heading,
  Flex,
  Box,
  Badge,
  Stack,
  UnorderedList,
  ListItem,
  Text,
} from "@chakra-ui/layout";
import { DragDrop, IRef } from "../../../../components/forms/fileUpload";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Table,
  Td,
  NumberInput,
  NumberInputField,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  UseStepsReturn,
  FormControl,
  FormLabel,
  Select,
  Tooltip,
  FormErrorMessage,
  FormErrorIcon,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { convertCentsToReais } from "../../../../utils/convert-money";
import {
  FloppyDiskBack,
  Info,
  Plus,
  Trash,
  Question,
  WarningOctagon,
} from "@phosphor-icons/react";
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { useCreatePayroll } from "../../../../store/payroll.store";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { AxiosError } from "axios";
import { useDebounce } from "../../../../hooks/enterprises/use-debounce";
import { TestCPF } from "../../../../utils/cpf-validator";
import { TestCNPJ } from "../../../../utils/cnpj-validator";
import { PermissionsEnum } from "../../../../enums/permissions.enum";
import { UploadPayrollResponseErrors } from "../../../../types/upload.type";
import { useGetPayroll } from "../../../../hooks/payments/use-get-payroll";
import { PayrollType } from "../../../../enums/payroll-type";
import { PixKeyType } from "../../../../enums/pix-type";
import { UpdatePixKeyButton } from "./components/update-pix-key-button";
import { useParams } from "react-router-dom";

export type PaymentInputs = {
  payment: {
    document?: string;
    name?: string;
    description?: string;
    pix_type?: PixKeyType | "";
    pix_key?: string;
    amount?: number;
    status?: string;
    pixErrorMessage?: string;
    discount?: number;
  }[];
  isToValidatePixDocument: string;
};

interface UploadResult {
  document: string;
  name: string;
  description: string;
  pix_key: string;
  amountInCents: number;
}

type UploadResponse = {
  result: UploadResult[];
  hasError: boolean;
  errors: UploadPayrollResponseErrors;
};

type Props = UseStepsReturn;

export const ValidationStatus = {
  validated: "Validado",
  "missing information": "Faltando informações",
  "wrong amount": "Valor inválido",
  "missing pix info": "Faltando informação de pix",
  "invalid pix": "Pix inválido",
};

export function PaymentSendSheet(props: Props) {
  const [uploading, setUploading] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [uploadErrors, setUploadErrors] =
    useState<UploadPayrollResponseErrors | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const debouncedSearch = useDebounce<string | undefined>(searchFilter, 200);
  const userPermissions = useCurrentUserStore((state) => state.permissions);
  const saveDraft = useCreatePayroll((state) => state.savePayrollDraft);
  const saveConciliatedDraft = useCreatePayroll(
    (state) => state.saveConciliatedDraft
  );
  const validatePayroll = useCreatePayroll((state) => state.validatePayroll);
  const validationResponse = useCreatePayroll(
    (state) => state.transactionValidated
  );

  const changePayrollStatus = useCreatePayroll(
    (state) => state.changePayrollStatus
  );
  const { id: payrollId } = useParams();

  const paymentsRef = useRef<IRef>(null);
  const uuid = useRef<string>("");

  const uploadFiles = useDisclosure({ id: "upload-files" });
  const readErrors = useDisclosure({ id: "read-errors" });

  const toast = useToast();

  const { isLoading, payrollCreated, reprocessedPayroll } = useGetPayroll();

  const isConciliated = payrollCreated?.type === PayrollType.CONCILIATED;

  const {
    register,
    control,
    formState: { isSubmitting, errors, isValid },
    handleSubmit,
    watch,
    trigger,
    setValue,
  } = useForm<PaymentInputs>({
    defaultValues: {
      payment:
        validationResponse?.result
          .map((item) => ({
            document: item.document,
            name: item.name,
            description: item.description,
            pix_key: item.pixKey,
            pix_type: item.pixType,
            amount: (item.initialPaymentBalance || item.amountInCents) / 100,
            status: item.status,
            pixErrorMessage: item.pixErrorMessage,
            discount: item.discount ? item.discount / 100 : undefined,
          }))
          .sort((a, b) => {
            if (a.status !== "validated" && b.status !== "validated") {
              return 0;
            }

            if (a.status !== "validated" && b.status === "validated") {
              return -1;
            }

            return 1;
          }) || undefined,
      isToValidatePixDocument:
        reprocessedPayroll?.isToValidatePixDocument === undefined ||
        reprocessedPayroll?.isToValidatePixDocument === null
          ? "true"
          : JSON.stringify(reprocessedPayroll?.isToValidatePixDocument),
    },
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "payment",
  });

  const payments = watch("payment");
  const isToValidatePixDocument = watch("isToValidatePixDocument");

  const amount = payments?.reduce(
    (acc, field) => (acc += field.amount || 0),
    0
  );

  const filteredFields = debouncedSearch
    ? payments.filter(
        (field) =>
          field.name?.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          field.document?.includes(debouncedSearch)
      )
    : [];

  const filteredErrors =
    errors.payment !== undefined
      ? (errors.payment as Array<
          Merge<
            FieldError,
            FieldErrorsImpl<{
              document: string;
              name: string;
              description: string;
              pix_type: NonNullable<
                "" | "email" | "telefone" | "aleatorio" | "cpf" | undefined
              >;
              pix_key: string;
              amount: number;
              status: string;
            }>
          >
        >)
      : [];

  async function handleUpload() {
    setUploading(true);
    uuid.current = crypto.randomUUID();

    try {
      const response = await paymentsRef.current?.processUpload<UploadResponse>(
        {
          sendId: uuid.current,
        }
      );

      if (!response) return;

      const result = response.result;

      remove();

      setUploadErrors(response.errors);
      setHasError(response.hasError);

      result.forEach((item) => append(item));

      setUploadErrors(response.errors);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 504 || error.response?.status === 524)
      ) {
        console.log("Erro de timeout do lado do servidor.");

        toast({
          status: "error",
          title: "Erro ao enviar folha para reprocessamento",
          description:
            "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
        });
      }
      if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
        toast({
          status: "error",
          title: "Conexão com internet instável",
          description:
            "Verifique sua conexão com internet ou tente novamente mais tarde.",
          duration: 5000,
        });
      } else {
        const err = error as AxiosError;
        const errorData = err.response?.data as UploadPayrollResponseErrors;
        setUploadErrors(errorData);
        toast({
          status: "error",
          title: "Não foi possível enviar as planilhas",
          description: err.message,
        });
      }
      return;
    } finally {
      uploadFiles.onClose();
      setUploading(false);
    }
  }

  async function handleSave({
    payment,
    isToValidatePixDocument,
  }: Partial<PaymentInputs>) {
    if (!payrollCreated?.id) return;

    if (!payment) return;

    trigger();

    if (!isValid) return;

    const payload = {
      payrollId: payrollCreated.id,
      isToValidatePixDocument: JSON.parse(isToValidatePixDocument as string),
      transactionDrafts: payment.map((payment) => ({
        document: payment.document!,
        name: payment.name!,
        description: payment.description!,
        pixType: payment.pix_type ? payment.pix_type : undefined,
        pixKey: payment.pix_key ? payment.pix_key : undefined,
        amountInCents: payment.amount ? Math.round(payment.amount * 100) : 0,
        discountInCents: payment.discount
          ? Math.round(payment.discount * 100)
          : 0,
      })),
    };

    try {
      if (!isConciliated) {
        await saveDraft(payload);
      }

      if (isConciliated) {
        await saveConciliatedDraft({
          isToValidatePixDocument: JSON.parse(
            isToValidatePixDocument as string
          ),
          payrollId: payrollCreated.id,
          transactionDrafts: payment.map((payment) => ({
            document: payment.document!,
            name: payment.name!,
            description: payment.description!,
            pixType: payment.pix_type as PixKeyType,
            pixKey: payment.pix_key!,
            amountInCents: payment.amount
              ? Math.round(payment.amount * 100)
              : 0,
            discount: payment.discount ? Math.round(payment.discount * 100) : 0,
            initialPaymentBalance: payment.amount
              ? Math.round(payment.amount * 100)
              : 0,
          })),
        });
      }

      toast({
        status: "success",
        title: "O rascunho salvo com sucesso",
        description:
          'Você pode consultar e retomar seus rascunhos pelo sub-menu lateral em "Folhas em rascunho"',
      });
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (
          error instanceof AxiosError &&
          (error.response?.status === 504 || error.response?.status === 524)
        ) {
          console.log("Erro de timeout do lado do servidor.");

          toast({
            status: "error",
            title: "Erro ao enviar folha para reprocessamento",
            description:
              "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
          });
        }
        if (error.response?.status === 400) {
          return toast({
            status: "error",
            title: "Houve erro ao salvar",
            description:
              "Verifique se o tipo da chave pix foi preenchido corretamente em todas as transações.",
          });
        }
        return toast({
          status: "error",
          title: "Houve erro ao salvar",
          description: "Erro: " + error.response?.data.message,
        });
      }

      toast({
        status: "error",
        title: "Houve erro ao salvar",
        description: "Erro: " + JSON.stringify(error),
      });
    }
  }

  async function handleValidation(data: PaymentInputs) {
    changePayrollStatus(false);

    if (!payrollCreated?.id) return;

    const payload = {
      payrollId: payrollCreated.id,
      isToValidatePixDocument: JSON.parse(data.isToValidatePixDocument),
      transactionDrafts: data.payment.map((payment) => ({
        document: payment.document!,
        name: payment.name!,
        description: payment.description!,
        pixType: payment.pix_type!,
        pixKey: payment.pix_key!,
        amountInCents: payment.amount ? Math.round(payment.amount * 100) : 0,
      })),
    };

    if (isConciliated) {
      try {
        const response = await saveConciliatedDraft({
          payrollId: payrollCreated.id,
          isToValidatePixDocument: isToValidatePixDocument === "true",
          transactionDrafts: data.payment.map((payment) => ({
            document: payment.document!,
            name: payment.name!,
            description: payment.description!,
            pixType: payment.pix_type! as PixKeyType,
            pixKey: payment.pix_key!,
            initialPaymentBalance: payment.amount
              ? Math.round(payment.amount * 100)
              : 0,
            discount: payment.discount ? Math.round(payment.discount * 100) : 0,
          })),
        });

        if (!response) return;

        if (!response.hasError) {
          props.setActiveStep(2);
          return;
        }
      } catch (error) {
        console.log(JSON.stringify(error));
        if (
          error instanceof AxiosError &&
          (error.response?.status === 504 || error.response?.status === 524)
        ) {
          console.log("Erro de timeout do lado do servidor.");

          toast({
            status: "error",
            title: "Erro ao enviar folha para reprocessamento",
            description:
              "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
          });
        }
        if (error instanceof AxiosError) {
          return toast({
            status: "error",
            title: "Houve erro na validação",
            description: "Erro: " + error.response?.data.message,
          });
        }

        toast({
          status: "error",
          title: "Houve erro na validação",
          description: "Erro: " + JSON.stringify(error),
        });
      }
    }

    if (!isConciliated) {
      try {
        const response = !reprocessedPayroll?.schedulingDate
          ? await validatePayroll(payload)
          : await saveDraft(payload);
        if (!response) return;

        if (!response.hasError) {
          props.setActiveStep(2);
          return;
        }

        props.setActiveStep(1);

        toast({
          status: "error",
          title: "Há transações com erros",
          description:
            "Há transações com erros, por favor faça as correções necessárias",
        });

        const formattedDraft = response.result
          .map((item) => ({
            document: item.document,
            name: item.name,
            description: item.description,
            pix_type: item.pixType,
            pix_key: item.pixKey,
            amount: item.amountInCents / 100,
            status: item.status,
          }))
          .sort((a, b) => {
            if (a.status !== "validated" && b.status !== "validated") {
              return 0;
            }

            if (a.status !== "validated" && b.status === "validated") {
              return -1;
            }

            return 1;
          });
        remove();

        append(formattedDraft);
      } catch (error) {
        console.log(JSON.stringify(error));
        if (
          error instanceof AxiosError &&
          (error.response?.status === 504 || error.response?.status === 524)
        ) {
          console.log("Erro de timeout do lado do servidor.");

          toast({
            status: "error",
            title: "Erro ao enviar folha para reprocessamento",
            description:
              "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
          });
        }
        if (error instanceof AxiosError) {
          return toast({
            status: "error",
            title: "Houve erro na validação",
            description: "Erro: " + error.response?.data.message,
          });
        }

        toast({
          status: "error",
          title: "Houve erro na validação",
          description: "Erro: " + JSON.stringify(error),
        });
      }
    }
  }

  function handleSearch(index: number) {
    const item = payments[index];
    if (filteredFields.length === 0 && !searchFilter) return false;

    if (filteredFields.length === 0 && searchFilter) return true;

    if (
      !filteredFields.some(
        (field) =>
          item.name?.toLowerCase() === field.name?.toLowerCase() ||
          item.document === field.document
      )
    ) {
      return true;
    }
    return false;
  }

  function removeAllErrors() {
    const transactionsWithErrors = fields.reduce((acc, field, index) => {
      if (field.status !== "validated" && field.status !== undefined) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);

    remove(transactionsWithErrors);
  }

  useEffect(() => {
    if (!payrollCreated) props.goToPrevious();
  }, [payrollCreated, props]);

  function ErrorsContainer({
    errors,
  }: {
    errors: UploadPayrollResponseErrors | null;
  }) {
    if (!errors) return <></>;

    return (
      <div>
        {errors.missingColumns?.columns?.length ? (
          <Flex w="max-content" p={4} borderRadius={8} gap={2}>
            <Flex gap={2} borderRadius={4} p={4} flexDir="column">
              <Heading size="sm">Colunas não encontradas</Heading>
              <UnorderedList>
                <ListItem>
                  <Flex shadow="xs" gap={4} bg="red.100" borderRadius={4} p={4}>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Erro: ${errors.missingColumns.message}`}</Flex>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </Flex>
          </Flex>
        ) : null}
        {errors.contentErrors?.length ? (
          <Flex w="max-content" p={4} borderRadius={8} gap={2}>
            <Flex gap={2} borderRadius={4} p={4} flexDir="column">
              <Heading size="sm">Valores inválidos</Heading>
              <UnorderedList>
                {errors.contentErrors.map((error) => (
                  <ListItem key={String(error)} p={2}>
                    <Flex
                      shadow="xs"
                      gap={4}
                      bg="red.100"
                      borderRadius={4}
                      p={4}
                    >
                      <Text>{error}</Text>
                    </Flex>
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </Flex>
        ) : null}
        {errors.notExpectedErrors?.length ? (
          <Flex w="max-content" p={4} borderRadius={8} gap={2}>
            <Flex gap={2} borderRadius={4} p={4} flexDir="column">
              <Heading size="sm">Erros não esperados na planilha</Heading>
              {errors.notExpectedErrors.map((error) => (
                <UnorderedList key={error.rowIndex}>
                  <ListItem>
                    <Flex shadow="xs" gap={4} borderRadius={4} p={4}>
                      <Flex gap={2} flexDir="column">{`Linha: ${
                        error.rowIndex + 1
                      }`}</Flex>
                      <Flex
                        gap={2}
                        flexDir="column"
                      >{`Data: ${error.value.data.substring(0, 10)}`}</Flex>
                    </Flex>
                  </ListItem>
                </UnorderedList>
              ))}
            </Flex>
          </Flex>
        ) : null}
      </div>
    );
  }

  return (
    <VStack
      w="full"
      alignItems="flex-start"
      gap={4}
      id="container"
      style={{ containerType: "inline-size" }}
    >
      <Flex justifyContent="space-between" w="full" flexDir="column" gap={8}>
        <Modal
          isOpen={uploadFiles.isOpen}
          onClose={uploadFiles.onClose}
          size="5xl"
          id="upload-files"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Upload - Arquivo de Pagamentos</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <Button size="lg" color="white" ml={2} mb={8}>
                <a
                  href="/assets/transaction-sheet-template/exemplo-transacoes-folha-de-pagamento.csv"
                  download
                >
                  Baixar Template
                </a>
              </Button>
              <Flex gap={16} flexDir="column">
                <Flex flexDir="column" gap={4}>
                  <DragDrop
                    uploadUrl="/api/v1/uploaded-file/split-payment"
                    maxFiles={1}
                    ref={paymentsRef}
                  />
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={uploadFiles.onClose}>
                Fechar
              </Button>
              <Button
                color="white"
                _hover={{
                  bg: "green.600",
                }}
                onClick={handleUpload}
                isDisabled={uploading}
                isLoading={uploading}
                loadingText="Enviando arquivo"
              >
                Enviar .CSV
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      {/* <Flex justifyContent="space-between" w="full" flexDir="column" gap={8}> */}
      {/* <Modal
          isOpen={pixKeyCaption.isOpen}
          onClose={pixKeyCaption.onClose}
          size="5xl"
          id="pix-key-caption"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Como inserir a chave pix do tipo Telefone</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <Flex as="span" fontSize="2xl">
                  Deve inserir o&nbsp;
                  <Text as="span" color="#1BB934">
                    número do celular
                  </Text>
                  &nbsp;com o&nbsp;
                  <Text as="span" color="#0074D9">
                    DDD
                  </Text>
                  &nbsp;sem o zero a esquerda
                </Flex>
                <Flex as="p" fontSize="2xl">
                  Exemplo:&nbsp;
                  <Text>
                    <Text as="span" color="#0074D9">
                      18
                    </Text>
                    <Text as="span" color="#1BB934">
                      988888888
                    </Text>
                  </Text>
                </Flex>
                <Flex fontSize="2xl" flexDirection="column" gap="10px">
                  <Text as="p">
                    A validação irá retornar o{' '}
                    <Text as="span" color="#FF3333">
                      código do país
                    </Text>{' '}
                    junto com o telefone
                  </Text>
                  <Text as="p">
                    Exemplo:{' '}
                    <Text as="span" color="#FF3333">
                      +55
                    </Text>
                    <Text as="span" color="#0074D9">
                      18
                    </Text>
                    <Text as="span" color="#1BB934">
                      988888888
                    </Text>
                  </Text>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={pixKeyCaption.onClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex> */}

      <Flex gap={4} w="full" flexDir={["column", "row"]} flexFlow="row wrap">
        <Flex gap={[4, 8]} flex={1}>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>Valor total: </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {amount
                  ? convertCentsToReais(amount * 100)
                  : convertCentsToReais(0)}
              </StatNumber>
            </Stat>
          </Box>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>
                Quantidade de saldos:
              </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {fields.length}
              </StatNumber>
            </Stat>
          </Box>
          {filteredErrors?.length > 0 ? (
            <Box
              bg="whiteAlpha.800"
              border="2px"
              borderColor="red.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize={["md", "xl"]}>
                  Quantidade de erros:
                </StatLabel>
                <StatNumber fontSize={["xl", "3xl"]} color="red.500">
                  {filteredErrors.filter((e) => e !== undefined).length}
                </StatNumber>
              </Stat>
            </Box>
          ) : null}
          {hasError && (
            <Flex>
              <Button
                size="lg"
                variant="ghost"
                bg="red.200"
                _hover={{
                  bgColor: "red.100",
                }}
                onClick={readErrors.onOpen}
                display="flex"
                gap={4}
              >
                <WarningOctagon color="red" size={24} />
                Erros na planilha enviada
              </Button>
            </Flex>
          )}
          <Modal
            isOpen={readErrors.isOpen}
            onClose={readErrors.onClose}
            size="5xl"
            id="read-errors"
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent px={4}>
              <ModalHeader>Erros na planilha</ModalHeader>
              <ModalCloseButton />
              <ModalBody px={16}>
                <ErrorsContainer errors={uploadErrors || null} />
              </ModalBody>

              <ModalFooter my={4}>
                <Button variant="ghost" mr={3} onClick={readErrors.onClose}>
                  Fechar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
        <Flex
          gap={4}
          flex={1}
          flexDir={["column", "row"]}
          justifyContent="flex-end"
          alignItems={["center", "flex-end"]}
        >
          <UpdatePixKeyButton
            payrollId={Number(payrollId)}
            setValue={setValue}
          />
          {fields.length > 0 ? (
            <Tooltip label="Salvar como rascunho" aria-label="A tooltip">
              <Button
                size="lg"
                w={["full", "auto"]}
                _hover={{
                  bg: "blue.600",
                  color: "white",
                }}
                onClick={async () => {
                  await handleSave({
                    payment: payments,
                    isToValidatePixDocument,
                  });
                }}
                gap={2}
                bg="blue.400"
                color="white"
              >
                <FloppyDiskBack size={24} />
                Salvar
              </Button>
            </Tooltip>
          ) : null}

          <Button
            size="lg"
            w={["full", "auto"]}
            onClick={() => {
              uploadFiles.onOpen();
            }}
          >
            Enviar .CSV
          </Button>
          {userPermissions?.some(
            (permission) =>
              permission.key === PermissionsEnum["Aprovar Split de Pagamento"]
          ) ? (
            <Button
              size="lg"
              w={["full", "auto"]}
              color="white"
              _hover={{
                bg: "green.600",
              }}
              type="submit"
              form="create-payroll-draft-form"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText="Finalizando..."
            >
              Finalizar transações
            </Button>
          ) : null}
        </Flex>
      </Flex>

      <Flex
        w="full"
        justifyContent="space-between"
        alignItems="center"
        paddingX="50"
        as="form"
      >
        <FormControl w="50%">
          <Heading size="md">Filtros</Heading>
          <FormLabel>Pesquisa</FormLabel>

          <Input
            placeholder="Procure por nome ou documento..."
            bg="gray.100"
            value={searchFilter}
            w="full"
            maxW="3xl"
            onChange={(e) => setSearchFilter(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl w="fit-content">
          <FormLabel display="flex" gap="12px">
            Validar documento Pix
            <Tooltip
              hasArrow
              textAlign="center"
              fontSize="md"
              label="Validar se o CPF/CNPJ passado é igual ao documento do dono da chave PIX"
              bg="gray.300"
              color="black"
            >
              <Question size={22} />
            </Tooltip>
          </FormLabel>
          <Controller
            name="isToValidatePixDocument"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack direction="row">
                  <Radio value="true" colorScheme="green">
                    Sim
                  </Radio>
                  <Radio value="false" colorScheme="red">
                    Não
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
      </Flex>

      <Box
        overflowX="auto"
        display="flex"
        flexDir="column"
        w="full"
        gap={4}
        as="form"
        id="create-payroll-draft-form"
        onSubmit={handleSubmit(handleValidation)}
      >
        {fields.filter(
          (item) => item.status !== "validated" && item.status !== undefined
        ).length ? (
          <Flex justifyContent="flex-end" w="full">
            <Button
              size="lg"
              w={["full", "auto"]}
              color="white"
              fontWeight="bold"
              onClick={removeAllErrors}
            >
              <Trash size={24} weight="bold" />
              Remover erros
            </Button>
          </Flex>
        ) : null}

        <Table variant="simple" colorScheme="green" w="full">
          <Thead>
            <Tr>
              <Th textAlign="center" minW="max-content">
                CPF/CNPJ
              </Th>
              <Th textAlign="center">Nome</Th>
              <Th textAlign="center">Descrição</Th>
              <Th textAlign="center" minW="max-content">
                Tipo da Chave Pix
              </Th>
              <Th textAlign="center">
                <Flex gap={2} justifyContent="center">
                  Chave Pix
                  <Tooltip
                    label="Deve inserir o número do celular com o DDD sem o zero a esquerda"
                    aria-label="Deve inserir o número do celular com o DDD sem o zero a esquerda tooltip"
                    placement="top"
                  >
                    <Info size={16} />
                  </Tooltip>
                </Flex>
              </Th>
              <Th textAlign="center">Valor (R$)</Th>
              {isConciliated && (
                <Th textAlign="center">Valor em Débito (R$)</Th>
              )}
              <Th></Th>
              <Th textAlign="center">Excluir Registro</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              fields.map((item, index) => (
                <Tr key={item.id} hidden={handleSearch(index)}>
                  <Td>
                    <FormControl
                      isInvalid={
                        errors?.payment
                          ? !!errors?.payment[index]?.document
                          : false
                      }
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      {item.pixErrorMessage ? (
                        <Tooltip
                          label={item.pixErrorMessage}
                          aria-label="erro que aconteceu na transação"
                        >
                          <Info size={20} color="red" />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <Input
                        border="1px"
                        borderColor="gray.300"
                        bg="gray.100"
                        w="150px"
                        {...register(`payment.${index}.document` as const, {
                          required: true,
                          validate: (value) =>
                            TestCPF(value) || TestCNPJ(value)
                              ? true
                              : "Insira um CPF ou CNPJ válido",
                        })}
                      />
                      {errors?.payment && errors?.payment[index]?.document ? (
                        <FormErrorMessage>
                          <Tooltip
                            label={errors?.payment[index]?.document?.message}
                            aria-label="error document tooltip"
                          >
                            <FormErrorIcon boxSize={6} />
                          </Tooltip>
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  </Td>
                  <Td>
                    <Input
                      border="1px"
                      borderColor="gray.300"
                      bg="gray.100"
                      w="150px"
                      {...register(`payment.${index}.name` as const, {
                        required: true,
                      })}
                    />
                  </Td>
                  <Td>
                    <Input
                      border="1px"
                      borderColor="gray.300"
                      bg="gray.100"
                      w="150px"
                      {...register(`payment.${index}.description` as const)}
                    />
                  </Td>
                  <Td>
                    <Select
                      {...register(`payment.${index}.pix_type` as const, {
                        deps: [`payment.${index}.pix_key`],
                      })}
                      w="100px"
                    >
                      <option value="">Nenhum</option>
                      <option value="cpf">CPF</option>
                      <option value="cnpj">CNPJ</option>
                      <option value="email">E-mail</option>
                      <option value="telefone">Telefone</option>
                      <option value="aleatorio">Aleatório</option>
                    </Select>
                  </Td>
                  <Td>
                    <FormControl
                      isInvalid={
                        errors?.payment && errors?.payment[index]?.pix_key
                          ? !!errors?.payment[index]?.pix_key
                          : false
                      }
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      <Input
                        border="1px"
                        borderColor="gray.300"
                        bg="gray.100"
                        w="150px"
                        {...register(`payment.${index}.pix_key` as const, {
                          required: false,
                          setValueAs(value) {
                            if (payments[index].pix_type === "cpf") {
                              value = value.replace(/\D/g, "");
                              return value;
                            }
                            if (payments[index].pix_type === "telefone") {
                              if (value[0] === "+") return value;

                              return `+55${value}`.trim();
                            }
                            return value?.trim();
                          },
                          validate: (value, formValues) => {
                            if (value === undefined) return false;

                            const pixTypeObject = {
                              "": !!reprocessedPayroll?.schedulingDate,
                              cpf:
                                TestCPF(value) || TestCNPJ(value)
                                  ? true
                                  : "Insira um CPF/CNPJ válido apenas com números",
                              email:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                  value
                                ) || "Insira um e-mail válido",
                              telefone:
                                value?.length === 14 ||
                                "Insira um telefone válido",
                              aleatorio:
                                /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(
                                  value
                                ) || "Chave com formato inválido",
                            };

                            return pixTypeObject[
                              formValues?.payment[index]
                                .pix_type as keyof typeof pixTypeObject
                            ];
                          },
                        })}
                        defaultValue=""
                      />
                      {errors?.payment && errors?.payment[index]?.pix_key ? (
                        <FormErrorMessage>
                          <Tooltip
                            label={errors?.payment[index]?.pix_key?.message}
                            aria-label="error pix key tooltip"
                          >
                            <FormErrorIcon boxSize={6} />
                          </Tooltip>
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl
                      isInvalid={
                        errors?.payment
                          ? !!errors?.payment[index]?.amount
                          : false
                      }
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      <NumberInput
                        min={0}
                        display="flex"
                        gap={4}
                        flex={1}
                        minW="100px"
                        alignItems="center"
                        allowMouseWheel={false}
                        inputMode="decimal"
                        precision={2}
                        step={0.1}
                        isValidCharacter={(value) =>
                          /^[Ee0-9+\-.,]$/.test(value)
                        }
                      >
                        R$
                        <NumberInputField
                          textAlign="right"
                          border="1px"
                          borderColor="gray.300"
                          bg="gray.100"
                          type="number"
                          w="100px"
                          {...register(`payment.${index}.amount` as const, {
                            valueAsNumber: true,
                            required: true,
                            min: {
                              value: 0.01,
                              message: "Insira um valor mínimo de R$ 0,01",
                            },
                          })}
                        />
                        {errors?.payment && errors?.payment[index]?.amount ? (
                          <FormErrorMessage>
                            <Tooltip
                              label={errors?.payment[index]?.amount?.message}
                              aria-label="error amount tooltip"
                            >
                              <FormErrorIcon boxSize={6} />
                            </Tooltip>
                          </FormErrorMessage>
                        ) : null}
                      </NumberInput>
                    </FormControl>
                  </Td>
                  {isConciliated && (
                    <Td>
                      <FormControl
                        isInvalid={
                          errors?.payment
                            ? !!errors?.payment[index]?.amount
                            : false
                        }
                        display="flex"
                        gap={2}
                        alignItems="center"
                      >
                        <NumberInput
                          min={0}
                          display="flex"
                          gap={4}
                          flex={1}
                          minW="100px"
                          alignItems="center"
                          allowMouseWheel={false}
                          inputMode="decimal"
                          precision={2}
                          step={0.1}
                          isValidCharacter={(value) =>
                            /^[Ee0-9+\-.,]$/.test(value)
                          }
                          isDisabled={!!reprocessedPayroll?.schedulingDate}
                        >
                          R$
                          <NumberInputField
                            textAlign="right"
                            border="1px"
                            borderColor="gray.300"
                            bg="gray.100"
                            type="number"
                            w="100px"
                            {...register(`payment.${index}.discount` as const, {
                              valueAsNumber: true,
                              required: false,
                              min: {
                                value: 0.01,
                                message: "Insira um valor mínimo de R$ 0,01",
                              },
                            })}
                          />
                          {errors?.payment &&
                          errors?.payment[index]?.discount ? (
                            <FormErrorMessage>
                              <Tooltip
                                label={
                                  errors?.payment[index]?.discount?.message
                                }
                                aria-label="error amount tooltip"
                              >
                                <FormErrorIcon boxSize={6} />
                              </Tooltip>
                            </FormErrorMessage>
                          ) : null}
                        </NumberInput>
                      </FormControl>
                    </Td>
                  )}
                  <Td>
                    {item.status ? (
                      <Badge
                        variant="solid"
                        colorScheme={
                          item.status !== "validated" ? "red" : "green"
                        }
                      >
                        {
                          ValidationStatus[
                            item.status as keyof typeof ValidationStatus
                          ]
                        }
                      </Badge>
                    ) : null}
                  </Td>
                  <Td textAlign="center">
                    <Button variant="danger" onClick={() => remove(index)}>
                      <Trash size={24} color="white" weight="bold" />
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Button
          size="lg"
          minH="40px"
          onClick={() => {
            append({
              document: undefined,
              name: undefined,
              amount: 0,
              description: undefined,
              pix_key: undefined,
            });
          }}
          display="flex"
          gap={4}
          maxW="xs"
          alignSelf="center"
          marginBottom={3}
        >
          <Plus size={16} />
          Adicionar pagamento
        </Button>
      </Box>
    </VStack>
  );
}
