import { HStack, VStack } from "@chakra-ui/layout";
import { TabList, Tab, Tabs } from "@chakra-ui/react";
import { GiftsHistory } from "./page-components/gifts-history.component";
import { useState } from "react";
import { RedemptionsHistory } from "./page-components/gifts-redemption.component";

export function HistoryPointsPage() {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <VStack gap={8} flex={1} alignItems={"flex-start"} w="full">
      <HStack gap={8}>
        <Tabs
          w="full"
          h="full"
          flex={1}
          colorScheme="green"
          index={tabIndex}
          onChange={setTabIndex}
        >
          <TabList>
            <Tab>Regaste</Tab>
            <Tab>Prêmios</Tab>
          </TabList>
        </Tabs>
      </HStack>
      {tabIndex === 0 && <RedemptionsHistory />}
      {tabIndex === 1 && <GiftsHistory />}
    </VStack>
  );
}
