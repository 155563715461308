import { ReactNode, useRef } from "react";
import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PortalManager,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { X } from "@phosphor-icons/react";
import { CloudManagementDebtsActionsTwoAuthConfirm } from "./two-auth-confirm";
import { postWithAuth } from "../../../../../../services/basicService";

interface IProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly children: ReactNode;
  readonly action: "cancel" | "pay off" | "reverse" | null;
  readonly debtId: number | null;
}

export function CloudManagementDebtsActionsModal({
  isOpen,
  onClose,
  children,
  action,
  debtId,
}: IProps) {
  const {
    isOpen: isModalActionOpen,
    onOpen: onModalActionOpen,
    onClose: onModalActionClose,
  } = useDisclosure({
    id: "cloud-management-debts-modal-two-auth-confirm",
  });
  const uuid2FA = useRef<string>("");

  const title: Record<string, string> = {
    cancel: "Cancelar débito",
    "pay off": "Quitar débito",
    reverse: "Estornar débito",
  };

  const sendCode = async () => {
    const sendAction: Record<string, string> = {
      cancel: "cancelar",
      "pay off": "quitar",
      reverse: "estornar",
    };

    if (action && action in sendAction) {
      const res = await postWithAuth(
        "/api/v1/two-factor-auth-code/generate/cloud-management",
        { debtId, action: sendAction[action as keyof typeof sendAction] }
      );

      uuid2FA.current = res?.data.uuid;
      onModalActionOpen();
      onClose();
    }
  };

  return (
    <PortalManager zIndex={2000}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="420px"
          h="fit-content"
          borderRadius="2xl"
          overflow="hidden"
        >
          <ModalHeader
            bg="#fffcf2"
            h="100px"
            w="full"
            py={8}
            boxShadow="0px 1px 4px rgba(144, 144, 144, 0.25)"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="3xl" fontFamily="Poppins" fontWeight="semibold">
              {action && title[action]}
            </Text>
            <Button onClick={onClose} bg="inherit" _hover={{ bg: "inherit" }}>
              <X size={28} color="#6f6f6f" />
            </Button>
          </ModalHeader>

          <ModalBody
            py={4}
            px={8}
            fontFamily="Source Sans 3, sans-serif"
            display="flex"
            flexDir="column"
            justifyContent="start"
          >
            {children}
            <Divider my="4" />
            <Flex w="full" justifyContent="end" alignItems="center" gap={8}>
              <Button
                py={6}
                px={8}
                bg="inherit"
                color="black"
                border="1px solid black"
                borderRadius="full"
                _hover={{ bg: "inherit" }}
                fontFamily="Poppins"
                fontSize="md"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                py={6}
                px={8}
                bg="black"
                color="white"
                border="1px solid black"
                borderRadius="full"
                _hover={{ bg: "black" }}
                fontFamily="Poppins"
                fontSize="md"
                onClick={sendCode}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CloudManagementDebtsActionsTwoAuthConfirm
        isOpen={isModalActionOpen}
        onClose={onModalActionClose}
        action={action}
        twoAuthUUID={uuid2FA.current}
        debtId={debtId}
      />
    </PortalManager>
  );
}
