import { Flex, Text, Icon, As } from "@chakra-ui/react";

function BoxData({
  value,
  label,
  icon,
}: {
  value: string;
  label: string;
  icon?: As;
}) {
  return (
    <Flex
      bg="#ECFDF5"
      p={4}
      borderRadius="8px"
      align="center"
      justify="space-between"
      maxW="400px"
    >
      <Text fontWeight="600">{label}:</Text>

      <Flex
        align="center"
        bg="white"
        border="1px solid #000"
        borderRadius="8px"
        px={3}
        py={2}
      >
        <Icon as={icon} boxSize={4} mr={2} />
        <Text fontWeight="bold">{value}</Text>
      </Flex>
    </Flex>
  );
}

export default BoxData;
