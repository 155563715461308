import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";

interface DebtWithoutPaymentProps {
  isOpen: boolean;
  onClose: () => void;
  total?: number;
}

const DebtWithoutPayment: React.FC<DebtWithoutPaymentProps> = ({
  isOpen,
  onClose,
  total,
}) => {
  const pathToNavigate = "portfolio-management/debt-without-payment";
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(pathToNavigate);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="30.812rem" borderRadius="2xl">
        <ModalHeader
          fontSize="1.375rem"
          bg="#fffcf2"
          h="100px"
          pb={8}
          borderTopRadius="3xl"
          boxShadow={" 0px 1px 4px 0px rgba(144, 144, 144, 0.25)"}
          color={"#D73030"}
          fontWeight={"bold"}
        >
          Ausências de cobranças detectadas
        </ModalHeader>
        <ModalCloseButton top={6} size="lg" color="gray.500" />

        <ModalBody p={4} fontFamily="Source Sans 3, sans-serif">
          <Flex direction={"column"} gap={2}>
            <Text fontSize={"1.375rem"} fontWeight={"bold"}>
              Atenção
            </Text>
            <Text fontSize={"1.375rem"}>
              {" "}
              Foram identificados
              <span style={{ fontWeight: "bold" }}> {total} débitos</span>{" "}
              fechados, porém não foram creditados
            </Text>
          </Flex>

          <Flex
            width={"100%"}
            justifyContent={"flex-end"}
            gap={4}
            marginTop={4}
          >
            <Button
              marginLeft={"auto"}
              borderRadius={"6.25rem"}
              bgColor={"#fff"}
              _hover={{ bg: "#f1f1f1" }}
              color={"#0B0B0B"}
              onClick={onClose}
              border={"1px"}
            >
              Cancelar
            </Button>
            <Button
              borderRadius={"6.25rem"}
              bgColor={"#0B0B0B"}
              _hover={{ bg: "#1A1A1A" }}
              onClick={handleNavigate}
            >
              Visualizar detalhes
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default DebtWithoutPayment;
