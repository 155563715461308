import { Flex, Icon, Text } from "@chakra-ui/react";

import { CalendarBlank } from "@phosphor-icons/react";
import { GetAuditUserActionType } from "../../../../types/get-audit-user-action.type";
import dayjs from "dayjs";

export function LogInfo({ data }: { data: GetAuditUserActionType }) {
  return (
    <Flex
      align="center"
      background={"var(--Neutral-100, #F4F4F4)"}
      color={"#6F6F6F"}
      padding={"0.125rem 0.5rem"}
      gap={"0.25rem"}
      justifyContent={"center"}
      alignContent={"center"}
      borderRadius={"0.25rem"}
      width={"auto"}
    >
      <Icon as={CalendarBlank} boxSize={4} mr={2} />
      <Text fontSize="sm">
        {data?.createdAt &&
          dayjs(data?.createdAt).format("DD/MM/YYYY HH:mm:ss")}{" "}
        | {data?.action} por: <strong>{data?.userName}</strong>
      </Text>
    </Flex>
  );
}
