import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

type IProps = {
  readonly title: string;
  readonly routes: {
    title: string;
    path: string;
  }[];
  readonly setMenuSelected: Dispatch<SetStateAction<string>>;
  readonly setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
};

export function SubMenuAccordionItem({
  title,
  routes,
  setMenuSelected,
  setIsMenuOpen,
}: IProps) {
  return (
    <Accordion w="full" allowToggle>
      <AccordionItem w="full" border="none">
        <AccordionButton
          w="full"
          px={3}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="md"
          _hover={{ bg: "#000", color: "#fff" }}
        >
          <Text
            w="full"
            textAlign="start"
            fontFamily="Source Sans 3, sans-serif"
            fontSize="xl"
            color="#000"
            fontWeight="400"
            textColor="inherit"
          >
            {title}
          </Text>

          <AccordionIcon
            color="inherit"
            fontSize="3xl"
            fontWeight="extrabold"
          />
        </AccordionButton>
        {routes.map((route) => (
          <Link key={route.title} to={route.path}>
            <AccordionPanel
              h="fit-content"
              py={2}
              pl={1}
              ml={8}
              fontFamily="Source Sans 3, sans-serif"
              fontSize="xl"
              borderRadius="md"
              _hover={{ bg: "#000", color: "#fff" }}
              onClick={() => {
                setMenuSelected(`${title} - ${route.title}`);
                setIsMenuOpen(false);
              }}
            >
              {route.title}
            </AccordionPanel>
          </Link>
        ))}
      </AccordionItem>
    </Accordion>
  );
}
