import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import { getWithAuth, patchWithAuth } from "../../../../services/basicService";
import { useQuery, useMutation } from "@tanstack/react-query";
import { GetDuplicatedAccountDetails } from "../../../../types/get-duplicated-account-details.type";
import { useCustomToast } from "./toast";

interface ChangeAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  logId: number;
  debtId?: number;
  refetch: () => void;
}

const ChangeAccountModal: React.FC<ChangeAccountModalProps> = ({
  isOpen,
  onClose,
  logId,
  debtId,
  refetch,
}) => {
  const { showCustomToast } = useCustomToast();

  const { data } = useQuery({
    queryKey: [`divergent-account-details`, logId, debtId],
    queryFn: async () => {
      const PATH = `/api/v1/webhook/divergent-account-details?logId=${logId}&debtId=${debtId}`;

      const res = await getWithAuth(PATH);

      return res?.data as GetDuplicatedAccountDetails;
    },
  });

  const mutation = useMutation({
    mutationFn: async (newAccountId: number) => {
      return await patchWithAuth(`/api/v1/debt/editDebt?debtId=${debtId}`, {
        accountId: newAccountId,
      });
    },
    onSuccess: () => {
      showCustomToast("Conta alterada com sucesso", "success");
      refetch();
      onClose();
    },
    onError: (error) => {
      showCustomToast("Erro ao alterar conta", "error");
      console.log(error);
    },
  });

  const handleAccountChange = (newAccountId: number) => {
    mutation.mutate(newAccountId);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="23.75rem" borderRadius="2xl">
        <ModalHeader
          fontSize="3xl"
          bg="#fffcf2"
          h="100px"
          pb={8}
          borderTopRadius="3xl"
          boxShadow={" 0px 1px 4px 0px rgba(144, 144, 144, 0.25)"}
        >
          Troca de conta
        </ModalHeader>
        <ModalCloseButton top={6} size="lg" color="gray.500" />

        <ModalBody p={4} fontFamily="Source Sans 3, sans-serif">
          <Flex direction={"column"} gap={2}>
            <Text fontSize={"1rem"}>A conta será atualizada para:</Text>
            <Text fontWeight={"bold"} fontSize={"1.375rem"}>
              {data?.newAccountId}
            </Text>
          </Flex>
          <Flex marginTop={4}>
            <Text fontWeight={"bold"} fontSize={"1rem"}>
              Confirmar essa ação?
            </Text>
          </Flex>
          <Flex
            width={"100%"}
            justifyContent={"flex-end"}
            gap={4}
            marginTop={4}
          >
            <Button
              marginLeft={"auto"}
              borderRadius={"6.25rem"}
              bgColor={"#fff"}
              _hover={{ bg: "#f1f1f1" }}
              color={"#0B0B0B"}
              onClick={onClose}
              border={"1px"}
            >
              Cancelar
            </Button>
            <Button
              borderRadius={"6.25rem"}
              bgColor={"#0B0B0B"}
              _hover={{ bg: "#1A1A1A" }}
              onClick={() => {
                if (data?.newAccountId) {
                  handleAccountChange(data?.newAccountId);
                }
              }}
            >
              Confirmar
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ChangeAccountModal;
