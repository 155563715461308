import { VStack } from "@chakra-ui/layout";
import { RulesManagementForm } from "./create/page-components/rules-management-form.component";

export function RulesManagementPage() {
  return (
    <VStack gap={8} flex={1} alignItems={"flex-start"}>
      <RulesManagementForm />
    </VStack>
  );
}
