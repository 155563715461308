import React, { memo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Box,
  FormControl,
  FormLabel,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Button,
  Checkbox,
  MenuItem,
  Divider,
} from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import InputDate from "../../../../components-ui/input-date";
import { FilterFormInputsValidation } from ".";
import { LucideChevronDown } from "lucide-react";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  control: Control<FilterFormInputsValidation>;
  register: UseFormRegister<FilterFormInputsValidation>;
  getValues: UseFormGetValues<FilterFormInputsValidation>;
  refetch: () => void;
  setIsFilterActive: React.Dispatch<React.SetStateAction<boolean>>;
  isOnlyUser: boolean;
  setValue: UseFormSetValue<FilterFormInputsValidation>;
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  onClose,
  control,
  register,
  getValues,
  refetch,
  setIsFilterActive,
  isOnlyUser,
  setValue,
}) => {
  const formValues = useWatch({ control });
  const isFormFilled = Object.values(formValues).some((value) => value);
  const mappedTypeAction = {
    creation: "Criação",
    refund: "Reembolso",
    cancellation: "Cancelamento",
    payment: "Pagamento",
    unknown: "Desconhecido",
  };

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleChange = (value: string) => {
    const newValues = selectedValues.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...selectedValues, value];

    setTimeout(() => {
      setSelectedValues(newValues);
    }, 1000);
    setValue(
      "typeAction",
      newValues as (
        | "creation"
        | "refund"
        | "cancellation"
        | "payment"
        | "unknown"
      )[]
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px" borderRadius="2xl">
        <ModalHeader
          fontSize="3xl"
          bg="#fffcf2"
          h="100px"
          w="full"
          pb={8}
          borderTopRadius={"3xl"}
          boxShadow={"0px 1px 4px 0px rgba(144, 144, 144, 0.25)"}
        >
          Filtro
        </ModalHeader>
        <ModalCloseButton top={6} size="lg" color="gray.500" />

        <ModalBody p={4} fontFamily="Source Sans 3, sans-serif">
          {/* CPF Input */}
          {!isOnlyUser && (
            <>
              <Box mb={6}>
                <Text fontSize="xl" mb={2}>
                  Document
                </Text>
                <Input
                  {...register("document")}
                  placeholder="Insira o CPF"
                  size="md"
                  borderRadius="md"
                  bg="white"
                  h="12"
                  fontSize="xl"
                />
                <Text fontSize="md" color="gray.500" mt={1}>
                  Apenas números
                </Text>
              </Box>

              <Box mb={6}>
                <Text fontSize="xl" mb={2}>
                  ID do Débito
                </Text>
                <Input
                  {...register("debtId")}
                  placeholder="Insira o número"
                  size="md"
                  height={"2.5rem"}
                  borderRadius="md"
                  bg="white"
                  h="12"
                  fontSize="xl"
                />
              </Box>
            </>
          )}

          {/* Tipo de operação */}
          <Box mb={6} display={"flex"} width={"full"}>
            <FormControl>
              <FormLabel fontSize="xl" mb={2}>
                Tipo de operação
              </FormLabel>
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  variant="outline"
                  height={"2.5rem"}
                  fontSize={"0.875rem"}
                  w="full"
                  fontWeight={400}
                  border={"1px"}
                  color={"#929292"}
                  textAlign="left"
                  rightIcon={<LucideChevronDown />}
                >
                  {selectedValues.length
                    ? selectedValues
                        .map(
                          (value: string) =>
                            mappedTypeAction[
                              value as keyof typeof mappedTypeAction
                            ]
                        )
                        .join(", ")
                    : "Selecione"}
                </MenuButton>
                <MenuList width={"35vw"} zIndex={2}>
                  <MenuOptionGroup zIndex={2} type="checkbox" width={"35vw"}>
                    {[
                      { value: "creation", label: "Criação" },
                      { value: "refund", label: "Estorno" },
                      { value: "cancellation", label: "Cancelamento" },
                      { value: "payment", label: "Pagamento" },
                      { value: "unknown", label: "Desconhecido" },
                    ].map(({ value, label }) => (
                      <Box key={value} zIndex={2} width={"35vw"}>
                        <MenuItem
                          key={value}
                          zIndex={2}
                          onClick={() => handleChange(value)}
                        >
                          <Checkbox
                            value={value}
                            isChecked={selectedValues.includes(value)}
                            _before={{ color: "black" }}
                            colorScheme="green"
                          >
                            <Text>{label}</Text>
                          </Checkbox>
                        </MenuItem>
                        <Divider />
                      </Box>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </FormControl>
          </Box>

          {/* Date Fields */}
          <Flex gap={4} mb={6}>
            <InputDate
              control={control}
              name="startDate"
              label="Período - Data inicial"
              getValues={getValues}
            />
            <InputDate
              control={control}
              name="endDate"
              label="Data final"
              getValues={getValues}
            />
          </Flex>

          {/* Radio Buttons */}
          <Box mb={8}>
            <FormControl w="fit-content">
              <FormLabel
                display="flex"
                gap="12px"
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
              >
                Ordenação
              </FormLabel>
              <Controller
                name="order"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    onChange={onChange}
                    value={value}
                    defaultValue="desc"
                    fontFamily="Source Sans 3, sans-serif"
                    fontSize="xl"
                  >
                    <Stack direction="row">
                      <Radio value="desc" colorScheme="green">
                        <Text
                          fontFamily="Source Sans 3, sans-serif"
                          fontSize="xl"
                        >
                          Crescente
                        </Text>
                      </Radio>
                      <Radio value="asc" colorScheme="green">
                        <Text
                          fontFamily="Source Sans 3, sans-serif"
                          fontSize="xl"
                        >
                          Descrescente
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>

          {/* Action Buttons */}
          <Stack direction="row" spacing={4} justify="flex-end">
            <Button
              variant="outline"
              size="md"
              onClick={onClose}
              borderRadius="md"
              px={6}
            >
              Cancelar
            </Button>
            <Button
              bg={isFormFilled ? "green.500" : "gray.200"}
              _hover={{ bg: isFormFilled ? "green.600" : "gray.300" }}
              color={isFormFilled ? "white" : "black"}
              size="md"
              borderRadius="md"
              px={6}
              onClick={() => {
                refetch();
                onClose();
                setIsFilterActive(true);
              }}
            >
              Filtrar
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default memo(FilterModal);
