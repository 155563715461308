import { useState } from "react";
import { ResponseType } from "axios";
import { getWithAuth } from "../../services/basicService";
import { useToast } from "@chakra-ui/react";

export function useReceiptAdvance() {
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const toast = useToast();

  const getReceipt = (userAdvanceId: number, name: string) => {
    if (loadingReceipt) return;

    setLoadingReceipt(true);

    const timezoneOffset = new Date().getTimezoneOffset() / 60;

    const ENDPOINT = `/api/v1/userAdvance/receipt/${userAdvanceId}?timezoneOffset=${timezoneOffset}`;

    getWithAuth(
      ENDPOINT,
      {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
      { responseType: "arraybuffer" as ResponseType }
    )
      .then((res) => {
        const pdfBlob = new Blob([res?.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `comprovante-adiantamento-${name}.pdf`);
        link.click();
        URL.revokeObjectURL(url);

        toast({
          title: "Comprovante gerado com sucesso",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .finally(() => setLoadingReceipt(false));
  };

  return { getReceipt, loadingReceipt };
}
