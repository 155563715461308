import { Flex, Text } from "@chakra-ui/layout";

type IProps = {
  readonly label: string;
  readonly value: string | number | undefined;
};

export function InfoRow({ label, value }: IProps) {
  return (
    <Flex
      justifyContent="space-between"
      px={4}
      py={2}
      borderBottom="1px solid"
      borderColor="gray.200"
      _last={{ borderBottom: "none" }}
    >
      <Text fontSize="md">{label}</Text>
      <Text fontSize="md" fontWeight="extrabold">
        {value}
      </Text>
    </Flex>
  );
}
