import { Button, Text } from "@chakra-ui/react";
import { useTwoFactorResend } from "./hooks/use-two-factor-resend";

type IProps = {
  readonly isOpen: boolean;
  readonly debtId: number | null;
  readonly action: "cancel" | "pay off" | "reverse" | null;
};

export function CloudManagementDebtsActionsResendTwoAuthCode({
  isOpen,
  debtId,
  action,
}: IProps) {
  const { isTimerActive, timeLeft, resendCode } = useTwoFactorResend({
    isOpen,
    debtId,
    action,
  });

  if (isTimerActive) {
    return (
      <Text fontSize="small" color="#A5A5A5">
        Reenviar código em ({timeLeft} segundos)
      </Text>
    );
  }

  return (
    <Button
      onClick={resendCode}
      color="blue.500"
      bg="inherit"
      _hover={{ bg: "inherit" }}
    >
      Reenviar código
    </Button>
  );
}
