import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  PortalManager,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CheckCircle, WarningCircle, X } from "@phosphor-icons/react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useQueryClient } from "@tanstack/react-query";
import { CloudManagementDebtsActionsResendTwoAuthCode } from "./resend-two-auth-code";
import { useCloudManagementDebtsActionStore } from "../store/action-store";

interface IProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly action: "cancel" | "pay off" | "reverse" | null;
  readonly debtId: number | null;
  readonly twoAuthUUID: string;
}

const pinSchema = z.object({
  pin: z
    .string()
    .regex(/^\d{6}$/, "O PIN deve conter exatamente 6 dígitos numéricos"),
});

export function CloudManagementDebtsActionsTwoAuthConfirm({
  isOpen,
  onClose,
  action,
  debtId,
  twoAuthUUID,
}: IProps) {
  const { action: callback } = useCloudManagementDebtsActionStore();
  const client = useQueryClient();
  const toast = useToast({
    position: "top",
    containerStyle: {
      width: "100%",
      maxWidth: "100%",
      fontFamily: "Source Sans 3, sans-serif",
    },
    render: ({ status, title, description, onClose, icon }) => (
      <Flex
        w="full"
        h="fit-content"
        bg={status === "success" ? "#1DC161" : "#D65745"}
        color="white"
        px="5"
        py="5"
        borderRadius="md"
        fontFamily="Source Sans 3, sans-serif"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexDir="column" gap={2}>
          <Flex gap={2} alignItems="center" fontWeight="semibold" fontSize="xl">
            {icon}
            {title && <Text>{title}</Text>}
          </Flex>
          <Text fontWeight="light" fontSize="medium">
            {description}
          </Text>
        </Flex>

        <X onClick={onClose} cursor="pointer" />
      </Flex>
    ),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<{ pin: string }>({
    resolver: zodResolver(pinSchema),
    defaultValues: { pin: "" },
  });

  const handleConfirmationToken = async (data: { pin: string }) => {
    try {
      await callback(Number(data.pin), twoAuthUUID);

      const successActionMapper = {
        cancel: "Cancelamento realizado com sucesso!",
        "pay off": "Débito adiciona a fila de pagamento com sucesso!",
        reverse: "Estorno realizado com sucesso!",
      };

      if (action && action in successActionMapper) {
        toast({
          status: "success",
          position: "top",
          icon: <CheckCircle size={24} color="#ffffff" weight="fill" />,
          title: successActionMapper[action],
        });
      }

      client.invalidateQueries([
        "cloud-management-debts-pagination",
        "cloud-management-debts-info",
        "payments-and-refunds",
        "cloud-management-debts-details",
      ]);
      reset();
      onClose();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorActionMapper = {
        cancel: "cancelar",
        "pay off": "quitar",
        reverse: "estornar",
      };

      if (action && action in errorActionMapper) {
        toast({
          status: "error",
          position: "top",
          icon: <WarningCircle size={24} color="#ffffff" weight="fill" />,
          title: `Ops... Não foi possível ${errorActionMapper[action]} o débito!`,
          description: error?.response?.data.message,
        });
      }
    }
  };

  const onCloseModal = () => {
    reset();
    onClose();
  };

  return (
    <PortalManager zIndex={2000}>
      <Modal isOpen={isOpen} onClose={onCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="500px"
          h="fit-content"
          borderRadius="2xl"
          overflow="hidden"
        >
          <ModalHeader
            bg="#fffcf2"
            h="100px"
            w="full"
            py={8}
            boxShadow="0px 1px 4px rgba(144, 144, 144, 0.25)"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="2xl" fontFamily="Poppins" fontWeight="semibold">
              Autenticação de dois fatores
            </Text>
            <Button onClick={onClose} bg="inherit" _hover={{ bg: "inherit" }}>
              <X size={28} color="#6f6f6f" />
            </Button>
          </ModalHeader>

          <ModalBody
            py={4}
            px={8}
            fontFamily="Source Sans 3, sans-serif"
            display="flex"
            flexDir="column"
            justifyContent="start"
          >
            <Flex flexDir="column" gap={4}>
              <Text fontWeight="bold" fontSize="medium">
                Confirmação da ação...
              </Text>
              <Text fontSize="medium">
                Enviamos para o e-mail cadastrado o código de verificação, por
                favor insira o código abaixo. Caso não tenha encontrado,
                verifique a caixa de spam.
              </Text>
              <Text fontSize="small">Insira o código enviado</Text>
              <Flex
                gap={2}
                justifyContent="space-between"
                as="form"
                onSubmit={handleSubmit(handleConfirmationToken)}
                id="confirmation-token-form"
                flexDir="column"
              >
                <Controller
                  control={control}
                  name="pin"
                  render={({ field }) => (
                    <Flex gap={4}>
                      <PinInput
                        type="number"
                        colorScheme="red"
                        onChange={field.onChange}
                        value={field.value || ""}
                        isInvalid={!!errors.pin}
                        autoFocus
                      >
                        <PinInputField
                          _focus={{ borderColor: "green.500" }}
                          w="60px" // Substitui style.width
                          h="60px" // Substitui style.height
                          fontSize="xl" // Aumenta o tamanho da fonte
                        />
                        <PinInputField
                          _focus={{ borderColor: "green.500" }}
                          w="60px"
                          h="60px"
                          fontSize="xl"
                        />
                        <PinInputField
                          _focus={{ borderColor: "green.500" }}
                          w="60px"
                          h="60px"
                          fontSize="xl"
                        />
                        <PinInputField
                          _focus={{ borderColor: "green.500" }}
                          w="60px"
                          h="60px"
                          fontSize="xl"
                        />
                        <PinInputField
                          _focus={{ borderColor: "green.500" }}
                          w="60px"
                          h="60px"
                          fontSize="xl"
                        />
                        <PinInputField
                          _focus={{ borderColor: "green.500" }}
                          w="60px"
                          h="60px"
                          fontSize="xl"
                        />
                      </PinInput>
                    </Flex>
                  )}
                />
                {errors.pin && (
                  <Text color="red.500" fontSize="sm">
                    {errors.pin.message}
                  </Text>
                )}
              </Flex>
              <CloudManagementDebtsActionsResendTwoAuthCode
                action={action}
                debtId={debtId}
                isOpen={isOpen}
              />
            </Flex>
            <Divider my="4" />
            <Flex w="full" justifyContent="end" alignItems="center" gap={8}>
              <Button
                py={6}
                px={8}
                bg="inherit"
                color="black"
                border="1px solid black"
                borderRadius="full"
                _hover={{ bg: "inherit" }}
                fontFamily="Poppins"
                fontSize="md"
                onClick={onCloseModal}
              >
                Cancelar
              </Button>
              <Button
                py={6}
                px={8}
                bg="black"
                color="white"
                border="1px solid black"
                borderRadius="full"
                _hover={{ bg: "black" }}
                fontFamily="Poppins"
                fontSize="md"
                type="submit"
                form="confirmation-token-form"
                isLoading={isSubmitting}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PortalManager>
  );
}
