import { useRef, useState } from "react";
import { useUserBalanceStore } from "../../../store/user-balance.store";
import { useCurrentUserStore } from "../../../store/current-user.store";
import {
  VStack,
  Heading,
  Badge,
  HStack,
  Flex,
  Box,
  Text,
  Stack,
} from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Icon,
  useColorMode,
  useToast,
  Stat,
  StatLabel,
  StatNumber,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  RadioGroup,
  Radio,
  Tooltip,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowDown, ArrowUp, Info, Trash } from "@phosphor-icons/react";
import { documentCpfCnpjFormatter } from "../../../utils/format-document";
import { convertCentsToReais } from "../../../utils/convert-money";
import dayjs from "dayjs";
import { Pagination } from "../../../components/pagination";
import { useQuery } from "@tanstack/react-query";
import { LoadingLogo } from "../../../components/loading";
import { deleteWithAuth, getWithAuth } from "../../../services/basicService";
import { CustomModal } from "../../../components/modal";
import { fortnightValidForToday } from "../../../utils/fortnight-valid-for-today";
import { DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { COMPANY_TYPE, CompanyDto } from "../../../dto/company-dto";
import { isFranchisor } from "../../../utils/company-verifications";

const filterBalanceSchema = z.object({
  search: z.string(),
  balancesUsed: z.string(),
  getDeletedBalances: z.string(),
  companyId: z.string().optional(),
});

type filterBalanceFormInputs = z.infer<typeof filterBalanceSchema>;

const deleteByPeriodFormSchema = z.object({
  period: z.object({
    from: z.date(),
    to: z.date().optional(),
  }),
});

type deleteByPeriodFormInputs = z.infer<typeof deleteByPeriodFormSchema>;

type DeleteBalanceData = {
  id: number;
  name: string;
  balanceDate: string;
  createdAt: string;
};

export function UserBalanceEnterprise() {
  const me = useCurrentUserStore((state) => state.user);

  const getAllPlazas = () => {
    const pracas = me?.company?.pracas;

    const allPracas = pracas || "";

    return allPracas
      .split(",")
      .map((praca) => ({ label: praca, value: praca }));
  };

  const deleteBalanceData = useRef<DeleteBalanceData>();
  const [deleteBalanceLoading, setDeleteBalanceLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [isOrderByDesc, setIsOrderByDesc] = useState<boolean>(true);
  const [plaza, setPlaza] = useState(getAllPlazas()[0].label);

  const userBalance = useUserBalanceStore((state) => state.userBalance);
  const getUserBalance = useUserBalanceStore((state) => state.getUserBalance);
  const deleteUserBalanceByPlaza = useUserBalanceStore(
    (state) => state.deleteUserBalanceByPlaza
  );
  const deleteUserBalanceByPeriod = useUserBalanceStore(
    (state) => state.deleteUserBalanceByPeriod
  );

  const { colorMode } = useColorMode();
  const toast = useToast({ duration: 4000, isClosable: true, position: "top" });

  const fetchBalanceData = async () => {
    const dataForm = getValues();
    const filters = urlGenerate(dataForm);
    try {
      return await getUserBalance(
        `/api/v1/userAdvanceBalance/company?${filters}`
      );
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao buscar saldos",
        description:
          "Não foi possível buscar saldos. Tente novamente ou contate o suporte.",
        status: "error",
      });
    }
  };

  const { isLoading, isFetching, refetch } = useQuery(
    ["balance-data", page, limit, isOrderByDesc],
    fetchBalanceData
  );

  const { data: companyFranchisees } = useQuery(
    ["company-franchisees"],
    async () => {
      try {
        const response = await getWithAuth(
          `/api/v1/companies/franchisees/${me?.company?.id}`
        );
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: isFranchisor(me?.company as CompanyDto),
      initialData: () => ({ id: null, franchisees: [] as CompanyDto[] }),
    }
  );

  const handleItemsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
    setPage(1);
    setIsOrderByDesc(true);
  };

  const urlGenerate = (data: filterBalanceFormInputs) => {
    const params = [];

    if (data.search) {
      params.push(`search=${data.search}`);
    }

    if (data.balancesUsed !== "all" && data.balancesUsed !== undefined) {
      params.push(`balancesUsed=${data.balancesUsed}`);
    }

    if (JSON.parse(data.getDeletedBalances)) {
      params.push(`getDeletedBalances=${data.getDeletedBalances}`);
    }

    if (data.companyId) {
      params.push(`companyId=${data.companyId}`);
    }

    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    let url = `page=${page}&limit=${limit}&timezoneOffset=${timezoneOffset}`;

    if (isOrderByDesc) {
      url += `&orderBy=DESC`;
    } else {
      url += `&orderBy=ASC`;
    }

    url += `&${params.join("&")}`;

    return url;
  };

  const deleteBalance = useDisclosure({ id: "delete-balance" });

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { isDirty },
  } = useForm<filterBalanceFormInputs>({
    resolver: zodResolver(filterBalanceSchema),
    defaultValues: { getDeletedBalances: "false" },
  });

  const handleOpenDeleteBalancesModal = (data: DeleteBalanceData) => {
    deleteBalanceData.current = data;

    deleteBalance.onOpen();
  };

  const handleDeleteBalances = () => {
    if (deleteBalanceLoading) {
      return;
    }

    setDeleteBalanceLoading(true);

    const URL = `/api/v1/userAdvanceBalance/${deleteBalanceData.current?.id}`;

    deleteWithAuth(URL)
      .then(() => {
        refetch();

        deleteBalance.onClose();

        toast({
          status: "success",
          title: "Sucesso ao deletar o saldo",
          description: `Saldo do(a) ${deleteBalanceData.current?.name} foi deletado`,
        });
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Erro ao deletar o saldo",
          description: `Não foi possível deletar o saldo`,
        });
      })
      .finally(() => setDeleteBalanceLoading(false));
  };

  const handleDeletePerPlaza = async () => {
    try {
      setDeleteBalanceLoading(true);
      await deleteUserBalanceByPlaza([plaza]);
      refetch();
    } catch (error) {
    } finally {
      setDeleteBalanceLoading(false);
    }
  };

  const deleteByPeriodForm = useForm<deleteByPeriodFormInputs>({
    resolver: zodResolver(deleteByPeriodFormSchema),
    defaultValues: { period: { from: undefined, to: undefined } },
  });

  const handleDeletePerPeriod = async ({
    period: { from, to },
  }: {
    period: {
      from: Date;
      to?: Date;
    };
  }) => {
    to = to || dayjs(from).endOf("day").toDate();
    try {
      setDeleteBalanceLoading(true);
      await deleteUserBalanceByPeriod(from, to);
      refetch();
      deleteByPeriodForm.reset();
    } catch (error) {
    } finally {
      setDeleteBalanceLoading(false);
    }
  };

  return (
    <VStack
      gap={8}
      w="full"
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Saldos
      </Heading>
      <Flex gap={8} justifyContent="space-between" w="full">
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
          display="flex"
          alignItems="center"
        >
          <Stat>
            <StatLabel fontSize="xl">Total de registros:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {userBalance?.totalRecords || 0}
            </StatNumber>
          </Stat>
        </Box>
        {isFranchisor(me?.company as CompanyDto) ? null : (
          <Flex gap={4}>
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <FormControl as="fieldset" gap={4}>
                <Flex dir="row" justifyContent="center" alignItems="center">
                  <FormLabel as="legend" fontSize="lg">
                    Deletar saldo por praça
                  </FormLabel>
                  <Tooltip
                    label="Essa função irá deletar todos os saldos da quinzena."
                    aria-label="Alerta que irá deletar todos os saldos da quinzena"
                  >
                    <Info size={20} />
                  </Tooltip>
                </Flex>

                <Select
                  variant="filled"
                  size="md"
                  flex={1}
                  value={plaza}
                  mb={4}
                  onChange={(e) => setPlaza(e.target.value)}
                >
                  {getAllPlazas().map((plaza) => (
                    <option value={plaza.value} key={plaza.value}>
                      {plaza.label}
                    </option>
                  ))}
                </Select>
                <CustomModal
                  modalHeader="Deletar saldo por praças"
                  buttonProps={{
                    color: "whiteAlpha.900",
                    w: "max-content",
                    _hover: {
                      bg: "green.700",
                    },
                    px: 8,
                    size: "md",
                    isLoading: deleteBalanceLoading,
                    isDisabled: userBalance?.result?.length === 0,
                  }}
                  buttonText="Deletar"
                  onConfirm={handleDeletePerPlaza}
                  modalBody={`Deseja deletar os saldos do perído ${dayjs(
                    fortnightValidForToday().from
                  )
                    .utc()
                    .format("DD/MM/YYYY")} até ${dayjs(
                    fortnightValidForToday().to
                  )
                    .utc()
                    .format("DD/MM/YYYY")} da praça ${plaza}?`}
                  confirmButtonText="Deletar"
                />
              </FormControl>
            </Box>
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
              as="form"
              id="delete-by-period-form"
              onSubmit={deleteByPeriodForm.handleSubmit(handleDeletePerPeriod)}
            >
              <FormControl as="fieldset" gap={4}>
                <Flex dir="row" justifyContent="center" alignItems="center">
                  <FormLabel as="legend" fontSize="lg">
                    Deletar saldo por período
                  </FormLabel>
                  <Tooltip
                    label="Essa função irá deletar todos os saldos do período selecionado."
                    aria-label="Alerta que irá deletar todos os saldos do período selecionado."
                  >
                    <Info size={20} />
                  </Tooltip>
                </Flex>

                <Controller
                  control={deleteByPeriodForm.control}
                  name="period"
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      as="fieldset"
                      w="full"
                      display="flex"
                      flexDir="column"
                      gap={4}
                      alignItems="center"
                    >
                      <Popover placement="auto-end">
                        <style>{`
                          .rdp-caption_label: {
                            text-transform: capitalize;
                          }
                          .custom-selected:not([disabled]) {
                            background-color: #399469;
                            color: white;
                          }
                          .custom-selected:hover:not([disabled]):not(.rdp-day_selected) {
                            background-color: #276749;
                          }
                      `}</style>
                        <PopoverTrigger>
                          <Button>Escolha a sua data</Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent
                            bg={colorMode === "dark" ? "gray.900" : "white"}
                            p={2}
                            w="min-content"
                          >
                            <PopoverBody>
                              <DayPicker
                                id="dateFilter"
                                mode="range"
                                selected={value}
                                onSelect={onChange}
                                locale={ptBR}
                                modifiersClassNames={{
                                  disabled: "btn-disabled",
                                  selected: "custom-selected",
                                  outside: "outside-day",
                                }}
                              />
                              <Text fontSize="lg" textAlign="center">
                                Caso queira selecionar apenas um dia clique duas
                                vezes no dia desejado
                              </Text>
                            </PopoverBody>
                          </PopoverContent>
                        </Portal>
                      </Popover>
                      <CustomModal
                        modalHeader="Deletar saldo por período"
                        buttonProps={{
                          color: "whiteAlpha.900",
                          w: "max-content",
                          _hover: {
                            bg: "green.700",
                          },
                          px: 8,
                          size: "md",
                          isLoading: deleteBalanceLoading,
                          isDisabled: userBalance?.result?.length === 0,
                        }}
                        buttonText="Deletar"
                        modalBody={`Deseja deletar os saldos do perído ${dayjs(
                          value.from
                        )
                          .utc()
                          .format("DD/MM/YYYY")} até ${dayjs(value.to)
                          .utc()
                          .format("DD/MM/YYYY")}?`}
                        confirmButtonText="Deletar"
                        confirmButtonProps={{
                          type: "submit",
                          form: "delete-by-period-form",
                          bg: "red.600",
                        }}
                      />
                    </FormControl>
                  )}
                />
              </FormControl>
            </Box>
          </Flex>
        )}
      </Flex>
      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          gap={4}
          id="form"
          w="full"
          p={4}
          onSubmit={handleSubmit(() => refetch())}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            w="full"
            justifyContent="center"
            alignItems="center"
            gap={8}
          >
            <FormControl as="fieldset">
              <FormLabel as="legend">CPF/ID/Nome/Praça</FormLabel>
              <Input
                type="text"
                {...register("search")}
                bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.300" : "gray.700"}
                placeholder="Busque pelo CPF, Praça, ID ou nome do entregador"
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </FormControl>
            <FormControl as="fieldset">
              <FormLabel as="legend">Saldos</FormLabel>
              <Select
                variant="filled"
                size="md"
                {...register("balancesUsed")}
                w="full"
              >
                <option value="all">Todos</option>
                <option value="true">Utilizados</option>
                <option value="false">Não Utilizados</option>
              </Select>
            </FormControl>
            {isFranchisor(me?.company as CompanyDto) ? (
              <FormControl as="fieldset">
                <FormLabel as="legend">Franqueada</FormLabel>
                <Select
                  {...register("companyId")}
                  placeholder="Todas"
                  _placeholder={{
                    color: "gray.500",
                  }}
                  variant="filled"
                  focusBorderColor="green.500"
                  w="full"
                >
                  {companyFranchisees.franchisees.map((company: CompanyDto) => (
                    <option value={company.id} key={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <FormControl w="fit-content">
              <FormLabel display="flex" gap="12px">
                Buscar deletados?
              </FormLabel>
              <Controller
                name="getDeletedBalances"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <Stack direction="row">
                      <Radio value="true" colorScheme="green">
                        Sim
                      </Radio>
                      <Radio value="false" colorScheme="red">
                        Não
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <Button
              type="submit"
              form="form"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
              alignSelf="flex-end"
              isDisabled={!isDirty || isFetching}
              isLoading={isFetching}
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>

        <TableContainer
          w="full"
          flex={1}
          gap={4}
          display="flex"
          flexDir="column"
        >
          <Table>
            <Thead>
              <Tr>
                <Th flex={1}>Nome</Th>
                <Th textAlign="center">DOCUMENTO</Th>
                <Th textAlign="center">ID Entregador</Th>
                {isFranchisor(me?.company as CompanyDto) ? (
                  <Th textAlign="center">Empresa</Th>
                ) : null}
                <Th textAlign="center">Praça</Th>
                <Th isNumeric>Saldo</Th>
                <Th textAlign="center">Pedido foi realizado?</Th>
                <Th
                  textAlign="center"
                  onClick={() => setIsOrderByDesc(!isOrderByDesc)}
                  cursor="pointer"
                >
                  Data de referência
                  {isOrderByDesc ? (
                    <Icon as={ArrowDown} />
                  ) : (
                    <Icon as={ArrowUp} />
                  )}
                </Th>
                <Th textAlign="center">Data de criação</Th>
                {JSON.parse(getValues().getDeletedBalances) && (
                  <Th textAlign="center">Data de exclusão</Th>
                )}
                <Th textAlign="center">Deletar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading ? (
                userBalance?.result.map((balance) => {
                  return (
                    <Tr fontWeight={600} key={balance.id}>
                      <Td
                        maxW="200px"
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        textTransform={"capitalize"}
                      >
                        {balance.name ? balance.name.toLowerCase() : "-"}
                      </Td>
                      <Td textAlign="center">
                        {documentCpfCnpjFormatter(balance.document)}
                      </Td>
                      <Td textAlign="center">
                        {balance.idEntregador ? balance.idEntregador : "-"}
                      </Td>
                      {me?.company?.companyType ===
                      COMPANY_TYPE.FRANQUEADORA ? (
                        <Td textAlign="center">{balance.company}</Td>
                      ) : null}
                      <Td textAlign="center">
                        {balance.praca ? balance.praca : "-"}
                      </Td>
                      <Td isNumeric>{convertCentsToReais(balance.balance)}</Td>
                      <Td textAlign="center">
                        <Badge
                          variant="solid"
                          px={4}
                          colorScheme={balance.wasUsed ? "green" : "red"}
                        >
                          {balance.wasUsed ? "Sim" : "Não"}
                        </Badge>
                      </Td>
                      <Td textAlign="center">
                        {balance.balanceDate
                          ? dayjs(balance.balanceDate).format("DD/MM/YYYY")
                          : "-"}
                      </Td>
                      <Td textAlign="center">
                        {dayjs(balance.createdAt).format("DD/MM/YYYY HH:mm")}
                      </Td>
                      {JSON.parse(getValues().getDeletedBalances) && (
                        <Td textAlign="center">
                          {balance.deletedAt === null
                            ? "-"
                            : dayjs(balance.deletedAt).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                        </Td>
                      )}
                      <Td textAlign="center">
                        {balance.wasUsed || balance.deletedAt !== null ? (
                          <Flex justifyContent="center">-</Flex>
                        ) : (
                          <Button
                            backgroundColor="red.400"
                            onClick={() =>
                              handleOpenDeleteBalancesModal({
                                id: balance.id,
                                name: balance.name,
                                balanceDate: balance.balanceDate,
                                createdAt: balance.createdAt,
                              })
                            }
                          >
                            <Trash size={24} color="white" />
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <LoadingLogo />
              )}
            </Tbody>
          </Table>
          <HStack gap={8} pt={8} justifyContent="flex-end">
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={userBalance?.totalPages || 1}
            />
            <Select
              variant="filled"
              size="md"
              w="100px"
              onChange={handleItemsPerPage}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Select>
          </HStack>
        </TableContainer>
      </Box>

      <Modal
        isOpen={deleteBalance.isOpen}
        onClose={deleteBalance.onClose}
        size="5xl"
        id="delete-balance"
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader fontSize="3xl">
            Confirmar Ação de Deletar Saldo
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <Flex flexDirection="column" fontSize="2xl">
              <Flex marginBottom="5">
                Essa ação ira deletar o saldo do(a){" "}
                {deleteBalanceData.current?.name}
              </Flex>
              <Flex as="ul" flexDirection="column" gap="5px">
                <li>
                  data de referencia:{" "}
                  {dayjs(deleteBalanceData.current?.balanceDate).format(
                    "DD/MM/YYYY"
                  )}
                </li>
                <li>
                  data de criação:{" "}
                  {dayjs(deleteBalanceData.current?.createdAt).format(
                    "DD/MM/YYYY"
                  )}
                </li>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter my={4}>
            <Button
              onClick={deleteBalance.onClose}
              isDisabled={deleteBalanceLoading}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteBalances}
              ml={3}
              isLoading={deleteBalanceLoading}
              isDisabled={deleteBalanceLoading}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
