import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Switch,
  Flex,
  Button,
  Text,
  Progress,
  useDisclosure,
} from "@chakra-ui/react";
import { useHistoryPointsContext } from "../provider/history-points.provider";
import { useState } from "react";
import RewardDetailsModal from "./reward-details-modal.component";

function RewardsTable() {
  const {
    pointsHistory,
    mutate,
    handleChangePage,
    isLoading,
    isLoadingMutation,
  } = useHistoryPointsContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedReward, setSelectedReward] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpenModal = (reward: any) => {
    setSelectedReward(reward);
    onOpen();
  };

  return (
    <Box mt={10}>
      <Progress
        value={isLoading ? 100 : 0}
        size="md"
        isIndeterminate
        colorScheme="green"
        width={"full"}
        display={isLoading ? "block" : "none"}
        mt={2}
        mb={2}
      />
      <Table variant="striped" size={"md"}>
        <Thead>
          <Tr>
            <Th>Nome do Prêmio</Th>
            <Th>Data de Ativação</Th>
            <Th>Categoria</Th>
            <Th>Valor em Pontos</Th>
            <Th>Data de Inativação</Th>
            <Th>Descrição</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pointsHistory?.data?.map((reward, index) => (
            <Tr key={index}>
              <Td>{reward.name}</Td>
              <Td>
                {reward.createdAt
                  ? new Date(reward.createdAt).toLocaleDateString()
                  : "Data indisponível"}
              </Td>

              <Td>{reward.category}</Td>
              <Td>{reward.value}</Td>
              <Td>
                {reward.deactivatedAt
                  ? new Date(reward.deactivatedAt).toLocaleDateString()
                  : "Data indisponível"}
              </Td>
              <Td cursor="pointer">
                <Text
                  as="button"
                  color="green.600"
                  textDecoration="underline"
                  onClick={() => handleOpenModal(reward)}
                >
                  {reward.description}
                </Text>
              </Td>
              <Td>
                <Switch
                  colorScheme="green"
                  isChecked={reward.active}
                  isDisabled={isLoadingMutation}
                  onChange={() =>
                    mutate({
                      giftId: reward.id,
                      status: !reward.active,
                    })
                  }
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent="space-between" alignItems="center" mt={4}>
        <Button
          onClick={() =>
            handleChangePage(pointsHistory?.page ? pointsHistory?.page - 1 : 1)
          }
          isDisabled={pointsHistory?.page === 1}
        >
          Anterior
        </Button>
        <Text>
          Página {pointsHistory?.page} de{" "}
          {Math.ceil((pointsHistory?.count || 1) / 10)}
        </Text>
        <Button
          onClick={() =>
            handleChangePage(pointsHistory?.page ? pointsHistory?.page + 1 : 1)
          }
        >
          Próxima
        </Button>
      </Flex>
      <RewardDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        reward={selectedReward}
      />
    </Box>
  );
}

export default RewardsTable;
