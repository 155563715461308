import { Box, Flex, HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UseStepsReturn,
  useDisclosure,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useCreatePayroll } from "../../../../store/payroll.store";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { CompanyStatus } from "../../../../enums/company-status";
import { PayrollStatus } from "../../../../enums/payroll-status";
import { PayrollType } from "../../../../enums/payroll-type";
import { RadioCard } from "../../../../components/radio/card";
import { PayrollDto } from "../../../../dto/payroll-dto";
import { getWithAuth } from "../../../../services/basicService";
import { ScheduledPayrollConfig } from "../../../../types/payroll.type";
import { COMPANY_TYPE } from "../../../../dto/company-dto";
import {
  IPaginatePendingDeliverymanDto,
  IPendingDeliverymanDto,
} from "../../../../dto/pending-deliveryman-dto";
import { Pagination } from "../../../../components/pagination";
import { Copy } from "@phosphor-icons/react";
import { PendingDeliverymanStatus } from "../../../../enums/pending-deliveryman-status.enum";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);

type Props = UseStepsReturn;

// TODO: Recomponentizar esse trem aqui
export function CreatePayroll(props: Props) {
  const params = useParams();

  const company = useCurrentUserStore.getState().user?.company;
  const hasScheduledFlow = company?.scheduledSplitFlow;
  const statusCompany = company?.statusId;

  const { data: messageByPass } = useQuery({
    queryKey: ["message", company?.id],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/payroll/message-bypass/" + company?.id
      );

      return response?.data;
    },
    enabled: !!company?.id,
    staleTime: 60 * 60 * 1000,
    refetchInterval: 5000,
  });

  const hasConciliatedFlow = company?.conciliatedPaymentSplitFlow;

  const [pendingDeliverymenPage, setPendingDeliverymenPage] = useState(1);
  const [copyingAllPendingDeliverymen, setCopyingAllPendingDeliverymen] =
    useState(false);

  if (Number(statusCompany) === CompanyStatus.INACTIVE) {
    throw new Error(" OL inativa. Entre em contato com o suporte!");
  }
  const createPayroll = useCreatePayroll((state) => state.createPayroll);
  const createConciliatedPayroll = useCreatePayroll(
    (state) => state.createConciliatedPayroll
  );
  const payrollLoadingState = useCreatePayroll((state) => state.isGetting);

  const { data: payroll } = useQuery({
    queryKey: [`payroll-${params?.id}`],
    queryFn: async (): Promise<PayrollDto | null> => {
      if (!params?.id) return null;

      const response = await getWithAuth(`/api/v1/payroll/${params.id}`);
      return response?.data as PayrollDto;
    },
  });

  const { data: scheduledConfig } = useQuery({
    queryKey: [`scheduled-config`],
    queryFn: async (): Promise<ScheduledPayrollConfig | null> => {
      if (!company?.id) return null;

      const response = await getWithAuth(
        `/api/v1/payroll/scheduled/dates_from_business_rule/${company.id}`
      );
      return response?.data as ScheduledPayrollConfig;
    },
  });

  const { data: payrollCreating } = useQuery({
    queryKey: [`payroll-creating`],
    queryFn: async (): Promise<boolean> => {
      if (!company?.id) return false;

      const response = await getWithAuth(
        `/api/v1/payroll/creating/${+company?.id}`
      );
      return response?.data;
    },
  });

  const { data: payrollScheduledNumber } = useQuery({
    queryKey: [`payroll-scheduled-confirmed-number`],
    queryFn: async (): Promise<PayrollDto | null> => {
      const response = await getWithAuth(
        `/api/v1/payroll/sheets/${company?.id}?type=${PayrollType.SCHEDULED}&status=${PayrollStatus.CONFIRMED}`
      );
      return response?.data?.totalRecords;
    },
  });

  const { data: pendingDeliverymen, isLoading: isLoadingPendingDeliverymen } =
    useQuery({
      queryKey: ["pending-deliverymen", pendingDeliverymenPage],
      queryFn: async (): Promise<IPaginatePendingDeliverymanDto> => {
        const response = await getWithAuth(
          `/api/v1/pending_deliverymen?page=${pendingDeliverymenPage}&status=${PendingDeliverymanStatus.NEW}`
        );
        return response?.data;
      },
    });

  const today = dayjs().tz("America/Sao_Paulo");
  const startScheduledPayrollCreationPeriod = dayjs(
    scheduledConfig?.correctionEndDate
  );

  const endScheduledPayrollCreationPeriod = dayjs(
    scheduledConfig?.scheduleDeadline
  );

  const havePayrollScheduled: boolean = Number(payrollScheduledNumber) > 0;
  let noticeScheduledMessage: string | null = null;
  let scheduledPayrollCreationAllowed = false;

  const [payrollStatusState, setPayrollStatusState] = useState(false);

  useQuery({
    queryKey: ["payroll-status", company?.id],
    queryFn: async () => {
      try {
        const response = await getWithAuth(
          "/api/v1/payroll/bypass/" + company?.id
        );

        if (response?.status === 200 && response?.data?.key === "bypass") {
          setPayrollStatusState(true);
        } else {
          setPayrollStatusState(false);
        }
      } catch (error) {
        setPayrollStatusState(false);
      }
    },
    enabled: !!company?.id,
    staleTime: 60 * 60 * 1000,
    refetchInterval: 5000,
  });

  if (payrollStatusState) {
    noticeScheduledMessage =
      "O bypass de status de payroll está ativado. Todas as verificações de data foram ignoradas.";
    scheduledPayrollCreationAllowed = true;
  } else {
    if (today.isBefore(startScheduledPayrollCreationPeriod)) {
      noticeScheduledMessage = `Hoje é ${today.format(
        "DD/MM/YYYY"
      )}. O período para realizar a criação de suas folhas de pagamento agendadas é entre os dias ${startScheduledPayrollCreationPeriod
        .add(1, "day")
        .format("DD/MM/YYYY")} e ${endScheduledPayrollCreationPeriod
        .subtract(1, "day")
        .format("DD/MM/YYYY")}.`;
    } else if (
      !havePayrollScheduled &&
      today.isAfter(endScheduledPayrollCreationPeriod)
    ) {
      noticeScheduledMessage = `Infelizmente passou o prazo para criar suas folhas de pagamento, entre em contato com o suporte e regularize seus repasses.`;
    } else if (havePayrollScheduled) {
      noticeScheduledMessage =
        'Somente um agendamento pode ser feito por vez. Neste momento há folhas do tipo agendada criadas que não foram processadas. Verifique em "Folhas agendadas" e "Folhas em processamento".';
    } else if (company?.companyType === COMPANY_TYPE.FRANQUEADO) {
      scheduledPayrollCreationAllowed = true;
      noticeScheduledMessage =
        "A folha de pagamento agendada será criada de acordo com o relatório IFood disponível no momento e não será possível edição.";
    } else {
      noticeScheduledMessage =
        "Esta ferramenta é destinada a franquias com integração. Entre em contato com o suporte para saber mais.";
    }
  }

  const { handleSubmit, register } = useForm<{
    payrollName: string;
    initialDate?: string;
    finalDate?: string;
  }>();

  const toast = useToast({
    duration: 5000,
    isClosable: true,
  });

  const [scheduled, setScheduledType] = useState<boolean>(false);
  const setScheduledPayroll = useCreatePayroll(
    (state) => state.setScheduledFlag
  );
  const setConciliatedFlow = useCreatePayroll(
    (state) => state.setConciliatedFlag
  );

  const {
    isOpen: modalNoticeOpened,
    onClose: closeModalNotice,
    onOpen: openModalNotice,
  } = useDisclosure({
    id: "schedule-notice-modal",
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "payrollType",
    defaultValue: "AVULSA",
    onChange: (key) => {
      setScheduledType(key === "AGENDADA");
    },
  });

  const group = getRootProps();
  const options = ["AVULSA", "AGENDADA"];

  if (hasConciliatedFlow) {
    options.pop();
    options.push("FECHAMENTO AUTOMÁTICO");
  }

  function handleScheduled() {
    closeModalNotice();
    handleCreatePayroll({ payrollName: "Set default name in backend" });
  }

  async function handleCreatePayroll({
    payrollName,
    initialDate,
    finalDate,
  }: {
    payrollName: string;
    initialDate?: string;
    finalDate?: string;
  }) {
    try {
      let type = scheduled ? PayrollType.SCHEDULED : PayrollType.INSTANT;
      const isConciliated = await getRadioProps({
        value: "FECHAMENTO AUTOMÁTICO",
      }).isChecked;

      const reprocessedOptions = payroll
        ? {
            isReprocessingScheduledPayroll: Boolean(payroll?.schedulingDate),
            reprocessedPayrollId: payroll.id,
          }
        : undefined;

      if (scheduled) {
        type = PayrollType.SCHEDULED;
      } else if (isConciliated) {
        type = PayrollType.CONCILIATED;
      }

      if (isConciliated) {
        const response = await createConciliatedPayroll(
          payrollName,
          initialDate || "",
          finalDate || ""
          //   isReprocessingScheduledPayroll
        );
        if (response) {
          setConciliatedFlow(true);
          props.setActiveStep(1);
        }
      } else {
        const response = await createPayroll(
          payrollName,
          type,
          reprocessedOptions
        );
        if (response) {
          setScheduledPayroll(!!scheduled);
          props.setActiveStep(1);
        }
      }

      toast({
        status: "success",
        title: "Folha de pagamento criada com sucesso",
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          title: "Não foi possível criar a folha de pagamento",
          description: error.message,
        });
      }
    }
  }

  async function fetchAllPendingDeliverymen() {
    let allPendingDeliverymen: IPendingDeliverymanDto[] = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const response = await getWithAuth(
        `/api/v1/pending_deliverymen?page=${currentPage}&status=${PendingDeliverymanStatus.NEW}`
      );
      const data: IPaginatePendingDeliverymanDto = response?.data;
      allPendingDeliverymen = allPendingDeliverymen.concat(data.result);
      totalPages = data.totalPages;
      currentPage += 1;
    }

    return allPendingDeliverymen;
  }

  async function copyAllPendingDeliverymen() {
    try {
      setCopyingAllPendingDeliverymen(true);
      const allPendingDeliverymen = await fetchAllPendingDeliverymen();
      const deliverymenIds = allPendingDeliverymen
        .map((deliveryman) => deliveryman.deliverymanExternalId)
        .join(", ");
      navigator.clipboard.writeText(deliverymenIds);

      toast({
        title: "IDs copiados!",
        description: "Os IDs dos entregadores pendentes foram copiados.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao copiar IDs",
        description:
          "Não foi possível copiar os IDs dos entregadores pendentes.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setCopyingAllPendingDeliverymen(false);
    }
  }

  return (
    <VStack
      as="form"
      w="full"
      gap={8}
      onSubmit={handleSubmit(handleCreatePayroll)}
    >
      <Modal
        isOpen={modalNoticeOpened}
        onClose={closeModalNotice}
        id="confirm-transactions-modal"
        size="xl"
        isCentered
      >
        <ModalOverlay />
        {pendingDeliverymen && pendingDeliverymen.totalRecords === 0 ? (
          <ModalContent>
            <ModalHeader color="red">ATENÇÃO!</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={8}
            >
              {noticeScheduledMessage && !payrollCreating ? (
                <Text fontSize="large" textAlign="justify">
                  {noticeScheduledMessage}
                </Text>
              ) : (
                <>
                  <VStack gap={2}>
                    <Text fontSize="large" textAlign="left">
                      Sua folha está sendo criada, por favor aguarde.
                    </Text>
                    <Text fontSize="large" textAlign="center">
                      Estará disponível logo mais, na aba de folhas agendadas.
                    </Text>
                  </VStack>
                </>
              )}
            </ModalBody>

            <ModalFooter my={4}>
              {scheduledPayrollCreationAllowed && !payrollCreating ? (
                <>
                  <Button variant="ghost" mr={3} onClick={closeModalNotice}>
                    Cancelar
                  </Button>
                  <Button color="white" onClick={handleScheduled}>
                    Criar folha agendada
                  </Button>
                </>
              ) : (
                <Button variant="ghost" mr={3} onClick={closeModalNotice}>
                  Fechar
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent minWidth={"50%"}>
            <ModalHeader color="red">ATENÇÃO!</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              alignItems="center"
              flexDir="column"
              justifyContent="center"
              py={8}
            >
              <Text fontSize="large" textAlign="justify" flexDir={"column"}>
                {
                  ">> Profissionais de entrega com cadastro pendente não entra na folha de pagamento. Por favor, entre em contato com o suporte e forneça a lista de ids abaixo para orientação."
                }
              </Text>

              <Accordion
                allowMultiple
                allowToggle
                minWidth={"100%"}
                paddingTop={35}
              >
                <AccordionItem>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="full"
                  >
                    <AccordionButton flex={1} flexDir={"row"}>
                      <Box>
                        <Text
                          sx={{
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          Lista profissionais de entrega com cadastro pendente
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <Button
                      size="sm"
                      colorScheme="green"
                      mr={2}
                      rightIcon={<Copy />}
                      isLoading={copyingAllPendingDeliverymen}
                      onClick={copyAllPendingDeliverymen}
                    >
                      Copiar
                    </Button>
                  </Flex>
                  <AccordionPanel>
                    <TableContainer
                      w="full"
                      p={4}
                      gap={4}
                      display="flex"
                      flexDir="column"
                      style={{
                        containerType: "inline-size",
                      }}
                    >
                      <Table>
                        <Thead>
                          <Tr>
                            <Th w="fit-content">ID da pessoa entregadora</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {pendingDeliverymen?.result?.map(
                            (pendingDeliveryman) => (
                              <Tr
                                key={pendingDeliveryman.deliverymanExternalId}
                              >
                                <Td>
                                  {pendingDeliveryman.deliverymanExternalId}
                                </Td>
                              </Tr>
                            )
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <HStack gap={8} pt={8} justifyContent="flex-end">
                      <Pagination
                        page={pendingDeliverymenPage}
                        setPage={setPendingDeliverymenPage}
                        totalPages={
                          pendingDeliverymen?.totalPages
                            ? pendingDeliverymen?.totalPages
                            : 1
                        }
                      />
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </ModalBody>

            <ModalBody
              display="flex"
              alignItems="center"
              flexDir="column"
              justifyContent="center"
              py={8}
            >
              {noticeScheduledMessage && !payrollCreating ? (
                <Text fontSize="large" textAlign="justify">
                  {noticeScheduledMessage}
                </Text>
              ) : (
                <>
                  <Text fontSize="large" textAlign="justify">
                    Sua folha está sendo criada, por favor aguarde.
                  </Text>
                  <Text fontSize="large" textAlign="justify">
                    Estará disponível logo mais, na aba de folhas agendadas.
                  </Text>
                </>
              )}
            </ModalBody>

            <ModalFooter my={4}>
              {scheduledPayrollCreationAllowed && !payrollCreating ? (
                <>
                  <Button variant="ghost" mr={3} onClick={closeModalNotice}>
                    Cancelar
                  </Button>
                  <Button color="white" onClick={handleScheduled}>
                    Criar folha agendada
                  </Button>
                </>
              ) : (
                <Button variant="ghost" mr={3} onClick={closeModalNotice}>
                  Fechar
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
      <FormControl display="flex" flexDir="column" alignItems="center">
        {(hasScheduledFlow || hasConciliatedFlow) && (
          <>
            <FormLabel marginTop={4} marginBottom={4}>
              Qual o tipo da folha que deseja criar?
            </FormLabel>
            <HStack {...group}>
              {options.map((value) => {
                const radio = getRadioProps({ value });
                return (
                  <RadioCard key={value} {...radio}>
                    {value}
                  </RadioCard>
                );
              })}
            </HStack>
          </>
        )}
        {scheduled ? (
          <>
            <Box
              fontSize="small"
              mt={8}
              borderRadius={8}
              p={8}
              border="1px"
              borderColor="gray.300"
              height="auto"
            >
              {messageByPass ? (
                <Text>
                  Criação de folhas de pagamento agendadas aberta em caráter de
                  exceção.
                </Text>
              ) : scheduledConfig ? (
                <>
                  <Text>Período vigente: {scheduledConfig?.workPeriodKey}</Text>
                  <Text>
                    Dias para criar folhas agendadas:{" "}
                    {startScheduledPayrollCreationPeriod
                      .add(1, "day")
                      .format("DD/MM")}{" "}
                    à {endScheduledPayrollCreationPeriod.format("DD/MM")} até{" "}
                    {endScheduledPayrollCreationPeriod.hour()}h
                  </Text>
                  <Text>
                    Dias para confirmar as folhas agendadas:{" "}
                    {startScheduledPayrollCreationPeriod
                      .add(1, "day")
                      .format("DD/MM")}{" "}
                    à {endScheduledPayrollCreationPeriod.format("DD/MM")} até{" "}
                    {endScheduledPayrollCreationPeriod.hour()}h
                  </Text>
                  <Text>
                    Repasse das folhas agendadas confirmadas iniciam após:{" "}
                    {dayjs(scheduledConfig?.transferStartDate)
                      .tz("America/Sao_Paulo")
                      .add(3, "hours")
                      .format("DD/MM/YYYY")}
                  </Text>
                  {pendingDeliverymen &&
                    pendingDeliverymen.totalRecords > 0 && (
                      <Text color="red.600">
                        Entregadores não cadastrados:{" "}
                        {pendingDeliverymen.totalRecords}
                      </Text>
                    )}
                </>
              ) : (
                <Text fontSize="medium">
                  Não foi encontrado uma configuração de agendamento. Entre em
                  contato com o suporte.
                </Text>
              )}
            </Box>
            <Button
              marginTop={8}
              loadingText="Criando folha"
              size="lg"
              w={["full", "auto"]}
              color="white"
              isLoading={payrollLoadingState}
              isDisabled={payrollLoadingState || isLoadingPendingDeliverymen}
              onClick={openModalNotice}
            >
              Continuar
            </Button>
          </>
        ) : (
          <>
            <FormLabel marginTop={8} marginBottom={4}>
              Qual o nome dessa folha de pagamento?
            </FormLabel>
            <Input
              placeholder="Insira aqui o nome para esse pagamento"
              bg="gray.100"
              maxW="3xl"
              {...register("payrollName")}
            />
            {getRadioProps({ value: "FECHAMENTO AUTOMÁTICO" }).isChecked && (
              <Flex
                mt={8}
                width={"100%"}
                justifyContent="space-between"
                gap={8}
              >
                <Stack width={"50%"}>
                  <FormLabel marginTop={8} marginBottom={4}>
                    Data inicial
                  </FormLabel>
                  <Input
                    type="date"
                    bg="gray.100"
                    maxW="3xl"
                    {...register("initialDate")}
                  />
                </Stack>
                <Stack width={"50%"}>
                  <FormLabel marginTop={8} marginBottom={4}>
                    Data final
                  </FormLabel>
                  <Input
                    type="date"
                    bg="gray.100"
                    maxW="3xl"
                    {...register("finalDate")}
                  />
                </Stack>
              </Flex>
            )}
            <Button
              marginTop={8}
              loadingText="Criando folha"
              size="lg"
              w={["full", "auto"]}
              color="white"
              type="submit"
              isLoading={payrollLoadingState}
              isDisabled={payrollLoadingState}
            >
              Criar folha{" "}
              {getRadioProps({ value: "AVULSA" }).isChecked
                ? "avulsa"
                : "conciliada"}
            </Button>
          </>
        )}
      </FormControl>
    </VStack>
  );
}
