import { createContext, useState, ReactNode, useContext } from "react";
import { postWithAuth } from "../../../../../services/basicService";
import { useToast } from "@chakra-ui/react";

type PointsContextType = {
  companyId: number;
  setCompanyId: (companyId: number) => void;
  isLoading: boolean;
  refetchPoints: () => void;
  image: File | null;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any, resetForm: () => void) => void;
};

export const PointsContext = createContext<PointsContextType>(
  {} as PointsContextType
);

export const PointsProvider = ({ children }: { children: ReactNode }) => {
  const [companyId, setCompanyId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const toast = useToast();

  const refetchPoints = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const removeImage = () => {
    setImage(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any, resetForm: () => void) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("value", data.points);
    formData.append("category", data.category);

    if (image) {
      formData.append("file", image);
    }

    if (!image) {
      return toast({
        title: "Erro ao enviar o formulário",
        description: "Por favor, selecione uma imagem para o premio",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setIsLoading(true);
    try {
      await postWithAuth("/api/v1/trampoints/create-gift", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast({
        title: "Sucesso",
        description: "Premio criado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Erro desconhecido. Por favor, tente novamente mais tarde.";

      toast({
        title: "Erro ao enviar o formulário",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      console.error("Erro ao enviar o formulário:", error);
    } finally {
      resetForm();
      setIsLoading(false);
    }
  };

  return (
    <PointsContext.Provider
      value={{
        companyId,
        setCompanyId,
        isLoading,
        refetchPoints,
        image,
        handleImageChange,
        removeImage,
        onSubmit,
      }}
    >
      {children}
    </PointsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const usePointsContext = () => useContext(PointsContext);
