/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text } from "@chakra-ui/layout";
import { Link, Outlet, useNavigate, useNavigation } from "react-router-dom";
import { SideBar } from "../components/sidebar/support";
import { LoadingLogo } from "../components/loading";
import {
  Avatar,
  Button,
  Spinner,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { accountInfo } from "../types/bank-info.type";
import { UserStore, useCurrentUserStore } from "../store/current-user.store";
import { useQuery } from "@tanstack/react-query";
import { Plus, User, Wallet } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { convertCentsToReais } from "../utils/convert-money";
import { getWithAuth } from "../services/basicService";
import { GetDuplicatedAccountDto } from "../types/get-duplicated-account.type";
import { useEffect } from "react";
import DuplicatedAccountModal from "./alert-modals/duplicated-account.modal";
import { GetDebtsWithoutPaymentDto } from "../types/get-debts-without-payment.type";
import DebtWithoutPayment from "./alert-modals/debts-without-payment.modal";

export function RootLayout() {
  const { state } = useNavigation();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const user = useCurrentUserStore((state: UserStore) => state.user);

  const { data: bankInfo } = useQuery({
    queryKey: ["advanceAccountInfo"],
    queryFn: async (): Promise<accountInfo> => {
      const response = await getWithAuth("/api/v1/companies/accounts/advance");
      return response?.data as accountInfo;
    },
    refetchOnWindowFocus: true,
  });

  const { data: statusAdvance, isLoading } = useQuery({
    queryKey: ["advance-status"],
    queryFn: async () => {
      const status = await getWithAuth(
        "/api/v1/configuration/adiantamento-status"
      );

      return /true/.test(status?.data.value);
    },
    refetchOnWindowFocus: true,
  });

  const { data } = useQuery({
    queryKey: [`divergent-account`],
    queryFn: async () => {
      const page = 1;
      const limit = 10;
      const PATH = `/api/v1/webhook/divergent-accounts?limit=${limit}&page=${page}&`;

      const res = await getWithAuth(PATH);

      return res?.data as GetDuplicatedAccountDto;
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure({ id: "modal-duplicate" });

  const { data: debts } = useQuery({
    queryKey: [`debts-without-payment`],
    queryFn: async () => {
      const page = 1;
      const limit = 10;
      const PATH = `/api/v1/debt/not-paid?limit=${limit}&page=${page}&`;

      const res = await getWithAuth(PATH);

      return res?.data as GetDebtsWithoutPaymentDto;
    },
  });

  const {
    isOpen: isOpenDebt,
    onOpen: onOpenDebt,
    onClose: onCloseDebt,
  } = useDisclosure({ id: "modal-debt" });

  useEffect(() => {
    if (data?.totalRecords && data?.totalRecords > 0) {
      onOpen();
    }
    if (debts?.totalRecords && debts?.totalRecords > 0) {
      onOpenDebt();
    }
  }, [data?.totalRecords, debts?.totalRecords]);

  const navigate = useNavigate();

  return (
    <Flex
      as="main"
      minH="100dvh"
      flex={1}
      bg="gray.100"
      position="relative"
      flexDir={isLargerThan800 ? "row" : "column"}
      style={{
        containerType: "inline-size",
      }}
    >
      <SideBar />
      <Flex w="full" flexDir="column">
        {isLargerThan800 ? (
          <Flex
            as="nav"
            flex={1}
            px={16}
            py={3}
            gap={6}
            bg="blackAlpha.900"
            justifyContent="flex-end"
            alignItems="center"
            shadow="md"
            maxH="60px"
          >
            <Flex
              as={motion.div}
              bg="whiteAlpha.400"
              borderRadius={4}
              p={2}
              lineHeight={1}
              textAlign="center"
              fontSize="xl"
              color="whiteAlpha.900"
              gap={2}
            >
              <Text>Adiantamento:</Text>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {statusAdvance ? (
                    <Flex gap={2} alignItems="center">
                      <Text color="green.300" fontWeight="bold">
                        aberto
                      </Text>
                    </Flex>
                  ) : (
                    <Flex gap={2} alignItems="center">
                      <Text color="red.300" fontWeight="bold">
                        fechado
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
            <Flex
              as={motion.div}
              bg="whiteAlpha.400"
              borderRadius={4}
              p={2}
              lineHeight={1}
              textAlign="center"
              fontSize="xl"
              color="whiteAlpha.900"
              gap={2}
            >
              <Wallet size={20} />
              {bankInfo?.availableBalanceInCents
                ? convertCentsToReais(bankInfo?.availableBalanceInCents)
                : convertCentsToReais(0)}
            </Flex>
            <Button
              as={Link}
              to="/settings/balance"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={2}
              size="md"
              gap={2}
            >
              <Plus weight="bold" size={16} />
            </Button>
            <Avatar
              border="1px"
              borderColor="whiteAlpha.900"
              name={user?.firstName + " " + user?.lastName}
              icon={<User size={24} />}
              onClick={() => navigate("/settings/perfil")}
              cursor="pointer"
              _hover={{
                borderWidth: "2px",
              }}
            />
          </Flex>
        ) : null}
        <Flex w="full" height="full" flex={1} p={4} overflowX="auto">
          {state === "loading" ? (
            <>
              <LoadingLogo />
            </>
          ) : (
            <Outlet />
          )}
        </Flex>
      </Flex>
      <DuplicatedAccountModal
        isOpen={isOpen}
        onClose={onClose}
        total={data?.totalRecords}
      />
      <DebtWithoutPayment
        isOpen={isOpenDebt}
        onClose={onCloseDebt}
        total={debts?.totalRecords}
      />
    </Flex>
  );
}
