import {
  VStack,
  Button,
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Switch,
  Select,
  Text,
  FormLabel,
  useToast,
  FormErrorMessage,
  FormControl,
  NumberInputField,
  NumberInput,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { AxiosError } from "axios";
import {
  getWithAuth,
  patchWithAuth,
} from "../../../../../services/basicService";
import { IRedemptionCriteriesDto } from "../../../../../dto/trampoints-dto";
import { AxiosDataError } from "../../../../../dto/axios-error-dto";

const updateRuleSchema = z.object({
  id: z.string().nonempty("Selecione uma ação para atualizar"),
  points: z
    .number()
    .min(1, "O valor em pontos deve ser maior que zero")
    .max(10000, "O valor em pontos não pode exceder 10.000"),
});

export type UpdateRuleFormInputs = z.infer<typeof updateRuleSchema>;

const updateStatusSchema = z.object({
  id: z.number(),
  isActive: z.boolean(),
});

export type UpdateStatusInputs = z.infer<typeof updateStatusSchema>;

export function RulesManagementForm() {
  const toast = useToast();
  const client = useQueryClient();

  const { data: rules } = useQuery({
    queryKey: ["all-rules"],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/trampoints/redemptions-criteries"
      );
      return response?.data as IRedemptionCriteriesDto[];
    },
  });

  const updatePoints = useForm<UpdateRuleFormInputs>({
    resolver: zodResolver(updateRuleSchema),
  });

  const pointsMutation = useMutation({
    mutationFn: (data: UpdateRuleFormInputs) =>
      patchWithAuth(`/api/v1/trampoints/redemption-criteries/${data.id}`, {
        points: data.points,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Sucesso",
        description: `Pontos alterados com sucesso`,
      });

      client.invalidateQueries(["all-rules"]);
      updatePoints.reset();
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao atualizar pontuação",
        description: `Erro! ${error.message}`,
      });
    },
  });

  const statusMutation = useMutation({
    mutationFn: (data: UpdateStatusInputs) =>
      patchWithAuth(`/api/v1/trampoints/redemption-criteries/${data.id}`, {
        isActive: data.isActive,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Sucesso",
        description: `Status alterado com sucesso`,
      });

      client.invalidateQueries(["all-rules"]);
      updatePoints.reset();
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao atualizar status",
        description: `Erro! ${error.message}`,
      });
    },
  });

  const handleUpdatePoints = (data: UpdateRuleFormInputs) => {
    if (pointsMutation.isLoading) return;

    pointsMutation.mutate(data);
  };

  const handleUpdateStatus = (data: UpdateStatusInputs) => {
    if (statusMutation.isLoading) return;

    statusMutation.mutate(data);
  };

  return (
    <Flex w="full" h="full">
      <VStack spacing={8} align="stretch" w="full">
        <Box
          as="form"
          onSubmit={updatePoints.handleSubmit(handleUpdatePoints)}
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          w="full"
        >
          <Flex mb={4}>
            <Box mr={4} w="full">
              <FormControl
                as="fieldset"
                isInvalid={!!updatePoints.formState.errors.id}
              >
                <FormLabel>Ação</FormLabel>
                <Select
                  as={Select}
                  placeholder="Selecione uma ação"
                  {...updatePoints.register("id")}
                >
                  {rules?.map((rule) => (
                    <option key={rule.id} value={rule.id}>
                      {rule.name}
                    </option>
                  ))}
                </Select>
                {updatePoints.formState.errors.id ? (
                  <FormErrorMessage>
                    {updatePoints.formState.errors.id.message}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
            </Box>

            <FormControl
              as="fieldset"
              isInvalid={!!updatePoints.formState.errors.points}
            >
              <FormLabel as="legend">Pontuação</FormLabel>
              <NumberInput>
                <NumberInputField
                  {...updatePoints.register("points", { valueAsNumber: true })}
                  bg={"gray.100"}
                  fontWeight="bold"
                  color={"gray.700"}
                  placeholder="Digite o número de pontos"
                  _placeholder={{
                    color: "gray.500",
                  }}
                />
                {updatePoints.formState.errors.points ? (
                  <FormErrorMessage>
                    {updatePoints.formState.errors.points.message}
                  </FormErrorMessage>
                ) : null}
              </NumberInput>
            </FormControl>
          </Flex>

          <Flex justify="flex-end" mt={4}>
            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </Flex>
        </Box>

        <Box
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          w="full"
        >
          <Text fontSize="lg" mb={4}>
            Regras Cadastradas
          </Text>
          <Table colorScheme="green">
            <Thead>
              <Tr>
                <Th>Ação</Th>
                <Th textAlign="center">Valor em Pontos</Th>
                <Th textAlign="center">Data do Cadastro</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {rules?.map((rule) => (
                <Tr key={rule.id}>
                  <Td>{rule.name}</Td>
                  <Td textAlign="center">{rule.points}</Td>
                  <Td textAlign="center">
                    {dayjs(rule.createdAt).format("DD/MM/YYYY")}
                  </Td>
                  <Td>
                    <Switch
                      isChecked={rule.isActive}
                      onChange={() => {
                        handleUpdateStatus({
                          id: rule.id,
                          isActive: !rule.isActive,
                        });
                      }}
                      colorScheme="green"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </Flex>
  );
}
