import { useState } from "react";
import { getWithAuth } from "../../services/basicService";
import { useToast } from "@chakra-ui/react";

export function useExportCsv() {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const exportCsv = (endpoint: string, fileName: string, queries: string) => {
    if (loading) return;
    setLoading(true);

    endpoint += `?${queries}`;

    getWithAuth(
      endpoint,
      {
        "Content-Type": "text/csv",
      },
      { responseType: "arraybuffer" as ResponseType, timeout: 0 }
    )
      .then((res) => {
        const csvBlob = new Blob([res?.data], { type: "text/csv" });
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.csv`);
        link.click();

        URL.revokeObjectURL(url);

        toast({
          title: "CSV gerado com sucesso",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return { exportCsv, loading };
}
