import {
  Box,
  VStack,
  IconButton,
  Text,
  Flex,
  Tooltip,
  FormControl,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Image,
  useDisclosure,
  Button,
  Spinner,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import {
  DotsThreeVertical,
  DownloadSimple,
  MagnifyingGlass,
  Question,
  X,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../services/basicService";
import dayjs from "dayjs";
import { formatCPF } from "../../../../utils/format-document";
import { convertCentsToReais } from "../../../../utils/convert-money";
import { useState } from "react";
import { Pagination } from "../../../../components-ui/pagination";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import EmptySearchIcon from "../../../../assets/empty-search-icon.svg";
import {
  ComposableTable,
  ComposableTableBody,
  ComposableTableCell,
  ComposableTableHead,
  ComposableTableHeaderCell,
  ComposableTableRow,
} from "../../../../components-ui/table";
import StatusBadge from "./status-badge";
import DetailsModal from "./details-modal";
import { useCustomToast } from "./toast";
import {
  DivergenceLog,
  GetDuplicatedAccountDto,
} from "../../../../types/get-duplicated-account.type";
import ChangeAccountModal from "./change-account-modal";

const formSchema = z.object({
  nameOrDocument: z.string().optional(),
  document: z.string().optional(),
  createdAt: z.date().optional(),
  transactionDate: z.date().optional(),
  debtDate: z.date().optional(),
  withDebtReversal: z.string().optional(),
});

export type FilterFormInputsValidation = z.infer<typeof formSchema>;

export function DuplicatedAccount() {
  const { showCustomToast } = useCustomToast();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    isOpen: isOpenDetails,
    onOpen: openDetails,
    onClose: closeDetails,
  } = useDisclosure({ id: "details" });
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure({ id: "modal-details" });

  const [paramsToDetails, setParamsToDetails] = useState({
    logId: 0,
    debtId: 0,
  });

  const {
    register,
    getValues,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FilterFormInputsValidation>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nameOrDocument: "",
    },
  });

  const queryParams = () => {
    let query = "";

    const formValues = getValues();

    for (const [key, value] of Object.entries(formValues)) {
      if (value) {
        query += `${key}=${value}&`;
      }
    }

    return query;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [`divergent-account`, page, limit],
    queryFn: async () => {
      let PATH = `/api/v1/webhook/divergent-accounts?limit=${limit}&page=${page}&`;
      PATH += queryParams();
      const res = await getWithAuth(PATH);

      return res?.data as GetDuplicatedAccountDto;
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (_: FilterFormInputsValidation) => refetch();

  const [isLoadingExport, setIsloadingExport] = useState(false);

  const exportDataCSV = () => {
    setIsloadingExport(true);
    if (!data?.data || data?.data.length === 0) {
      showCustomToast("Sem dados para exportar", "error");
      console.log("Nenhum dado para exportar.");
      setIsloadingExport(false);
      return;
    }

    const headers = Object.keys(data?.data[0]);
    const csvRows = [headers.join(",")];

    data?.data?.forEach((item) => {
      const values = headers.map((header) => {
        let cell =
          (item as Record<string, unknown>)[header] !== null &&
          (item as Record<string, unknown>)[header] !== undefined
            ? (item as Record<string, unknown>)[header]?.toString()
            : "";
        cell = cell?.replace(/"/g, '""');
        return `"${cell}"`;
      });
      csvRows.push(values.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "duplicatas.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsloadingExport(false);
  };

  return (
    <VStack
      gap={6}
      w="full"
      alignItems="flex-start"
      marginTop={6}
      h="calc(100dvh - 183px)"
    >
      <Flex
        bg="white"
        w="full"
        h="full"
        flexDirection="column"
        overflowY="auto"
        overflowX="auto"
        borderRadius="2xl"
      >
        {/* Cards de valores */}
        <Flex
          w="full"
          py={4}
          px={12}
          bg="#FBFBFB"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="3xl"
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
        >
          <Flex gap={8}>
            <Box
              bg="white"
              border="2px solid"
              borderColor="#E2E2E0"
              borderRadius="md"
              py={2}
              px={6}
              background={"var(--Red-50, #FDF4F4)"}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                fontWeight="extrabold"
              >
                Valor total de Débitos
              </Text>
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                color="#D73030"
                fontWeight="bold"
              >
                {isLoading
                  ? "R$ 0,00"
                  : convertCentsToReais(
                      data?.data.reduce((a, b) => a + (b?.debtValue ?? 0), 0) ||
                        0
                    )}
              </Text>
            </Box>
          </Flex>

          <Flex gap={4} alignItems="center" w="max-content">
            <Button
              w="fit-content"
              alignItems="center"
              px={7}
              py={2}
              display="flex"
              justifyContent="center"
              gap={3}
              bg="white"
              border="1px"
              borderColor="black"
              borderRadius="3xl"
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              isLoading={isLoadingExport}
              loadingText="Exportando"
              disabled={isLoadingExport}
              onClick={() => {
                exportDataCSV();
              }}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontWeight="extrabold"
                fontSize="medium"
                color="#0B0B0B"
              >
                Exportar .CSV
              </Text>
              <DownloadSimple size={25} weight="bold" color="black" />
            </Button>

            <Tooltip
              hasArrow
              placement="top"
              textAlign="center"
              fontSize="md"
              label="Os dados exportados serão baseados no filtro dessa página."
              borderRadius="md"
            >
              <Question size={20} color="#718096" cursor="pointer" />
            </Tooltip>

            <Box
              as="form"
              display="flex"
              flexDir="column"
              gap={8}
              w="fit-content"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={!!errors.nameOrDocument}>
                <InputGroup display="flex" alignItems="center">
                  <InputLeftElement
                    h="full"
                    display="flex"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => refetch()}
                  >
                    <IconButton
                      as="button"
                      aria-label="Ver detalhes"
                      bg="-moz-initial"
                      icon={
                        <MagnifyingGlass
                          size={16}
                          color="#6f6f6f"
                          weight="bold"
                        />
                      }
                      size="md"
                      variant="ghost"
                      type="submit"
                    />
                  </InputLeftElement>
                  <Input
                    id="nameOrDocument"
                    w={350}
                    size="lg"
                    bg="white"
                    _placeholder={{ color: "gray.500" }}
                    {...register("nameOrDocument")}
                    title="nameOrDocument"
                    placeholder="Buscar por nome"
                  />
                  {(watch("nameOrDocument")?.length || 0) > 0 && (
                    <InputRightElement
                      h="full"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                      onClick={() => {
                        reset();
                        refetch();
                      }}
                    >
                      <X size={20} />
                    </InputRightElement>
                  )}
                </InputGroup>
                <FormErrorMessage>
                  {errors.nameOrDocument?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>

            {isOpenDetails && (
              <DetailsModal
                isOpen={isOpenDetails}
                onClose={closeDetails}
                logId={paramsToDetails.logId}
                debtId={paramsToDetails.debtId}
              />
            )}
          </Flex>
        </Flex>

        {/* Tabela */}
        {!isLoading && data?.totalRecords === 0 ? (
          <Flex
            w="full"
            h="full"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Image
              src={EmptySearchIcon}
              alt="Lupa de busca"
              objectFit="cover"
              loading="eager"
            />
            <Text
              fontFamily="Source Sans 3, sans-serif"
              fontWeight="semibold"
              fontSize="xl"
            >
              Nenhum resultado encontrado...
            </Text>
          </Flex>
        ) : !isLoading ? (
          <ComposableTable>
            <ComposableTableHead>
              <ComposableTableHeaderCell>ID débito</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Nome</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Documento</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Valor transação
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Data transação
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Data vencimento
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Empresa</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Data entrada
              </ComposableTableHeaderCell>
              {/*          <ComposableTableHeaderCell>
                Vendido para
              </ComposableTableHeaderCell> */}

              <ComposableTableHeaderCell>Status</ComposableTableHeaderCell>
              <ComposableTableHeaderCell _after={{ border: "none" }}>
                Ações
              </ComposableTableHeaderCell>
            </ComposableTableHead>
            <ComposableTableBody>
              {data?.data?.map((item, index) => (
                <ComposableTableRow key={item?.id} isStriped={index % 2 !== 0}>
                  <ComposableTableCell>{item?.debtId}</ComposableTableCell>
                  <ComposableTableCell>{item?.userName}</ComposableTableCell>
                  <ComposableTableCell>
                    {item?.document && formatCPF(item.document)}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    <Flex alignItems="center" justifyContent="center" gap={1}>
                      {" R$ " + (item.debtValue || 0) / 100}
                    </Flex>
                  </ComposableTableCell>
                  <ComposableTableCell>
                    {item.paymentDate &&
                      dayjs(item.paymentDate).format("DD/MM/YYYY")}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    {item.dueDate && dayjs(item.dueDate).format("DD/MM/YYYY")}
                  </ComposableTableCell>
                  <ComposableTableCell>{item?.companyName}</ComposableTableCell>
                  <ComposableTableCell>
                    {item.createdAt &&
                      dayjs(item.createdAt).format("DD/MM/YYYY")}
                  </ComposableTableCell>
                  {/*   <ComposableTableCell>Trampay</ComposableTableCell> */}
                  <ComposableTableCell>
                    <StatusBadge
                      status={item?.status || DivergenceLog.PENDING}
                    />
                  </ComposableTableCell>

                  <ComposableTableCell _after={{ border: "none", padding: 0 }}>
                    <Popover
                      placement="bottom-end"
                      trigger="click"
                      closeOnBlur={true}
                    >
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Ver detalhes"
                          bg="-moz-initial"
                          icon={
                            <DotsThreeVertical
                              size={22}
                              color="#6f6f6f"
                              weight="bold"
                            />
                          }
                          size="md"
                          variant="ghost"
                        />
                      </PopoverTrigger>
                      <PopoverContent
                        bg="white"
                        border="1px solid var(--Neutral-200, #E2E2E0)"
                        boxShadow="0px 1px 4px 0px rgba(144, 144, 144, 0.25)"
                        borderRadius={"0.5rem"}
                        p="2"
                        w="auto"
                        display={"flex"}
                        flexDir={"column"}
                        alignItems="flex-start"
                        padding={"0.875rem"}
                      >
                        <Flex flexDirection="column" gap={"0.75rem"}>
                          <Flex
                            flexDirection="column"
                            gap={"0.5rem"}
                            padding={"0.25rem 0.5rem"}
                            onClick={() => {
                              setParamsToDetails({
                                logId: Number(item?.id),
                                debtId: item?.debtId || 0,
                              });
                              openDetails();
                            }}
                            cursor={"pointer"}
                          >
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              textAlign={"start"}
                            >
                              Detalhes
                            </Text>
                          </Flex>
                          {item?.status === DivergenceLog.PENDING && (
                            <Flex
                              flexDirection="column"
                              gap={"0.5rem"}
                              padding={"0.25rem 0.5rem"}
                              justifyContent={"start"}
                              alignContent={"Center"}
                              cursor={"pointer"}
                              onClick={() => {
                                setParamsToDetails({
                                  logId: Number(item?.id),
                                  debtId: item?.debtId || 0,
                                });
                                openModal();
                              }}
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="semibold"
                                textAlign={"start"}
                              >
                                Trocar conta
                              </Text>
                            </Flex>
                          )}
                        </Flex>
                      </PopoverContent>
                    </Popover>
                  </ComposableTableCell>
                </ComposableTableRow>
              ))}
            </ComposableTableBody>
          </ComposableTable>
        ) : (
          <Flex w="full" h="full" alignItems="center" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
      </Flex>
      {isModalOpen && (
        <ChangeAccountModal
          isOpen={isModalOpen}
          onClose={closeModal}
          logId={paramsToDetails.logId}
          debtId={paramsToDetails.debtId}
          refetch={refetch}
        />
      )}
      {/* Paginação */}
      {!isLoading && (data?.totalRecords || 0) > 0 && (
        <Pagination
          currentPage={page}
          totalPages={data?.totalPages ?? 0}
          itemsPerPage={limit}
          setPage={setPage}
          setItemsPerPage={setLimit}
        />
      )}
    </VStack>
  );
}
