/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useRef, useState, useEffect } from "react";
import {
  VStack,
  Heading,
  Flex,
  Box,
  Badge,
  Stack,
  UnorderedList,
  ListItem,
  Text,
} from "@chakra-ui/layout";
import { DragDrop, IRef } from "../../../../components/forms/fileUpload";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Table,
  Td,
  NumberInput,
  NumberInputField,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  UseStepsReturn,
  FormControl,
  FormLabel,
  Select,
  Tooltip,
  FormErrorMessage,
  FormErrorIcon,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { convertCentsToReais } from "../../../../utils/convert-money";
import {
  FloppyDiskBack,
  Info,
  Plus,
  Trash,
  Question,
  WarningOctagon,
} from "@phosphor-icons/react";
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { useCreatePayroll } from "../../../../store/payroll.store";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { AxiosError } from "axios";
import { useDebounce } from "../../../../hooks/enterprises/use-debounce";
import { TestCPF } from "../../../../utils/cpf-validator";
import { TestCNPJ } from "../../../../utils/cnpj-validator";
import { PermissionsEnum } from "../../../../enums/permissions.enum";
import { UploadPayrollResponseErrors } from "../../../../types/upload.type";
import { useGetPayroll } from "../../../../hooks/payments/use-get-payroll";
import { PayrollType } from "../../../../enums/payroll-type";
import { PixKeyType } from "../../../../enums/pix-type";
import {
  TransactionDraftValidationResponse,
  ValidatedTransactionDraft,
} from "../../../../types/transaction.type";
import dayjs from "dayjs";

type TransactionDraftInputs = {
  document?: string;
  name?: string;
  description?: string;
  pix_type?: PixKeyType | "";
  pix_key?: string;
  amount?: number;
  status?: string;
  pixErrorMessage?: string;
  discount?: number;
};

type PaymentInputs = {
  payrollId?: number;
  isToValidatePixDocument: string;
  payment: TransactionDraftInputs[];
};
type EditPayrollInfoInputs = {
  name: string;
  conciliatedPeriodEnd?: Date | null;
  conciliatedPeriodStart?: Date | null;
};

interface UploadResult {
  document: string;
  name: string;
  description: string;
  pix_key: string;
  amountInCents: number;
}

type UploadResponse = {
  result: UploadResult[];
  hasError: boolean;
  errors: UploadPayrollResponseErrors;
};

type Props = UseStepsReturn;

export enum ValidationStatus {
  VALIDATED = "validated",
  MISSING_INFORMATION = "missing information",
  WRONG_AMOUNT = "wrong amount",
  MISSING_PIX_INFO = "missing pix info",
  INVALID_PIX = "invalid pix",
}

export const ValidationStatusMessageMapper = {
  [ValidationStatus.VALIDATED]: "Validado",
  [ValidationStatus.MISSING_INFORMATION]: "Faltando informações",
  [ValidationStatus.WRONG_AMOUNT]: "Valor inválido",
  [ValidationStatus.MISSING_PIX_INFO]: "Faltando informação de pix",
  [ValidationStatus.INVALID_PIX]: "Pix inválido",
};

// TODO: refactor this component. It's too big
export function AddConciliatedPayrollTransactions(props: Props) {
  const [uploading, setUploading] = useState(false);
  const [isLoadingPix, setIsLoadingPix] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [uploadErrors, setUploadErrors] =
    useState<UploadPayrollResponseErrors | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const debouncedSearch = useDebounce<string | undefined>(searchFilter, 200);
  const userPermissions = useCurrentUserStore((state) => state.permissions);
  const saveDraft = useCreatePayroll((state) => state.savePayrollDraft);
  const saveConciliatedDraft = useCreatePayroll(
    (state) => state.saveConciliatedDraft
  );
  const updatePayroll = useCreatePayroll((state) => state.updatePayroll);
  const syncPixFromTransactionDrafts = useCreatePayroll(
    (state) => state.syncPixFromTransactionDrafts
  );
  const validatePayroll = useCreatePayroll((state) => state.validatePayroll);
  const validationResponse = useCreatePayroll(
    (state) => state.transactionValidated
  );

  const changePayrollStatus = useCreatePayroll(
    (state) => state.changePayrollStatus
  );

  const paymentsRef = useRef<IRef>(null);
  const uuid = useRef<string>("");

  const uploadFiles = useDisclosure({ id: "upload-files" });
  const readErrors = useDisclosure({ id: "read-errors" });

  const toast = useToast();

  const { isLoading, payrollCreated, reprocessedPayroll } = useGetPayroll();

  const isConciliated = payrollCreated?.type === PayrollType.CONCILIATED;

  function serializeTransactionDraftsToTransactionDraftsInputs(
    transactionDrafts: ValidatedTransactionDraft[] | undefined
  ) {
    const transactionDraftsInputs =
      transactionDrafts
        ?.map((item) => ({
          document: item.document,
          name: item.name,
          description: item.description,
          pix_key: item.pixKey,
          pix_type: item.pixType,
          amount: (item.initialPaymentBalance ?? item.amountInCents) / 100,
          status: item.status,
          pixErrorMessage: item.pixErrorMessage,
          discount: item.discount ? item.discount / 100 : undefined,
        }))
        .sort((a, b) => {
          if (a.status !== "validated" && b.status !== "validated") {
            return 0;
          }

          if (a.status !== "validated" && b.status === "validated") {
            return -1;
          }

          return 1;
        }) || undefined;

    return transactionDraftsInputs;
  }

  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
    setValue,
    setError,
    trigger,
  } = useForm<PaymentInputs>({
    defaultValues: {
      payment: serializeTransactionDraftsToTransactionDraftsInputs(
        validationResponse?.result
      ),
      isToValidatePixDocument:
        reprocessedPayroll?.isToValidatePixDocument === undefined ||
        reprocessedPayroll?.isToValidatePixDocument === null
          ? "true"
          : JSON.stringify(reprocessedPayroll?.isToValidatePixDocument),
    },
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "payment",
  });

  const payments = watch("payment");
  const isToValidatePixDocument = watch("isToValidatePixDocument");

  const amount = payments?.reduce(
    (acc, field) => (acc += field.amount || 0),
    0
  );

  const filteredFields = debouncedSearch
    ? payments.filter(
        (field) =>
          field.name?.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          field.document?.includes(debouncedSearch)
      )
    : [];

  const filteredErrors =
    errors.payment !== undefined
      ? (errors.payment as Array<
          Merge<
            FieldError,
            FieldErrorsImpl<{
              document: string;
              name: string;
              description: string;
              pix_type: PixKeyType | "";
              pix_key: string;
              amount: number;
              status: string;
            }>
          >
        >)
      : [];

  function serializeTransactionDraftsInputs(
    transactionDraftsInputs: TransactionDraftInputs[]
  ) {
    const transactionDrafts = transactionDraftsInputs.map((payment) => ({
      document: payment.document!,
      name: payment.name!,
      description: payment.description!,
      pixType: payment?.pix_type as PixKeyType | "",
      pixKey: payment?.pix_key,
      amountInCents: payment.amount ? Math.round(payment.amount * 100) : 0,
      initialPaymentBalance: payment.amount
        ? Math.round(payment.amount * 100)
        : 0,
      discount: payment.discount
        ? Math.round(payment.discount * 100)
        : undefined,
    }));

    return transactionDrafts;
  }

  function serializePayrollInputs(paymentInputs: PaymentInputs) {
    const payroll = {
      payrollId: payrollCreated.id,
      isToValidatePixDocument: paymentInputs.isToValidatePixDocument === "true",
      transactionDrafts: serializeTransactionDraftsInputs(
        paymentInputs.payment
      ),
    };

    return payroll;
  }

  async function handleUpload() {
    setUploading(true);
    uuid.current = crypto.randomUUID();

    try {
      const response = await paymentsRef.current?.processUpload<UploadResponse>(
        {
          sendId: uuid.current,
        }
      );

      if (!response) {
        return;
      }

      const result = response.result;

      remove();

      setUploadErrors(response.errors);
      setHasError(response.hasError);

      result.forEach((item) => append(item));

      setUploadErrors(response.errors);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 504 || error.response?.status === 524)
      ) {
        console.log("Erro de timeout do lado do servidor.");

        toast({
          status: "error",
          title: "Erro ao enviar folha para reprocessamento",
          description:
            "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
        });
      }
      if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
        toast({
          status: "error",
          title: "Conexão com internet instável",
          description:
            "Verifique sua conexão com internet ou tente novamente mais tarde.",
          duration: 5000,
        });
      } else {
        const err = error as AxiosError;
        const errorData = err.response?.data as UploadPayrollResponseErrors;
        setUploadErrors(errorData);
        toast({
          status: "error",
          title: "Não foi possível enviar as planilhas",
          description: err.message,
        });
      }
      return;
    } finally {
      uploadFiles.onClose();
      setUploading(false);
    }
  }

  async function handleSave() {
    if (!payrollCreated?.id) {
      return;
    }

    if (!payments) {
      return;
    }

    trigger();

    const payload = serializePayrollInputs({
      payrollId: payrollCreated.id,
      isToValidatePixDocument: JSON.parse(String(isToValidatePixDocument)),
      payment: payments,
    });

    try {
      await saveDraft(payload);

      toast({
        status: "success",
        title: "O rascunho salvo com sucesso",
        description:
          'Você pode consultar e retomar seus rascunhos pelo sub-menu lateral em "Folhas em rascunho"',
      });
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (
          error instanceof AxiosError &&
          (error.response?.status === 504 || error.response?.status === 524)
        ) {
          console.log("Erro de timeout do lado do servidor.");

          toast({
            status: "error",
            title: "Erro ao enviar folha para reprocessamento",
            description:
              "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
          });
        }
        if (error.response?.status === 400) {
          return toast({
            status: "error",
            title: "Houve erro ao salvar",
            description:
              "Verifique se o tipo da chave pix foi preenchido corretamente em todas as transações.",
          });
        }
        return toast({
          status: "error",
          title: "Houve erro ao salvar",
          description: "Erro: " + error.response?.data.message,
        });
      }

      toast({
        status: "error",
        title: "Houve erro ao salvar",
        description: "Erro: " + JSON.stringify(error),
      });
    }
  }

  function setErrors(response: TransactionDraftValidationResponse) {
    const formattedDraft = response.result
      .map((item) => ({
        document: item.document,
        name: item.name,
        description: item.description || "",
        pix_type: item.pixType,
        pix_key: item.pixKey,
        amount: (item?.initialPaymentBalance || 0) / 100,
        discount: item.discount ? item.discount / 100 : undefined,
        advanceValue: item?.advanceValue,
        amountAfterConciliation: item?.amount,
        status: item?.validationStatus,
      }))
      .sort((a, b) => {
        if (a.status !== "validated" && b.status !== "validated") {
          return 0;
        }

        if (a.status !== "validated" && b.status === "validated") {
          return -1;
        }

        return 1;
      });

    remove();

    append(formattedDraft);

    let field: keyof TransactionDraftInputs;
    let type: string;
    let message: string;
    let hasAmountLessThanZeroError = false;
    formattedDraft.forEach((item, index) => {
      if (item.status !== "validated") {
        if (item.status === ValidationStatus.WRONG_AMOUNT) {
          hasAmountLessThanZeroError = (item?.amountAfterConciliation || 0) < 0;

          field = "amount";
          type = "wrong_amount";
          message = hasAmountLessThanZeroError
            ? `Valor incorreto, após a conciliação, o saldo do entregador ficará negativo (R$ ${((item.amountAfterConciliation || 0) / 100).toFixed(2)}) pois possui R$ ${((item.advanceValue || 0) / 100).toFixed(2)} de adiantamento`
            : ValidationStatusMessageMapper[item.status];
        }

        setError(`payment.${index}.${field}`, {
          type,
          message,
        });

        if (hasAmountLessThanZeroError) {
          toast({
            status: "error",
            title: "Erro criar folha",
            description: "Valores de saldo após a conciliação menor que zero",
          });
        }
      }
    });
  }

  async function handleValidation(data: PaymentInputs) {
    changePayrollStatus(false);

    if (!payrollCreated?.id) return;

    const payload = serializePayrollInputs({
      payrollId: payrollCreated.id,
      isToValidatePixDocument: data.isToValidatePixDocument,
      payment: data.payment,
    });

    if (isConciliated) {
      try {
        const response = await saveConciliatedDraft(payload);

        if (!response) {
          return;
        }

        if (!response.hasError) {
          props.setActiveStep(2);
          return;
        }

        setErrors(response);
      } catch (error) {
        console.log(JSON.stringify(error));
        if (
          error instanceof AxiosError &&
          (error.response?.status === 504 || error.response?.status === 524)
        ) {
          console.log("Erro de timeout do lado do servidor.");

          toast({
            status: "error",
            title: "Erro ao enviar folha para reprocessamento",
            description:
              "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
          });
        }
        if (error instanceof AxiosError) {
          return toast({
            status: "error",
            title: "Houve erro na validação",
            description: "Erro: " + error.response?.data.message,
          });
        }

        toast({
          status: "error",
          title: "Houve erro na validação",
          description: "Erro: " + JSON.stringify(error),
        });
      }
    }

    if (!isConciliated) {
      try {
        const response = !reprocessedPayroll?.schedulingDate
          ? await validatePayroll(payload)
          : await saveDraft(payload);
        if (!response) {
          return;
        }

        if (!response.hasError) {
          props.setActiveStep(2);
          return;
        }

        props.setActiveStep(1);

        toast({
          status: "error",
          title: "Há transações com erros",
          description:
            "Há transações com erros, por favor faça as correções necessárias",
        });

        const formattedDraft = response.result
          .map((item) => ({
            document: item.document,
            name: item.name,
            description: item.description,
            pix_type: item.pixType,
            pix_key: item.pixKey,
            amount: item.amountInCents / 100,
            status: item.status,
          }))
          .sort((a, b) => {
            if (a.status !== "validated" && b.status !== "validated") {
              return 0;
            }

            if (a.status !== "validated" && b.status === "validated") {
              return -1;
            }

            return 1;
          });
        remove();

        append(formattedDraft);
      } catch (error) {
        console.log(JSON.stringify(error));
        if (
          error instanceof AxiosError &&
          (error.response?.status === 504 || error.response?.status === 524)
        ) {
          console.log("Erro de timeout do lado do servidor.");

          toast({
            status: "error",
            title: "Erro ao enviar folha para reprocessamento",
            description:
              "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
          });
        }
        if (error instanceof AxiosError) {
          return toast({
            status: "error",
            title: "Houve erro na validação",
            description: "Erro: " + error.response?.data.message,
          });
        }

        toast({
          status: "error",
          title: "Houve erro na validação",
          description: "Erro: " + JSON.stringify(error),
        });
      }
    }
  }

  async function handleSyncPix() {
    setIsLoadingPix(true);
    try {
      const transactionDrafts = serializeTransactionDraftsInputs(payments);

      const updatedTransactionDrafts =
        await syncPixFromTransactionDrafts(transactionDrafts);

      const transactionDraftsInputs =
        serializeTransactionDraftsToTransactionDraftsInputs(
          updatedTransactionDrafts
        );
      if (transactionDraftsInputs) {
        setValue("payment", transactionDraftsInputs);
      }
    } catch (error) {
      toast({
        status: "error",
        title: "Houve erro ao buscar chave pix",
        description: "Error: " + JSON.stringify(error),
      });
    }
    setIsLoadingPix(false);
  }

  function handleSearch(index: number) {
    const item = payments[index];
    if (filteredFields.length === 0 && !searchFilter) {
      return false;
    }

    if (filteredFields.length === 0 && searchFilter) {
      return true;
    }

    if (
      !filteredFields.some(
        (field) =>
          item.name?.toLowerCase() === field.name?.toLowerCase() ||
          item.document === field.document
      )
    ) {
      return true;
    }
    return false;
  }

  function removeAllErrors() {
    const transactionsWithErrors = fields.reduce((acc, field, index) => {
      if (field.status !== "validated" && field.status !== undefined) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);

    remove(transactionsWithErrors);
  }

  useEffect(() => {
    if (!payrollCreated) {
      props.goToPrevious();
    }
  }, [payrollCreated, props]);

  function ErrorsContainer({
    errors,
  }: {
    errors: UploadPayrollResponseErrors | null;
  }) {
    if (!errors) {
      return <></>;
    }

    return (
      <div>
        {errors.missingColumns?.columns?.length ? (
          <Flex w="max-content" p={4} borderRadius={8} gap={2}>
            <Flex gap={2} borderRadius={4} p={4} flexDir="column">
              <Heading size="sm">Colunas não encontradas</Heading>
              <UnorderedList>
                <ListItem>
                  <Flex shadow="xs" gap={4} bg="red.100" borderRadius={4} p={4}>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Erro: ${errors.missingColumns.message}`}</Flex>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </Flex>
          </Flex>
        ) : null}
        {errors.contentErrors?.length ? (
          <Flex w="max-content" p={4} borderRadius={8} gap={2}>
            <Flex gap={2} borderRadius={4} p={4} flexDir="column">
              <Heading size="sm">Valores inválidos</Heading>
              <UnorderedList>
                {errors.contentErrors.map((error) => (
                  <ListItem key={String(error)} p={2}>
                    <Flex
                      shadow="xs"
                      gap={4}
                      bg="red.100"
                      borderRadius={4}
                      p={4}
                    >
                      <Text>{error}</Text>
                    </Flex>
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </Flex>
        ) : null}
        {errors.notExpectedErrors?.length ? (
          <Flex w="max-content" p={4} borderRadius={8} gap={2}>
            <Flex gap={2} borderRadius={4} p={4} flexDir="column">
              <Heading size="sm">Erros não esperados na planilha</Heading>
              {errors.notExpectedErrors.map((error) => (
                <UnorderedList key={error.rowIndex}>
                  <ListItem>
                    <Flex shadow="xs" gap={4} borderRadius={4} p={4}>
                      <Flex gap={2} flexDir="column">{`Linha: ${
                        error.rowIndex + 1
                      }`}</Flex>
                      <Flex
                        gap={2}
                        flexDir="column"
                      >{`Data: ${error.value.data.substring(0, 10)}`}</Flex>
                    </Flex>
                  </ListItem>
                </UnorderedList>
              ))}
            </Flex>
          </Flex>
        ) : null}
      </div>
    );
  }

  const [editMOdal, setEditModal] = useState(false);

  const dataToBeEdit = payrollCreated;

  const {
    register: registerEdit,
    getValues,
    watch: watchEdit,
  } = useForm<EditPayrollInfoInputs>({
    defaultValues: {
      name: dataToBeEdit?.name || "",
    },
  });

  const handleUpdate = async () => {
    if (dataToBeEdit) {
      const response = updatePayroll({
        ...dataToBeEdit,
        conciliatedPeriodEnd: await watchEdit("conciliatedPeriodEnd"),
        conciliatedPeriodStart: await watchEdit("conciliatedPeriodStart"),
        name: getValues("name") || dataToBeEdit.name,
      });
      response
        .then(() => {
          toast({
            status: "success",
            title: "Folha atualizada com sucesso",
          });
          setEditModal(false);
        })
        .catch((error) => {
          toast({
            status: "error",
            title: "Houve um erro ao atualizar a folha",
            description: error.response.data.message,
          });
        });
    }
  };

  return (
    <VStack
      w="full"
      alignItems="flex-start"
      gap={4}
      id="container"
      style={{ containerType: "inline-size" }}
    >
      <Modal
        isOpen={editMOdal}
        onClose={() => setEditModal(false)}
        size="5xl"
        id="upload-files"
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader>Editar informações da folha</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <VStack w="full" alignItems="flex-start" gap={4}>
              <FormLabel marginTop={8} marginBottom={4}>
                Qual o nome dessa folha de pagamento?
              </FormLabel>
              <Input
                {...registerEdit("name")}
                placeholder="Insira aqui o nome para esse pagamento"
                bg="gray.100"
                maxW="3xl"
              />
              <Flex
                mt={8}
                width={"100%"}
                justifyContent="space-between"
                gap={8}
              >
                <Stack width={"50%"}>
                  <FormLabel marginTop={8} marginBottom={4}>
                    Data inicial
                  </FormLabel>
                  <Input
                    {...registerEdit("conciliatedPeriodStart")}
                    type="date"
                    bg="gray.100"
                    maxW="3xl"
                    defaultValue={
                      dataToBeEdit?.conciliatedPeriodStart
                        ? new Date(dataToBeEdit?.conciliatedPeriodStart)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                  />
                </Stack>
                <Stack width={"50%"}>
                  <FormLabel marginTop={8} marginBottom={4}>
                    Data final
                  </FormLabel>
                  <Input
                    {...registerEdit("conciliatedPeriodEnd")}
                    type="date"
                    bg="gray.100"
                    maxW="3xl"
                    defaultValue={
                      dataToBeEdit?.conciliatedPeriodEnd
                        ? new Date(dataToBeEdit?.conciliatedPeriodEnd)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                  />
                </Stack>
              </Flex>
            </VStack>
          </ModalBody>

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={() => setEditModal(false)}>
              Fechar
            </Button>
            <Button
              color="white"
              _hover={{
                bg: "green.600",
              }}
              onClick={handleUpdate}
            >
              Salvar alterações
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Heading alignSelf="center" size="lg" color="GrayText">
        {payrollCreated.name}
      </Heading>
      <Flex justifyContent="space-between" w="full" flexDir="column" gap={8}>
        <Modal
          isOpen={uploadFiles.isOpen}
          onClose={uploadFiles.onClose}
          size="5xl"
          id="upload-files"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Upload - Arquivo de Pagamentos</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <Button size="lg" color="white">
                <a
                  href="/assets/transaction-sheet-template/exemplo-transacoes-folha-de-pagamento-conciliada.csv"
                  download
                >
                  Baixar Template
                </a>
              </Button>
              <ModalHeader color="red">
                ATENÇÃO! O Upload sobrescreve todas as transações existente na
                folha.
              </ModalHeader>
              <Flex gap={16} flexDir="column">
                <Flex flexDir="column" gap={4}>
                  <DragDrop
                    uploadUrl="/api/v1/uploaded-file/split-payment"
                    maxFiles={1}
                    ref={paymentsRef}
                  />
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={uploadFiles.onClose}>
                Fechar
              </Button>
              <Button
                color="white"
                _hover={{
                  bg: "green.600",
                }}
                onClick={handleUpload}
                isDisabled={uploading}
                isLoading={uploading}
                loadingText="Enviando arquivo"
              >
                Enviar .CSV
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Flex gap={4} w="full" flexDir={["column", "row"]} flexFlow="row wrap">
        <Flex gap={[4, 8]} flex={1}>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>Valor sem débitos: </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {amount
                  ? convertCentsToReais(amount * 100)
                  : convertCentsToReais(0)}
              </StatNumber>
            </Stat>
          </Box>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>
                Quantidade de saldos:
              </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {fields.length}
              </StatNumber>
            </Stat>
          </Box>
          {filteredErrors?.length > 0 ? (
            <Box
              bg="whiteAlpha.800"
              border="2px"
              borderColor="red.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize={["md", "xl"]}>
                  Quantidade de erros:
                </StatLabel>
                <StatNumber fontSize={["xl", "3xl"]} color="red.500">
                  {filteredErrors.filter((e) => e !== undefined).length}
                </StatNumber>
              </Stat>
            </Box>
          ) : null}
          {hasError && (
            <Flex>
              <Button
                size="lg"
                variant="ghost"
                bg="red.200"
                _hover={{
                  bgColor: "red.100",
                }}
                onClick={readErrors.onOpen}
                display="flex"
                gap={4}
              >
                <WarningOctagon color="red" size={24} />
                Erros na planilha enviada
              </Button>
            </Flex>
          )}
          <Modal
            isOpen={readErrors.isOpen}
            onClose={readErrors.onClose}
            size="5xl"
            id="read-errors"
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent px={4}>
              <ModalHeader>Erros na planilha</ModalHeader>
              <ModalCloseButton />
              <ModalBody px={16}>
                <ErrorsContainer errors={uploadErrors || null} />
              </ModalBody>

              <ModalFooter my={4}>
                <Button variant="ghost" mr={3} onClick={readErrors.onClose}>
                  Fechar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
        <Flex
          gap={4}
          flex={1}
          flexDir={["column", "row"]}
          justifyContent="flex-end"
          alignItems={["center", "flex-end"]}
        >
          {fields.length > 0 ? (
            <Tooltip label="Salvar como rascunho" aria-label="A tooltip">
              <Button
                size="lg"
                w={["full", "auto"]}
                _hover={{
                  bg: "blue.600",
                  color: "white",
                }}
                onClick={async () => {
                  await handleSave();
                }}
                gap={2}
                bg="blue.400"
                color="white"
              >
                <FloppyDiskBack size={24} />
                Salvar
              </Button>
            </Tooltip>
          ) : null}

          <Button
            color="white"
            _hover={{
              bg: "green.600",
            }}
            onClick={() => {
              setEditModal(true);
            }}
          >
            Editar informações da folha
          </Button>

          <Button
            size="lg"
            w={["full", "auto"]}
            onClick={() => {
              uploadFiles.onOpen();
            }}
          >
            Enviar .CSV
          </Button>

          <Tooltip
            hasArrow
            textAlign="center"
            fontSize="md"
            label="Este botão preencherá as chaves PIX vazias com as chaves registradas dos entregadores cadastrados."
            bg="gray.300"
            color="black"
          >
            <Button
              size="lg"
              w={["full", "auto"]}
              color="white"
              _hover={{
                bg: "green.600",
              }}
              type="button"
              form="create-payroll-draft-form"
              isDisabled={isLoadingPix}
              isLoading={isLoadingPix}
              loadingText="Buscando..."
              onClick={handleSyncPix}
            >
              Buscar PIX
            </Button>
          </Tooltip>
          {userPermissions?.some(
            (permission) =>
              permission.key === PermissionsEnum["Aprovar Split de Pagamento"]
          ) ? (
            <Tooltip
              hasArrow
              textAlign="center"
              fontSize="md"
              label="Aplica a conciliação nesta folha de pagamento, incluindo os descontos e os adiantamentos, concedidos aos entregadores identificados no período de conciliação informado."
              bg="gray.300"
              color="black"
            >
              <Button
                size="lg"
                w={["full", "auto"]}
                color="white"
                _hover={{
                  bg: "green.600",
                }}
                type="submit"
                form="create-payroll-draft-form"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                loadingText="Finalizando..."
              >
                Conciliar
              </Button>
            </Tooltip>
          ) : null}
        </Flex>
      </Flex>

      <Flex
        w="full"
        justifyContent="space-between"
        alignItems="center"
        paddingX="50"
        as="form"
      >
        <FormControl w="50%">
          <Heading size="md">Filtros</Heading>
          <FormLabel>Pesquisa</FormLabel>

          <Input
            placeholder="Procure por nome ou documento..."
            bg="gray.100"
            value={searchFilter}
            w="full"
            maxW="3xl"
            onChange={(e) => setSearchFilter(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl w="fit-content">
          {isConciliated && (
            <Flex w="full" justifyContent={"flex-end"}>
              <Text color="green.500" fontSize="xl" fontWeight="bold">
                Conciliação Automática de{" "}
                {dayjs(payrollCreated.conciliatedPeriodStart)
                  .utc()
                  .format("DD/MM/YYYY")}{" "}
                à{" "}
                {dayjs(payrollCreated.conciliatedPeriodEnd)
                  .utc()
                  .format("DD/MM/YYYY")}
              </Text>
            </Flex>
          )}
          <FormLabel display="flex" gap="12px">
            Validar documento Pix
            <Tooltip
              hasArrow
              textAlign="center"
              fontSize="md"
              label="Validar se o CPF/CNPJ passado é igual ao documento do dono da chave PIX"
              bg="gray.300"
              color="black"
            >
              <Question size={22} />
            </Tooltip>
          </FormLabel>
          <Controller
            name="isToValidatePixDocument"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack direction="row">
                  <Radio value="true" colorScheme="green">
                    Sim
                  </Radio>
                  <Radio value="false" colorScheme="red">
                    Não
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
      </Flex>

      <Box
        overflowX="auto"
        display="flex"
        flexDir="column"
        w="full"
        gap={4}
        as="form"
        id="create-payroll-draft-form"
        onSubmit={handleSubmit(handleValidation)}
      >
        {fields.filter(
          (item) => item.status !== "validated" && item.status !== undefined
        ).length ? (
          <Flex justifyContent="flex-end" w="full">
            <Button
              size="lg"
              w={["full", "auto"]}
              color="white"
              fontWeight="bold"
              onClick={removeAllErrors}
            >
              <Trash size={24} weight="bold" />
              Remover erros
            </Button>
          </Flex>
        ) : null}

        <Table variant="simple" colorScheme="green" w="full">
          <Thead>
            <Tr>
              <Th textAlign="center" minW="max-content">
                CPF/CNPJ
              </Th>
              <Th textAlign="center">Nome</Th>
              <Th textAlign="center" minW="max-content">
                Tipo da Chave Pix
              </Th>
              <Th textAlign="center">
                <Flex gap={2} justifyContent="center">
                  Chave Pix
                  <Tooltip
                    label="Deve inserir o número do celular com o DDD sem o zero a esquerda"
                    aria-label="Deve inserir o número do celular com o DDD sem o zero a esquerda tooltip"
                    placement="top"
                  >
                    <Info size={16} />
                  </Tooltip>
                </Flex>
              </Th>
              <Th textAlign="center">Valor (R$)</Th>
              {isConciliated && (
                <Th textAlign="center">Valor em Débito (R$)</Th>
              )}
              <Th></Th>
              <Th textAlign="center">Excluir Registro</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              fields.map((item, index) => (
                <Tr key={item.id} hidden={handleSearch(index)}>
                  <Td>
                    <FormControl
                      isInvalid={
                        errors?.payment
                          ? !!errors?.payment[index]?.document
                          : false
                      }
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      {item.pixErrorMessage ? (
                        <Tooltip
                          label={item.pixErrorMessage}
                          aria-label="erro que aconteceu na transação"
                        >
                          <Info size={20} color="red" />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <Input
                        border="1px"
                        borderColor="gray.300"
                        bg="gray.100"
                        w="150px"
                        {...register(`payment.${index}.document` as const, {
                          required: true,
                          validate: (value) =>
                            TestCPF(value) || TestCNPJ(value)
                              ? true
                              : "Insira um CPF ou CNPJ válido",
                        })}
                      />
                      {errors?.payment && errors?.payment[index]?.document ? (
                        <FormErrorMessage>
                          <Tooltip
                            label={errors?.payment[index]?.document?.message}
                            aria-label="error document tooltip"
                          >
                            <FormErrorIcon boxSize={6} />
                          </Tooltip>
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  </Td>
                  <Td>
                    <Input
                      border="1px"
                      borderColor="gray.300"
                      bg="gray.100"
                      w="150px"
                      {...register(`payment.${index}.name` as const, {
                        required: true,
                      })}
                    />
                  </Td>
                  <Td>
                    <Select
                      {...register(`payment.${index}.pix_type` as const, {
                        deps: [`payment.${index}.pix_key`],
                      })}
                      w="100px"
                    >
                      <option value="">Nenhum</option>
                      <option value="cpf">CPF</option>
                      <option value="cnpj">CNPJ</option>
                      <option value="email">E-mail</option>
                      <option value="telefone">Telefone</option>
                      <option value="aleatorio">Aleatório</option>
                    </Select>
                  </Td>
                  <Td>
                    <FormControl
                      isInvalid={
                        errors?.payment
                          ? !!errors?.payment[index]?.pix_key
                          : false
                      }
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      <Input
                        border="1px"
                        borderColor="gray.300"
                        bg="gray.100"
                        w="150px"
                        {...register(`payment.${index}.pix_key` as const, {
                          required: false,
                          setValueAs(value) {
                            if (payments[index].pix_type === "cpf") {
                              value = value.replace(/\D/g, "");
                              return value;
                            }
                            if (payments[index].pix_type === "telefone") {
                              if (value[0] === "+") {
                                return value;
                              }
                              return `+55${value}`.trim();
                            }
                            if (payments[index].pix_type === "email") {
                              return value?.toLowerCase();
                            }
                            return value?.trim();
                          },
                          validate: (value, formValues) => {
                            if (value === undefined) {
                              return false;
                            }

                            const pixTypeObject = {
                              "": !!reprocessedPayroll?.schedulingDate,
                              cpf:
                                TestCPF(value) || TestCNPJ(value)
                                  ? true
                                  : "Insira um CPF/CNPJ válido apenas com números",
                              email:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                  value
                                ) || "Insira um e-mail válido",
                              telefone:
                                value?.length === 14 ||
                                "Insira um telefone válido",
                              aleatorio:
                                /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(
                                  value
                                ) || "Chave com formato inválido",
                            };

                            return pixTypeObject[
                              formValues?.payment[index]
                                .pix_type as keyof typeof pixTypeObject
                            ];
                          },
                        })}
                        defaultValue=""
                      />
                      {errors?.payment && errors?.payment[index]?.pix_key ? (
                        <FormErrorMessage>
                          <Tooltip
                            label={errors?.payment[index]?.pix_key?.message}
                            aria-label="error pix key tooltip"
                          >
                            <FormErrorIcon boxSize={6} />
                          </Tooltip>
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl
                      isInvalid={
                        errors?.payment
                          ? !!errors?.payment[index]?.amount
                          : false
                      }
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      <NumberInput
                        min={0}
                        display="flex"
                        gap={4}
                        flex={1}
                        minW="100px"
                        alignItems="center"
                        allowMouseWheel={false}
                        inputMode="decimal"
                        precision={2}
                        step={0.1}
                        isValidCharacter={(value) =>
                          /^[Ee0-9+\-.,]$/.test(value)
                        }
                      >
                        R$
                        <NumberInputField
                          textAlign="right"
                          border="1px"
                          borderColor="gray.300"
                          bg="gray.100"
                          type="number"
                          w="100px"
                          {...register(`payment.${index}.amount` as const, {
                            valueAsNumber: true,
                            required: true,
                            min: {
                              value: 0.01,
                              message: "Insira um valor mínimo de R$ 0,01",
                            },
                          })}
                        />
                        {errors?.payment && errors?.payment[index]?.amount ? (
                          <FormErrorMessage>
                            <Tooltip
                              label={errors?.payment[index]?.amount?.message}
                              aria-label="error amount tooltip"
                            >
                              <FormErrorIcon boxSize={6} />
                            </Tooltip>
                          </FormErrorMessage>
                        ) : null}
                      </NumberInput>
                    </FormControl>
                  </Td>
                  {isConciliated && (
                    <Td>
                      <FormControl
                        isInvalid={
                          errors?.payment
                            ? !!errors?.payment[index]?.discount
                            : false
                        }
                        display="flex"
                        gap={2}
                        alignItems="center"
                      >
                        <NumberInput
                          min={0}
                          display="flex"
                          gap={4}
                          flex={1}
                          minW="100px"
                          alignItems="center"
                          allowMouseWheel={false}
                          inputMode="decimal"
                          precision={2}
                          step={0.1}
                          isValidCharacter={(value) =>
                            /^[Ee0-9+\-.,]$/.test(value)
                          }
                          isDisabled={!!reprocessedPayroll?.schedulingDate}
                        >
                          R$
                          <NumberInputField
                            textAlign="right"
                            border="1px"
                            borderColor="gray.300"
                            bg="gray.100"
                            type="number"
                            w="100px"
                            {...register(`payment.${index}.discount` as const, {
                              valueAsNumber: true,
                              required: false,
                              min: {
                                value: 0.01,
                                message: "Insira um valor mínimo de R$ 0,01",
                              },
                            })}
                          />
                          {errors?.payment &&
                          errors?.payment[index]?.discount ? (
                            <FormErrorMessage>
                              <Tooltip
                                label={
                                  errors?.payment[index]?.discount?.message
                                }
                                aria-label="error amount tooltip"
                              >
                                <FormErrorIcon boxSize={6} />
                              </Tooltip>
                            </FormErrorMessage>
                          ) : null}
                        </NumberInput>
                      </FormControl>
                    </Td>
                  )}
                  <Td>
                    {item.status ? (
                      <Badge
                        variant="solid"
                        colorScheme={
                          item.status !== "validated" ? "red" : "green"
                        }
                      >
                        {
                          ValidationStatusMessageMapper[
                            item.status as keyof typeof ValidationStatusMessageMapper
                          ]
                        }
                      </Badge>
                    ) : null}
                  </Td>
                  <Td textAlign="center">
                    <Button variant="danger" onClick={() => remove(index)}>
                      <Trash size={24} color="white" weight="bold" />
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Button
          size="lg"
          minH="40px"
          onClick={() => {
            append({
              document: undefined,
              name: undefined,
              amount: 0,
              description: undefined,
              pix_key: undefined,
            });
          }}
          display="flex"
          gap={4}
          maxW="xs"
          alignSelf="center"
          marginBottom={3}
        >
          <Plus size={16} />
          Adicionar pagamento
        </Button>
      </Box>
    </VStack>
  );
}
