import { VStack, Box } from "@chakra-ui/layout";
import GiftFilterComponent from "./gift-filter-form.component";
import RewardsTable from "./rewards-table.component";

export function GiftsHistory() {
  return (
    <VStack gap={8} flex={1} alignItems="flex-start" w="100%">
      <Box
        w="full"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
      >
        <GiftFilterComponent />
        <RewardsTable />
      </Box>
    </VStack>
  );
}
