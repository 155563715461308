import { Td, Tr } from "@chakra-ui/react";
import { IUserDebtDTO } from "../../../../dto/user-debt-dto";
import { memo } from "react";
import { convertCentsToReais } from "../../../../utils/convert-money";
const Component = ({ data }: { data: IUserDebtDTO }) => {
  return (
    <Tr>
      <Td style={{ paddingTop: 18, paddingBottom: 18 }}>
        {data?.deliverymanName}
      </Td>
      <Td>{data?.document}</Td>
      <Td>
        {data?.remainingAmount ? convertCentsToReais(data.remainingAmount) : ""}
      </Td>
    </Tr>
  );
};

export const TableRowUserDebt = memo(Component);
