import { z } from "zod";
import { DebtStatusMapperEnum } from "../mappers/debt-status-mapper.enum";

export const formSchema = z.object({
  nameOrDocument: z.string().optional(),
  debtId: z.number().int().min(1).optional(),
  contractNumber: z.string().optional(),
  document: z.string().optional(),
  createdAt: z.date().optional(),
  quitDate: z.date().optional(),
  debtDate: z.date().optional(),
  companyId: z.number().int().min(1).optional(),
  debtStatus: z
    .nativeEnum(DebtStatusMapperEnum)
    .optional()
    .or(z.literal(""))
    .transform((val) => (val === "" ? undefined : val)),
  orderBy: z.string(),
});

export type FilterFormInputsValidation = z.infer<typeof formSchema>;

export const defaultValues: FilterFormInputsValidation = {
  nameOrDocument: "",
  document: "",
  debtId: undefined,
  contractNumber: undefined,
  createdAt: undefined,
  quitDate: undefined,
  debtDate: undefined,
  companyId: undefined,
  debtStatus: undefined,
  orderBy: "",
};
