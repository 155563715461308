import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import { useHistoryPointsContext } from "../provider/history-points.provider";
import { useHistoryRedemptionContext } from "../provider/history-remdeption.provider";

function RedemptionFilterComponent() {
  const { categories } = useHistoryPointsContext();

  const { setWhereParams, handleFilter } = useHistoryRedemptionContext();

  return (
    <Stack>
      <Flex gap={4}>
        <FormControl id="nome-premio" flex="1">
          <FormLabel>Nome do Prêmio:</FormLabel>
          <Input
            placeholder="Digite aqui o nome do prêmio"
            bg="gray.100"
            onChange={(e) => setWhereParams({ giftName: e.target.value })}
          />
        </FormControl>

        <FormControl id="nome-participante" flex="1">
          <FormLabel>Nome do participante:</FormLabel>
          <Input
            placeholder="Digite aqui o nome do participante"
            bg="gray.100"
            onChange={(e) =>
              setWhereParams({ deliverymanName: e.target.value })
            }
          />
        </FormControl>
      </Flex>
      <Flex gap={4}>
        <FormControl id="categoria" flex="1">
          <FormLabel>Categoria:</FormLabel>
          <Select
            placeholder="Selecione uma categoria"
            bg="gray.100"
            onChange={(e) => setWhereParams({ category: e.target.value })}
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl id="cpf" flex="1">
          <FormLabel>CPF:</FormLabel>
          <Input
            placeholder="Digite aqui o cpf do participante"
            bg="gray.100"
            onChange={(e) => setWhereParams({ document: e.target.value })}
          />
        </FormControl>
      </Flex>

      <Flex
        justifyContent={"space-between"}
        gap={4}
        display={"flex"}
        flexDir={"row"}
      >
        <Flex alignItems={"flex-end"} mt={4} mb={4}>
          <Button colorScheme="green" onClick={handleFilter}>
            Filtrar
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
}

export default RedemptionFilterComponent;
