import { Box, Text } from "@chakra-ui/react";
import { useCloudManagementDebtsActionStore } from "../../store/action-store";
import { useEffect } from "react";
import { patchWithAuth } from "../../../../../../../services/basicService";

type IProps = {
  readonly debtId: number;
};

export function CloudManagementDebtsActionsCancelContent({ debtId }: IProps) {
  const { setAction } = useCloudManagementDebtsActionStore();

  useEffect(() => {
    setAction(async (twoAuthCode: number, twoAuthUuid: string) => {
      await patchWithAuth("/api/v1/debt/cloud-management/cancel", {
        debtId,
        twoAuthUuid,
        twoAuthCode,
      });
    });
  }, [setAction, debtId]);

  return (
    <Box display="flex" flexDir="column" gap={2} fontSize="medium">
      <Text>Deseja realmente cancelar este débito?</Text>
      <Text fontWeight="semibold">Confirmar essa ação?</Text>
    </Box>
  );
}
