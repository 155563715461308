import { VStack, Heading, Flex, HStack, Badge, Box } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  useToast,
  Portal,
  Select,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
} from "@chakra-ui/react";
import { ptBR } from "date-fns/locale";
import { DateRange, DayPicker } from "react-day-picker";
import { useDebtStore } from "../../../../store/debt.store";
import { Pagination } from "../../../../components/pagination";
import { useRouteError, useSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { convertCentsToReais } from "../../../../utils/convert-money";
import {
  Debt,
  debtColorMap,
  debtTextMap,
  debtTypeTextMap,
  DebtTypeTextMapKey,
} from "../../../../dto/debt-dto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getWithAuth, patchWithAuth } from "../../../../services/basicService";
import { CustomModal } from "../../../../components/modal";
import { ArrowFatLinesRight, CalendarX } from "@phosphor-icons/react";
import { DebtStatus } from "../../../../enums/debt-status";
import { useLoading } from "../../../../store";
import { calculateRemainingDebt } from "../functions/calculate-remaining-debt";
import { DebtType } from "../../../../enums/debt-type";
import { EditCompanyDebt } from "../components/edit-debt";
import { useRef } from "react";
import { AxiosError } from "axios";
import { AxiosDataError } from "../../../../dto/axios-error-dto";
dayjs.extend(utc);

const filtersFormSchema = z.object({
  dateRange: z
    .object({ to: z.date().optional(), from: z.date().optional() })
    .optional(),
  company: z.string().optional(),
  type: z.string().optional(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function Debts() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const routeFetchError = useRouteError();
  const toast = useToast();
  const navigate = useNavigate();
  const client = useQueryClient();

  if (routeFetchError) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${routeFetchError}`,
    });
  }

  const debts = useDebtStore((state) => state.result);
  const totalPages = useDebtStore((state) => state.totalPages);
  const getDebts = useDebtStore((state) => state.getAll);
  const cancelDebt = useDebtStore((state) => state.cancelDebt);
  const loading = useLoading((state) => state.loading);
  const setLoading = useLoading((state) => state.setLoading);
  const editDebtModal = useDisclosure({ id: "edit-debit-modal" });
  const editDebt = useRef<Debt>();

  const { data: companies } = useQuery<{ name: string; id: string }[]>({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data;
    },
  });

  const companyId = searchParams.get("companyId");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const type = searchParams.get("type");

  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("endDate");
      prev.delete("startDate");
      prev.delete("companyId");
      prev.delete("type");
      if (data.dateRange?.from) {
        prev.set(
          "startDate",
          dayjs(data.dateRange.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data.dateRange?.to) {
        prev.set(
          "endDate",
          dayjs(data.dateRange.to).utc().format("YYYY-MM-DD")
        );
      }
      if (data.company) {
        prev.set("companyId", data.company);
      }
      if (data.type) {
        prev.set("type", data.type);
      }
      return prev;
    });
  }

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  const {
    handleSubmit,
    control,
    register,
    formState: { isDirty },
  } = useForm<filtersFormInput>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      company: companyId || undefined,
      dateRange: {
        from: startDate ? dayjs(startDate).toDate() : undefined,
        to: endDate ? dayjs(endDate).toDate() : undefined,
      },
      type: type || undefined,
    },
  });

  const handleCancel = async (debtId: number) => {
    try {
      setLoading(true);

      await cancelDebt(debtId);

      await getDebts({ page: 1, limit: 10 });

      toast({
        status: "success",
        title: "Cancelamento realizado com sucesso",
        description: `O cancelamento do débito foi feito com sucesso!`,
      });
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Erro ao cancelar débito",
        description: `Erro: ${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const PayoffDebt = useMutation({
    mutationFn: (debtId: number) => {
      return patchWithAuth(`/api/v1/debt/payoff/${debtId}`, {
        status: DebtStatus.CLOSED,
        quit_date: new Date(),
      });
    },
    onSuccess: (_, debtId) => {
      toast({
        status: "success",
        title: "Dívida quitada com sucesso",
        description: `A dívida foi quitada com sucesso!`,
      });
      client.invalidateQueries(["debts"]);
      useDebtStore.setState((state) => ({
        result: state.result.map((debt) =>
          debt.id === debtId ? { ...debt, status: DebtStatus.CLOSED } : debt
        ),
      }));
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao quitar dívida",
        description: `Erro: ${error.message}`,
      });
    },
  });
  const handlePayoffDebt = (status: DebtStatus, debtId: number) => {
    if (status !== DebtStatus.OPEN || PayoffDebt.isLoading) return;
    PayoffDebt.mutate(debtId);
  };

  // const calculateRemainingDebt = (debt: Debt) => {
  //   if (debt.status === DebtStatus.CANCELED) {
  //     return debt.amount;
  //   }

  //   if (debt.transactions.length === 0) {
  //     return 0;
  //   }

  //   const remainingAmount = debt.transactions.reduce(
  //     (totalAmount, transaction) =>
  //       transaction.status === TransactionStatus.SUCCESS
  //         ? (totalAmount += transaction.amount)
  //         : totalAmount,
  //     0
  //   );

  //   return remainingAmount;
  // };

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Flex justifyContent="space-between" w="full">
        <Heading fontWeight="extrabold" textAlign="left">
          Débitos lançados
        </Heading>
        <Button
          type="submit"
          bg="green.600"
          w="max-content"
          color="whiteAlpha.900"
          _hover={{ bg: "green.700" }}
          px={8}
          size="lg"
          onClick={() => navigate("company/create")}
        >
          Lançar débito
        </Button>
      </Flex>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={8}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={8}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            gap={8}
          >
            <Flex gap={8} alignItems="end">
              <FormControl as="fieldset" w="max-content">
                <FormLabel as="legend">Data do débito</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button>
                      {!startDate
                        ? "Escolha a sua data"
                        : !endDate
                          ? startDate
                          : `${startDate.replaceAll(
                              "-",
                              "/"
                            )} a ${endDate.replaceAll("-", "/")}`}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent bg="white" p={2} w="max-content">
                      <PopoverBody>
                        <Controller
                          control={control}
                          name="dateRange"
                          render={({ field }) => (
                            <DayPicker
                              id="dateFilter"
                              mode="range"
                              initialFocus={true}
                              selected={field.value as DateRange}
                              onSelect={field.onChange}
                              locale={ptBR}
                              modifiersClassNames={{
                                disabled: "btn-disabled",
                                selected: "custom-selected",
                                outside: "outside-day",
                              }}
                            />
                          )}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>

              <FormControl as="fieldset">
                <FormLabel as="legend">Empresa</FormLabel>
                <Select
                  variant="filled"
                  {...register("company")}
                  size="md"
                  w="full"
                >
                  <option value="">Todos</option>
                  {companies?.map((company) => (
                    <option value={company.id} key={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl as="fieldset">
                <FormLabel as="legend">Tipo</FormLabel>
                <Select
                  {...register("type")}
                  placeholder="Selecione o tipo"
                  variant="filled"
                >
                  {Object.values(DebtType).map((value) => {
                    return (
                      <option key={value} value={value}>
                        {debtTypeTextMap[value as DebtTypeTextMapKey]}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              <Button
                type="submit"
                bg="green.600"
                w="max-content"
                color="whiteAlpha.900"
                _hover={{ bg: "green.700" }}
                px={8}
                size="md"
                isDisabled={!isDirty}
              >
                Filtrar
              </Button>

              <Button
                w="max-content"
                px={8}
                size="md"
                isDisabled={!startDate && !companyId}
                onClick={() => {
                  setSearchParams((prev) => {
                    prev.delete("startDate");
                    prev.delete("endDate");
                    prev.delete("companyId");
                    prev.delete("type");
                    return prev;
                  });
                }}
              >
                Limpar Filtros
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          pb={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{ containerType: "inline-size" }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Valor</Th>
                <Th textAlign="center">Valor restante</Th>
                <Th textAlign="center">Empresa</Th>
                <Th textAlign="center">Data de débito</Th>
                <Th textAlign="center">Data de quitação</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Tipo</Th>
                <Th textAlign="center">Cancelar</Th>
                <Th textAlign="center">Editar</Th>
                <Th textAlign="center">Quitar Débito</Th>
                <Th textAlign="center">Ver detalhes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {debts?.map((debt) => (
                <Tr key={debt.id}>
                  <Td>{convertCentsToReais(debt.amount)}</Td>
                  <Td textAlign="center">
                    {convertCentsToReais(
                      debt.amount - calculateRemainingDebt(debt)
                    )}
                  </Td>
                  <Td textAlign="center">{debt.company.name}</Td>
                  <Td textAlign="center">
                    {dayjs(debt.debt_date).format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign="center">
                    {debt.quit_date
                      ? dayjs(debt.quit_date).format("DD/MM/YYYY")
                      : "-"}
                  </Td>
                  <Td textAlign="center">
                    <Badge
                      colorScheme={debtColorMap[debt.status]}
                      variant="solid"
                      px={4}
                    >
                      {debtTextMap[debt.status]}
                    </Badge>
                  </Td>
                  <Td textAlign="center">
                    {debt.type &&
                      debtTypeTextMap[debt.type as DebtTypeTextMapKey]}
                  </Td>
                  <Td textAlign="center">
                    {debt.status === DebtStatus.CANCELED ||
                    debt.status === DebtStatus.CLOSED ? null : (
                      <CustomModal
                        modalHeader="Cancelar débito"
                        modalBody="Realmente deseja cancelar este débito?"
                        confirmButtonText="Quero cancelar débito"
                        buttonProps={{
                          bg: "whiteAlpha.800",
                          color: "red.600",
                          w: "max-content",
                          _hover: { color: "red.800" },
                          size: "50px",
                          isLoading: loading,
                          isDisabled: loading,
                          leftIcon: <CalendarX size={28} />,
                        }}
                        onConfirm={() => {
                          handleCancel(debt.id);
                        }}
                      />
                    )}
                  </Td>
                  <Td>
                    {debt.status === DebtStatus.SCHEDULED && (
                      <Button
                        onClick={() => {
                          editDebt.current = debt;
                          editDebtModal.onOpen();
                        }}
                      >
                        Editar
                      </Button>
                    )}
                  </Td>
                  <Td textAlign="center">
                    {debt.status === DebtStatus.OPEN && (
                      <Button
                        onClick={() => handlePayoffDebt(debt.status, debt.id)}
                        isLoading={PayoffDebt.isLoading}
                      >
                        Quitar
                      </Button>
                    )}
                  </Td>
                  <Td textAlign="center">
                    <IconButton
                      aria-label="campo para ir a tela de detalhes da folha pendente"
                      role="a"
                      size="md"
                      bg="green.500"
                      icon={<ArrowFatLinesRight />}
                      color="white"
                      w="50%"
                      _hover={{ bg: "green.600" }}
                      onClick={() => {
                        navigate(`details/${debt.id}`, { relative: "path" });
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Modal
            isOpen={editDebtModal.isOpen}
            onClose={editDebtModal.onClose}
            isCentered
            size={"2xl"}
          >
            <ModalOverlay />
            <EditCompanyDebt
              debt={editDebt.current}
              onClose={editDebtModal.onClose}
            />
          </Modal>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={formatPage()}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={totalPages}
          />
        </HStack>
      </Box>
    </VStack>
  );
}
