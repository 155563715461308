import { Flex, Heading } from "@chakra-ui/layout";
import { useLocation, useNavigate } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  useOutsideClick,
} from "@chakra-ui/react";
import { MenuItemUi } from "../../components-ui/sub-menu/menu-item";
import { useRef, useState } from "react";
import { SubMenuAccordionItem } from "./accordion-item";

type ISimpleRoute = {
  title: string;
  path: string;
};

type IAccordionRoute = {
  title: string;
  routes: ISimpleRoute[];
};

type IProps = {
  readonly menuTitle: string;
  readonly routes: (ISimpleRoute | IAccordionRoute)[];
};

export function SubMenu({ menuTitle, routes }: IProps) {
  const [menuSelected, setMenuSelected] = useState(routes[0].title);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const isPortifioManagement = pathname.includes("portfolio-management");
  const isActivityHistory = pathname.includes("activity-history");

  useOutsideClick({
    ref: menuRef,
    handler: () => setIsMenuOpen(false),
  });

  const handleButtonClick = () => {
    if (isActivityHistory) {
      navigate(-1);
    } else if (isPortifioManagement) {
      navigate("/portfolio-management/activity-history");
    }
  };

  return (
    <Flex
      p={4}
      borderRadius={8}
      shadow="base"
      w="full"
      h="min-content"
      bg="whiteAlpha.800"
      alignItems="center"
      gap={6}
      ref={menuRef}
    >
      <Menu isOpen={isMenuOpen}>
        <MenuButton
          as={Heading}
          fontFamily="Source Sans 3, sans-serif"
          fontWeight="700"
          fontSize="2xl"
          cursor="pointer"
          onClick={() => setIsMenuOpen((prev) => !prev)}
        >
          {menuTitle}
        </MenuButton>
        <MenuList
          p={6}
          bg="#fff"
          borderRadius="md"
          boxShadow="lg"
          minW="200px"
          fontFamily="Source Sans 3, sans-serif"
          fontSize="md"
          color="#000"
          fontWeight="400"
          zIndex={9999}
        >
          {routes.map((route) =>
            "routes" in route ? (
              <SubMenuAccordionItem
                key={route.title}
                title={route.title}
                routes={route.routes}
                setMenuSelected={setMenuSelected}
                setIsMenuOpen={setIsMenuOpen}
              />
            ) : (
              <MenuItemUi
                key={route.title}
                to={route.path}
                isSelected={pathname === route.path}
                onClick={() => {
                  setMenuSelected(route.title);
                  setIsMenuOpen(false);
                }}
              >
                {route.title}
              </MenuItemUi>
            )
          )}
        </MenuList>
      </Menu>

      <CaretRight size={20} color="#6F6F6F" weight="bold" />
      <Heading
        fontFamily="Source Sans 3, sans-serif"
        fontSize="2xl"
        fontWeight="700"
        bg="#0B0B0B"
        color="#fff"
        borderRadius="md"
        paddingY={2}
        paddingX={10}
      >
        {menuSelected}
      </Heading>
      {isPortifioManagement && (
        <Button
          size="lg"
          color="white"
          fontFamily="Source Sans 3, sans-serif"
          bg="#0B0B0B"
          borderRadius="6.25rem"
          padding="0.625rem 1.5rem"
          height="2.75rem"
          _hover={{ bg: "#0B0B0B" }}
          marginLeft="auto"
          onClick={handleButtonClick}
        >
          {isActivityHistory ? "Voltar" : "Histórico de atividades"}
        </Button>
      )}
    </Flex>
  );
}
