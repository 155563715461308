import { useState, useEffect, useRef } from "react";
import { Flex, useToast, Text } from "@chakra-ui/react";
import { postWithAuth } from "../../../../../../../services/basicService";
import { X } from "@phosphor-icons/react";

type IProps = {
  readonly isOpen: boolean;
  readonly debtId: number | null;
  readonly action: "cancel" | "pay off" | "reverse" | null;
};

export function useTwoFactorResend({ isOpen, debtId, action }: IProps) {
  const toast = useToast({
    position: "top",
    containerStyle: {
      width: "100%",
      maxWidth: "100%",
      fontFamily: "Source Sans 3, sans-serif",
    },
    render: ({ status, title, description, onClose, icon }) => (
      <Flex
        w="full"
        h="fit-content"
        bg={status === "success" ? "#1DC161" : "#D65745"}
        color="white"
        px="5"
        py="5"
        borderRadius="md"
        fontFamily="Source Sans 3, sans-serif"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexDir="column" gap={2}>
          <Flex gap={2} alignItems="center" fontWeight="semibold" fontSize="xl">
            {icon}
            {title && <Text>{title}</Text>}
          </Flex>
          <Text fontWeight="light" fontSize="medium">
            {description}
          </Text>
        </Flex>
        <X onClick={onClose} cursor="pointer" />
      </Flex>
    ),
  });

  const [timeLeft, setTimeLeft] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Função para iniciar o timer
  const startTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Limpa o intervalo anterior, se existir
    }
    intervalRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          setIsTimerActive(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Inicia o timer quando o modal é aberto
  useEffect(() => {
    if (isOpen) {
      setTimeLeft(30);
      setIsTimerActive(true);
      startTimer();
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Limpa o intervalo ao desmontar ou quando isOpen muda
      }
    };
  }, [isOpen]);

  const resendCode = async () => {
    try {
      const sendAction: Record<string, string> = {
        cancel: "cancelar",
        "pay off": "quitar",
        reverse: "estornar",
      };

      await postWithAuth(
        "/api/v1/two-factor-auth-code/generate/cloud-management",
        { debtId, action: sendAction[action as keyof typeof sendAction] }
      );
      setTimeLeft(30);
      setIsTimerActive(true);
      startTimer(); // Reinicia o timer após o reenvio
      toast({
        status: "success",
        title: "Código reenviado com sucesso!",
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast({
        status: "error",
        title: "Erro ao reenviar o código",
        description: error?.response?.data.message,
      });
    }
  };

  return {
    timeLeft,
    isTimerActive,
    resendCode,
  };
}
