import { Box } from "@chakra-ui/react";
import { DivergenceLog } from "../../../../types/get-duplicated-account.type";

export type StatusType = "Atualizada" | "Pendente";

const statusColors = {
  Atualizada: {
    bg: "#E9F9F0", // Verde claro
    color: "#1DC161", // Verde escuro
  },
  Pendente: {
    bg: "#FFF3ED", // Laranja claro
    color: "#FF7E46", // Laranja escuro
  },
};

function StatusBadge({ status }: { status: DivergenceLog }) {
  const statusMaped: { [key in DivergenceLog]: StatusType } = {
    [DivergenceLog.RESOLVED]: "Atualizada",
    [DivergenceLog.PENDING]: "Pendente",
  };

  const { bg, color } = statusColors[statusMaped[status]] || {
    bg: "gray.100",
    color: "gray.700",
  };

  return (
    <Box
      bg={bg}
      color={color}
      display="flex"
      width="4.1875rem"
      height="1.125rem"
      padding="0.125rem 0.5rem"
      justifyContent="center"
      alignItems="center"
      gap="0.25rem"
      borderRadius={"0.25rem"}
    >
      <Box
        textAlign="center"
        fontFamily="'Source Sans 3'"
        fontSize="0.625rem"
        fontStyle="normal"
        fontWeight="800"
        lineHeight="1.25rem"
      >
        {statusMaped[status]}
      </Box>
    </Box>
  );
}

export default StatusBadge;
