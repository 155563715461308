import {
  Box,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  Spinner,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Debt } from "../../../../../../../dto/debt-dto";
import { CurrencyCircleDollar, Info } from "@phosphor-icons/react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCloudManagementDebtsActionStore } from "../../store/action-store";
import {
  getWithAuth,
  postWithAuth,
} from "../../../../../../../services/basicService";
import { useQuery } from "@tanstack/react-query";
import { convertCentsToReais } from "../../../../../../../utils/convert-money";
import { useState } from "react";

type IProps = {
  readonly debt: Debt;
  readonly debitReceivingAccountId: number;
};

export function CloudManagementDebtsActionsModalContentReverse({
  debt,
  debitReceivingAccountId,
}: IProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const { setAction } = useCloudManagementDebtsActionStore();

  const { data, isLoading } = useQuery({
    queryKey: [`payments-and-refunds`, debt.id],
    queryFn: async () => {
      const PATH = `/api/v1/debt/cloud-management/${debt.id}/payments-and-refunds`;

      const req = await getWithAuth(PATH);
      const res = req?.data as {
        debtId: number;
        debtAmount: number;
        paid: number;
        refunded: number;
        amountToBeRefunded: number;
      };

      setValue("amount", (res?.amountToBeRefunded ?? 0) / 100);

      setAction(async (twoAuthCode: number, twoAuthUuid: string) => {
        await postWithAuth("/api/v1/debt/cloud-management/reversal", {
          amount: res.amountToBeRefunded,
          debtId: debt.id,
          twoAuthCode,
          twoAuthUuid,
        });
      });

      return res;
    },
  });

  const refundSchema = z.object({
    amount: z
      .number({
        required_error: "Este campo é obrigatório",
        invalid_type_error: "Digite um número válido",
      })
      .min(0.01, "O valor mínimo é R$ 0,01")
      .max(
        (data?.amountToBeRefunded ?? 0) / 100,
        `O valor máximo é R$ ${((data?.amountToBeRefunded ?? 0) / 100).toFixed(2).replace(".", ",")}`
      ),
  });

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof refundSchema>>({
    resolver: zodResolver(refundSchema),
    mode: "onChange",
    defaultValues: {
      amount: 0,
    },
  });

  return (
    <Flex w="full" flexDir="column" fontFamily="Source Sans 3" gap={4}>
      {!isLoading ? (
        <>
          <Flex w="full" flexDir="column" alignItems="center">
            <Flex
              w="full"
              display="flex"
              fontSize="xl"
              fontFamily="Source Sans 3"
              fontWeight="normal"
              mb={4}
            >
              <Text>
                Deseja realmente estornar o débito no valor de{" "}
                <Text as="span" fontWeight="semibold">
                  {convertCentsToReais(data?.amountToBeRefunded || 0)}
                </Text>{" "}
                ?
              </Text>
            </Flex>

            <FormControl
              w="full"
              display="flex"
              flexDir="column"
              gap={1.5}
              as="fieldset"
              isInvalid={!!errors.amount}
              isRequired
            >
              <Text
                display="flex"
                alignItems="center"
                gap={1}
                fontFamily="Source Sans 3"
                fontSize="small"
              >
                Valor a estornar
                <Tooltip
                  label={`O valor máximo indicado é de R$ ${(data?.amountToBeRefunded ?? 0 / 100).toFixed(2).replace(".", ",")}`}
                  hasArrow
                  placement="top"
                >
                  <Info size={16} color="black" />
                </Tooltip>
              </Text>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <InputGroup
                    w="full"
                    h="full"
                    display="flex"
                    alignItems="center"
                  >
                    <InputLeftElement
                      h="full"
                      display="flex"
                      alignItems="center"
                      ml={4}
                    >
                      <CurrencyCircleDollar size={32} color="#7f7f7f" />
                    </InputLeftElement>
                    <NumberInput
                      w="max-content"
                      display="flex"
                      gap={4}
                      alignItems="center"
                      inputMode="decimal"
                      precision={2}
                      step={0.1}
                      isDisabled={isDisabled}
                      isValidCharacter={(value) => /^[Ee0-9+\-.,]$/.test(value)}
                      value={field.value}
                      onChange={(_valueString, valueAsNumber) =>
                        field.onChange(isNaN(valueAsNumber) ? 0 : valueAsNumber)
                      }
                      onBlur={() => {
                        if (isNaN(field.value) || field.value < 0.01) {
                          field.onChange(0);
                        }

                        setAction(
                          async (twoAuthCode: number, twoAuthUuid: string) => {
                            await postWithAuth(
                              "/api/v1/debt/cloud-management/reversal",
                              {
                                amount: Math.round(field.value * 100),
                                debtId: debt.id,
                                twoAuthCode,
                                twoAuthUuid,
                              }
                            );
                          }
                        );
                      }}
                    >
                      <NumberInputField
                        w="48"
                        py={6}
                        textAlign="right"
                        border="1px"
                        borderColor="gray.400"
                        type="number"
                        fontSize="xl"
                      />
                    </NumberInput>
                  </InputGroup>
                )}
              />
              <Flex gap={2}>
                <Switch
                  id="edit-input"
                  colorScheme="green"
                  onChange={() =>
                    setIsDisabled((prev) => {
                      if (!prev) {
                        setValue(
                          "amount",
                          (data?.amountToBeRefunded || 0) / 100
                        );
                      }
                      return !prev;
                    })
                  }
                />

                <Text
                  color={`${isDisabled ? "#CCCCCB" : "#D73030"}`}
                  fontFamily="Source Sans 3"
                >
                  Editar valor máximo de{" "}
                  {convertCentsToReais(data?.amountToBeRefunded || 0)}
                </Text>
              </Flex>
            </FormControl>
          </Flex>
          <Flex display="flex" fontSize="xl">
            <Text>Informações das contas relacionadas...</Text>
          </Flex>
          <Flex w="full" justify="space-between" alignItems="center">
            <Box w="full">
              <Text fontFamily="Source Sans 3" fontSize="md">
                ID Conta saída
              </Text>
              <Input
                w="48"
                py={6}
                fontFamily="Source Sans 3"
                fontSize="xl"
                value={debitReceivingAccountId}
                isDisabled={true}
              />
            </Box>
            <Box w="full">
              <Text fontFamily="Source Sans 3" fontSize="md">
                ID Conta recebimento
              </Text>
              <Input
                w="48"
                py={6}
                fontFamily="Source Sans 3"
                fontSize="xl"
                value={debt.account_id}
                isDisabled={true}
              />
            </Box>
          </Flex>
          <Text
            fontFamily="Source Sans 3, sans-serif"
            fontSize="medium"
            fontWeight="bold"
          >
            Confirmar essa ação?
          </Text>
        </>
      ) : (
        <Flex h="full" w="full" justifyContent="center" alignItems="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}
    </Flex>
  );
}
