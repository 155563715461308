import { VStack } from "@chakra-ui/layout";

import { CreateGiftsForm } from "./page-components/create-gift-form.component";
import { useCurrentUserStore } from "../../../../store/current-user.store";

export function CreateGiftsPage() {
  const { user } = useCurrentUserStore((state) => ({
    getPermissions: state.getPermissions,
    user: state.user,
  }));

  const isAdmin = user?.roles?.some((role) => role.name === "Admin_Trampay");

  return (
    <VStack gap={8} flex={1} alignItems={"flex-start"}>
      {isAdmin && <CreateGiftsForm />}
    </VStack>
  );
}
