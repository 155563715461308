import { Button, Flex, Select } from "@chakra-ui/react";
import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";
import { Dispatch, SetStateAction } from "react";

interface PaginationProps {
  currentPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  itemsPerPage: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setPage,
  setItemsPerPage,
  totalPages,
  itemsPerPage,
}) => {
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <Flex w="full" justify="flex-end" my={8}>
      <Flex align="center" gap={2}>
        <Button
          bg="-moz-initial"
          size="md"
          variant="ghost"
          p={1}
          minW="auto"
          aria-label="First page"
          color="gray.400"
          onClick={() => handlePageChange(1)}
          isDisabled={currentPage === 1}
        >
          <CaretDoubleLeft size={18} />
        </Button>
        <Button
          bg="-moz-initial"
          size="md"
          variant="ghost"
          p={1}
          minW="auto"
          aria-label="Previous page"
          color="gray.400"
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          <CaretLeft size={18} />
        </Button>
        <Button
          size="md"
          bg="#1DC161"
          color="white"
          borderRadius="full"
          _hover={{ bg: "green.600" }}
          minW={8}
        >
          {currentPage}
        </Button>
        {[...Array(Math.min(5, totalPages - currentPage))].map((_, i) => {
          const pageNumber = currentPage + 1 + i;
          return (
            <Button
              bg="-moz-initial"
              color="gray.400"
              key={pageNumber}
              size="md"
              variant="ghost"
              minW={8}
              borderRadius="full"
              onClick={() => handlePageChange(pageNumber)}
              isDisabled={currentPage === pageNumber}
            >
              {pageNumber}
            </Button>
          );
        })}
        <Button
          bg="-moz-initial"
          color="gray.400"
          size="md"
          variant="ghost"
          p={1}
          minW="auto"
          aria-label="Next page"
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
        >
          <CaretRight size={18} />
        </Button>
        <Button
          bg="-moz-initial"
          color="gray.400"
          size="md"
          variant="ghost"
          p={1}
          minW="auto"
          aria-label="Last page"
          onClick={() => handlePageChange(totalPages)}
          isDisabled={currentPage === totalPages}
        >
          <CaretDoubleRight size={18} />
        </Button>
        <Select
          bg="white"
          size="md"
          w="100px"
          value={itemsPerPage.toString()}
          onChange={(e) => {
            setPage(1);
            setItemsPerPage(Number(e.target.value));
          }}
          borderRadius="md"
          fontSize="md"
        >
          <option value="10">10 / pág</option>
          <option value="25">25 / pág</option>
          <option value="50">50 / pág</option>
        </Select>
      </Flex>
    </Flex>
  );
};
