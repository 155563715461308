import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { patchWithAuth } from "../../../../services/basicService";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  useToast,
} from "@chakra-ui/react";
import {
  Debt,
  debtTypeTextMap,
  DebtTypeTextMapKey,
} from "../../../../dto/debt-dto";
import { useDebtStore } from "../../../../store/debt.store";
import dayjs from "dayjs";
import { DebtType } from "../../../../enums/debt-type";

const filtersFormSchema = z.object({
  amount: z.number().transform((amount) => amount * 100),
  debtDate: z
    .date()
    .transform((date) =>
      dayjs
        .utc(date)
        .set("hour", 12)
        .set("minute", 0)
        .set("second", 0)
        .local()
        .toDate()
    ),
  type: z.nativeEnum(DebtType),
});
type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function EditCompanyDebt(props: {
  debt: Debt | undefined;
  onClose: () => void;
}) {
  const client = useQueryClient();
  const getDebts = useDebtStore((state) => state.getAll);
  const toast = useToast();

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<filtersFormInput>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      amount: (props.debt?.amount || 0) / 100,
      debtDate: props.debt?.debt_date || new Date(),
      type: props.debt?.type || undefined,
    },
  });

  const editScheduledDebt = useMutation(
    async ({
      debtId,
      data,
    }: {
      debtId: number;
      data: { amount: number; debt_date: Date; type: DebtType };
    }) => {
      await patchWithAuth(`/api/v1/debt/editDebt/${debtId}`, data);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["debts"]);
        getDebts({ page: 1, limit: 10 });
        reset();
        props.onClose();

        toast({
          status: "success",
          title: "Edição realizada com sucesso",
          description: `A edição foi realizada com sucesso!`,
        });
      },
      onError: (error) => {
        toast({
          status: "error",
          title: "Erro ao editar débito",
          description: `Erro: ${error}`,
        });
      },
    }
  );

  const handleEditDebt: SubmitHandler<filtersFormInput> = ({
    amount,
    debtDate,
    type,
  }) => {
    if (editScheduledDebt.isLoading) return;

    editScheduledDebt.mutate({
      debtId: props.debt?.id as number,
      data: { amount, debt_date: debtDate, type: type as DebtType },
    });
  };

  return (
    <ModalContent as="form" onSubmit={handleSubmit(handleEditDebt)}>
      <ModalHeader>Editar Débito</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex flexDirection="row" gap={4}>
          <FormControl
            as="fieldset"
            id="amount"
            w="fit-content"
            isInvalid={!!errors.amount}
          >
            <FormLabel as="legend">Valor:</FormLabel>
            <Input
              placeholder="Insira novo valor"
              type="number"
              pattern="[0-9]+([,][0-9]+)?"
              step="0.01"
              {...register("amount", { valueAsNumber: true })}
            />
          </FormControl>
          <FormControl as="fieldset" id="debt_date" w="fit-content">
            <FormLabel as="legend">Data do Debito:</FormLabel>
            <Input
              placeholder="Insira nova data"
              type="date"
              {...register("debtDate", { valueAsDate: true })}
            />
            {errors.debtDate && (
              <FormErrorMessage>{errors.debtDate.message}</FormErrorMessage>
            )}
            {errors.amount && (
              <FormErrorMessage>{errors.amount.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" id="type" w="fit-content">
            <FormLabel as="legend">Tipo:</FormLabel>
            <Select {...register("type")} placeholder="Selecione o tipo">
              {Object.values(DebtType).map((value) => {
                return (
                  <option key={value} value={value}>
                    {debtTypeTextMap[value as DebtTypeTextMapKey]}
                  </option>
                );
              })}
            </Select>
            {errors.type && (
              <FormErrorMessage>{errors.type.message}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Flex w="full" justifyContent="space-around">
          <Button
            colorScheme="green"
            type="submit"
            loadingText="Salvando"
            isLoading={editScheduledDebt.isLoading}
          >
            Salvar
          </Button>
          <Button
            colorScheme="red"
            onClick={props.onClose}
            disabled={editScheduledDebt.isLoading}
          >
            Cancelar
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  );
}
