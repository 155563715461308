import { FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

type FormInputProps = {
  label: string;
  name: string;
  placeholder: string;
  isTextarea?: boolean;
  isInteger?: boolean;
};

export function FormInput({
  label,
  name,
  placeholder,
  isTextarea = false,
  isInteger = false,
}: FormInputProps) {
  const { register } = useFormContext();

  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      {isTextarea ? (
        <Textarea placeholder={placeholder} {...register(name)} bg="white" />
      ) : (
        <Input
          placeholder={placeholder}
          inputMode={isInteger ? "numeric" : undefined}
          isRequired
          pattern={isInteger ? "\\d*" : undefined} // Aplica a validação apenas se `isInteger` for verdadeiro
          {...register(name, {
            required: true,
            validate: isInteger
              ? (value) => Number.isInteger(Number(value)) // Validação para aceitar apenas inteiros se `isInteger` for true
              : undefined,
          })}
          bg="white"
        />
      )}
    </FormControl>
  );
}
