import { Flex } from "@chakra-ui/layout";
import { Outlet, useNavigation } from "react-router-dom";
import { PointsManagementSubMenu } from "./points-management-submenu";
import { LoadingLogo } from "../../components/loading";

export function PointsManagementLayout() {
  const { state } = useNavigation();

  return (
    <Flex gap={8} w="full" px={4} position="relative">
      <PointsManagementSubMenu />
      {state === "loading" ? (
        <>
          <LoadingLogo />
        </>
      ) : (
        <Outlet />
      )}
    </Flex>
  );
}
