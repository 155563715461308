import { Flex, Text } from "@chakra-ui/layout";
import { ReactNode } from "react";

type IProps = {
  readonly item:
    | {
        readonly label: string;
        readonly value: string;
        readonly icon: ReactNode;
        readonly bg: string;
      }
    | undefined;
};

export function InfoBox({ item }: IProps) {
  if (!item) {
    return null;
  }

  return (
    <Flex
      w="full"
      h="fit-content"
      bg={item.bg}
      px={6}
      py={2}
      borderRadius="xl"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text fontSize="xl" fontWeight="extrabold">
        {item.label}
      </Text>
      <Flex
        w="200px"
        bg="white"
        px={5}
        py={1}
        gap={3}
        border="2px solid black"
        borderRadius="xl"
        alignItems="center"
        justifyContent="center"
      >
        {item.icon}
        <Text fontSize="medium" fontWeight="extrabold">
          {item.value}
        </Text>
      </Flex>
    </Flex>
  );
}
