import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const InfoRow: React.FC<{ label: string; value?: string | number }> = ({
  label,
  value,
}) => (
  <Flex
    justifyContent="space-between"
    px={4}
    py={2}
    borderBottom="1px solid"
    borderColor="gray.200"
    _last={{ borderBottom: "none" }}
  >
    <Text fontSize="md">{label}</Text>
    <Text fontSize="md" fontWeight="extrabold">
      {value}
    </Text>
  </Flex>
);

export default InfoRow;
