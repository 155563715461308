import { create } from "zustand";

interface IActionStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (twoAuthCode: number, twoAuthUuid: string) => Promise<void>;
  setAction: (
    fn: (twoAuthCode: number, twoAuthUuid: string) => Promise<void>
  ) => void;
}

// Criação da store
export const useCloudManagementDebtsActionStore = create<IActionStore>(
  (set) => ({
    action: async (twoAuthCode: number, twoAuthUuid: string): Promise<void> => {
      console.log("Função padrão", twoAuthCode, twoAuthUuid);
    },
    setAction: (fn) => set({ action: fn }),
  })
);
