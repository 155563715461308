import { DebtStatus } from "../../../../../../enums/debt-status";

const statusMap = Object.freeze({
  [DebtStatus.CLOSED]: { label: "Quitado", color: "#1DC161", bg: "#E9F9F0" },
  [DebtStatus.OPEN]: { label: "Aberto", color: "#FF7E46", bg: "#FFF3ED" },
  [DebtStatus.SCHEDULED]: {
    label: "Agendado",
    color: "#D08CEF",
    bg: "#faf4fd",
  },
  [DebtStatus.CANCELED]: {
    label: "Cancelado",
    color: "#6F6F6F",
    bg: "#F4F4F4",
  },
  [DebtStatus.EARLY_OPENED]: {
    label: "Aberto antecipadamente",
    color: "#FF6D3B",
    bg: "#FFF3ED",
  },
} as const);

export type debtStatusMapKey = keyof typeof statusMap;

export function debtStatusMap(debtStatus: debtStatusMapKey | unknown) {
  return (
    statusMap[debtStatus as debtStatusMapKey] ?? {
      label: "Não encontrado",
      color: "#6F6F6F",
      bg: "#F4F4F4",
    }
  );
}
