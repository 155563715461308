import { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AdvanceUserDto } from "../../../../dto/advance-user-dto";
import { convertCentsToReais } from "../../../../utils/convert-money";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

interface Props {
  advances: AdvanceUserDto[];
}

export function UnprocessedAdvances({ advances }: Props) {
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);

  const filteredAdvances = advances.filter((advance) => {
    const hasCompanyFilter = !!urlSearchParams.get("companyName");
    const hasPracaFilter = !!urlSearchParams.get("praca");

    if (hasCompanyFilter && hasPracaFilter) {
      return (
        advance.praca === urlSearchParams.get("praca") &&
        advance.companyName === urlSearchParams.get("companyName")
      );
    }

    if (hasCompanyFilter && !hasPracaFilter) {
      return advance.companyName === urlSearchParams.get("companyName");
    }

    if (!hasCompanyFilter && hasPracaFilter) {
      return advance.praca === urlSearchParams.get("praca");
    }

    return false;
  });

  const maxPages =
    filteredAdvances.length > 0
      ? Math.ceil(filteredAdvances.length / 10)
      : Math.ceil(advances.length / 10);

  const companiesSet = new Set(advances.map((advance) => advance.companyName));
  const pracaSet = new Set(advances.map((advance) => advance.praca));

  return (
    <TableContainer
      w="full"
      display="flex"
      flexDir="column"
      alignItems="center"
      flex={1}
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      shadow="base"
      bg="whiteAlpha.800"
      gap={8}
    >
      <Flex alignSelf="flex-start" flexDir="column" gap={4}>
        <Heading size="lg">Filtros</Heading>
        <Flex gap={4}>
          <FormControl>
            <FormLabel>Empresas</FormLabel>
            <Select
              placeholder="Seleciona uma empresa"
              onChange={(ev) => {
                setURLSearchParams((prev) => {
                  prev.set("companyName", ev.currentTarget.value);
                  return prev;
                });
              }}
            >
              {[...companiesSet].map((company) => (
                <option value={company} key={company}>
                  {company}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Praças</FormLabel>
            <Select
              placeholder="Seleciona uma praca"
              onChange={(ev) => {
                setURLSearchParams((prev) => {
                  prev.set("praca", ev.currentTarget.value);
                  return prev;
                });
              }}
            >
              {[...pracaSet].map((praca) => (
                <option value={praca} key={praca}>
                  {praca}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
      </Flex>

      <Table variant="striped" colorScheme="green">
        <Thead>
          <Tr>
            <Th flex={1} textAlign="center">
              Nome
            </Th>
            <Th textAlign="center">Documento</Th>
            <Th textAlign="center">Valor (R$)</Th>
            <Th textAlign="center">Data da transação</Th>
            <Th textAlign="center">Data da vencimento</Th>
            <Th textAlign="center">N° Contrato</Th>
            <Th textAlign="center">Conta destino</Th>
            <Th textAlign="center">Empresa</Th>
            <Th textAlign="center">Praça</Th>
          </Tr>
        </Thead>
        <Tbody>
          {(filteredAdvances.length > 0 ? filteredAdvances : advances)
            .slice(0, page * 10)
            .map((advance) => (
              <Tr key={advance.id}>
                <Td textAlign="center">{advance.userName}</Td>
                <Td textAlign="center">{advance.document}</Td>
                <Td textAlign="center">
                  {convertCentsToReais(advance.amount - advance.amountFee)}
                </Td>
                <Td textAlign="center">
                  {dayjs(
                    JSON.parse(advance.money_transfer_response ?? "")
                      .transactionDate
                  ).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign="center">
                  {dayjs(advance.due_date).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign="center">{advance.contract_number}</Td>
                <Td textAlign="center">
                  {
                    JSON.parse(advance.money_transfer_response ?? "")
                      .destinationAccount
                  }
                </Td>
                <Td textAlign="center">{advance.companyName}</Td>
                <Td textAlign="center">{advance.praca}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      {page < maxPages ? (
        <Button onClick={() => setPage((prev) => prev + 1)}>
          Carregar mais
        </Button>
      ) : null}
    </TableContainer>
  );
}
