import { DebtType } from "../../../../../../enums/debt-type";

export function debtTypeMapper(type: DebtType): string {
  const map = {
    [DebtType.ADVANCES]: "Empréstimo OL",
    [DebtType.CLOUD_IFOOD_ADVANCE]: "Empréstimo nuvem",
    [DebtType.CLOUD_IFOOD_CASH]: "Cash nuvem",
    [DebtType.SERVICES]: "Serviços",
    [DebtType.LOAN_DEFAULTS]: "Inadimplência de empréstimo",
    [DebtType.OTHERS]: "Outros",
  };

  return map[type];
}
