import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Grid,
  Flex,
  Button,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { formatCPF } from "../../../../utils/format-document";
import InfoRow from "./info-row";
import BoxData from "./box-data";

import { useQuery } from "@tanstack/react-query";
import {
  CurrencyCircleDollar,
  CalendarBlank,
  DownloadSimple,
  Question,
} from "@phosphor-icons/react";
import { LogInfo } from "./log-info";
import { getWithAuth } from "../../../../services/basicService";
import { GetDuplicatedAccountDetails } from "../../../../types/get-duplicated-account-details.type";
import dayjs from "dayjs";
import { GetAuditUserActionType } from "../../../../types/get-audit-user-action.type";
import { useCustomToast } from "./toast";

interface DetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  logId: number;
  debtId?: number;
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  isOpen,
  onClose,
  logId,
  debtId,
}) => {
  const { showCustomToast } = useCustomToast();

  const { data } = useQuery({
    queryKey: [`divergent-account-details`, logId, debtId],
    queryFn: async () => {
      const PATH = `/api/v1/webhook/divergent-account-details?logId=${logId}&debtId=${debtId}`;

      const res = await getWithAuth(PATH);

      return res?.data as GetDuplicatedAccountDetails;
    },
  });

  const { data: dataLog, isLoading: isLoadingLog } = useQuery({
    queryKey: [`divergent-account-log`, "DEBT", debtId],
    queryFn: async () => {
      const PATH = `/api/v1/audit-user-action?entityChanged=DEBT&entityId=${debtId}`;

      const res = await getWithAuth(PATH);
      console.log(res?.data);

      return res?.data as GetAuditUserActionType[];
    },
  });

  const [isloadingExport, setIsloadingExport] = React.useState(false);

  const exportDataLogCSV = () => {
    setIsloadingExport(true);
    if (!dataLog || dataLog.length === 0) {
      showCustomToast("Sem dados para exportar", "error");
      console.log("Nenhum dado para exportar.");
      setIsloadingExport(false);
      return;
    }

    const headers = Object.keys(dataLog[0]);
    const csvRows = [headers.join(",")];

    dataLog.forEach((item) => {
      const values = headers.map((header) => {
        let cell =
          item[header as keyof GetAuditUserActionType] !== null &&
          item[header as keyof GetAuditUserActionType] !== undefined
            ? item[header as keyof GetAuditUserActionType]?.toString()
            : "";
        cell = cell?.replace(/"/g, '""');
        return `"${cell}"`;
      });
      csvRows.push(values.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "dataLog.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsloadingExport(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="51.6rem" borderRadius="2xl">
        <ModalHeader
          fontSize="3xl"
          bg="#fffcf2"
          h="100px"
          w="full"
          pb={8}
          borderTopRadius="3xl"
          boxShadow={" 0px 1px 4px 0px rgba(144, 144, 144, 0.25)"}
        >
          Detalhes
        </ModalHeader>
        <ModalCloseButton top={6} size="lg" color="gray.500" />

        <ModalBody p={4} fontFamily="Source Sans 3, sans-serif">
          <Grid templateColumns="1fr 1fr" gap={6}>
            {/* Left Column */}
            <Box>
              <Box
                borderWidth="1px"
                borderColor="gray.200"
                borderRadius="md"
                mb={6}
              >
                <InfoRow
                  label="ID do débito:"
                  value={data?.debtId?.toString()}
                />
                <InfoRow label="Nome:" value={data?.userName} />
                <InfoRow
                  label="Documento:"
                  value={data?.document && formatCPF(data?.document)}
                />
                <InfoRow
                  label="Conta do driver 1:"
                  value={data?.newAccountId}
                />
                <InfoRow
                  label="Conta do driver 2:"
                  value={data?.oldAccountId}
                />
                <InfoRow
                  label="Conta de recebimento: "
                  value={data?.newAccountId}
                />
              </Box>
            </Box>
            <Box>
              <Box gap={4} display="flex" flexDirection="column">
                <BoxData
                  label="Valor transação"
                  value={data?.amout ? "R$ " + data.amout / 100 : "R$ 0"}
                  icon={CurrencyCircleDollar}
                />
                <BoxData
                  label="Valor creditado"
                  value={
                    data?.debtValue ? "R$ " + data.debtValue / 100 : "R$ 0"
                  }
                  icon={CurrencyCircleDollar}
                />
                <BoxData
                  label="Data transação"
                  value={
                    data?.transferDate
                      ? dayjs(data?.transferDate).format("DD/MM/YYYY")
                      : ""
                  }
                  icon={CalendarBlank}
                />
              </Box>
            </Box>
            <Box gap={4} display={"flex"} flexDirection={"column"}>
              <Flex
                flexDir={"row"}
                width={"100%"}
                alignItems={"center"}
                gap={4}
              >
                <Text
                  fontFamily="Source Sans 3, sans-serif"
                  fontSize={"1.375rem"}
                  whiteSpace="nowrap"
                >
                  Registro de atividades:
                </Text>
                <Tooltip
                  hasArrow
                  placement="top"
                  textAlign="center"
                  fontSize="md"
                  label="Registros de atividades referentes a dívida."
                  borderRadius="md"
                >
                  <Question size={18} />
                </Tooltip>
                <Button
                  w="fit-content"
                  alignItems="center"
                  px={7}
                  py={2}
                  display="flex"
                  justifyContent="center"
                  gap={3}
                  bg="white"
                  border="1px"
                  borderColor="black"
                  borderRadius="3xl"
                  cursor="pointer"
                  _hover={{ bg: "gray.50" }}
                  isLoading={isloadingExport}
                  loadingText="Exportando"
                  disabled={isloadingExport}
                  onClick={() => {
                    exportDataLogCSV();
                  }}
                >
                  <Text
                    fontFamily="Source Sans 3, sans-serif"
                    fontWeight="extrabold"
                    fontSize="medium"
                    color="#0B0B0B"
                  >
                    Exportar .CSV
                  </Text>
                  <DownloadSimple size={25} weight="bold" color="black" />
                </Button>
              </Flex>
              <Flex alignItems={"flex-start"} flexDir={"column"} gap={"0.5rem"}>
                {dataLog &&
                  !isLoadingLog &&
                  dataLog?.map((log, index) => (
                    <LogInfo key={index} data={log} />
                  ))}
              </Flex>
            </Box>
          </Grid>
          <Flex width={"100%"}>
            <Button
              marginLeft={"auto"}
              borderRadius={"6.25rem"}
              bgColor={"#0B0B0B"}
              _hover={{ bg: "#1A1A1A" }}
              onClick={onClose}
            >
              Fechar
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default DetailsModal;
