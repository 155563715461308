import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Text,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Box,
  FormControl,
  FormLabel,
  GridItem,
  Select,
  Divider,
  PortalManager,
} from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  useWatch,
} from "react-hook-form";
import InputDate from "../../../../../components-ui/input-date";
import { DebtStatusMapperEnum } from "./mappers/debt-status-mapper.enum";
import { Trash } from "@phosphor-icons/react";
import { defaultValues, FilterFormInputsValidation } from "./validations/form";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  control: Control<FilterFormInputsValidation>;
  register: UseFormRegister<FilterFormInputsValidation>;
  getValues: UseFormGetValues<FilterFormInputsValidation>;
  refetchs: (() => void)[];
  resetField: UseFormResetField<FilterFormInputsValidation>;
  setIsFilterActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloudManagementDebtsFilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  onClose,
  control,
  register,
  getValues,
  refetchs,
  resetField,
  setIsFilterActive,
}) => {
  const formValues = useWatch({ control });
  const isFormFilled = Object.values(formValues).some((value) => value);

  return (
    <PortalManager zIndex={2000}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="500px"
          h="fit-content"
          borderRadius="2xl"
          overflow="hidden"
        >
          <ModalHeader
            fontSize="3xl"
            bg="#fffcf2"
            h="100px"
            w="full"
            pb={8}
            borderRadius="3xl"
          >
            Filtro
          </ModalHeader>
          <ModalCloseButton top={6} size="lg" color="gray.500" />

          <ModalBody p={4} fontFamily="Source Sans 3, sans-serif">
            <Grid templateColumns="repeat(2, 1fr)" gap={4} placeItems="center">
              <GridItem>
                <Box mb={6}>
                  <Text fontSize="md" mb={2}>
                    ID do Débito
                  </Text>
                  <Input
                    {...register("debtId")}
                    placeholder="Insira o número"
                    size="md"
                    borderRadius="md"
                    bg="white"
                    h="12"
                    fontSize="xl"
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Box mb={6}>
                  <Text fontSize="md" mb={2}>
                    Número do Contrato
                  </Text>
                  <Input
                    {...register("contractNumber")}
                    placeholder="Insira o número"
                    size="md"
                    borderRadius="md"
                    bg="white"
                    h="12"
                    fontSize="xl"
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Box mb={6}>
                  <Text fontSize="md" mb={2}>
                    Documento
                  </Text>
                  <Input
                    {...register("document")}
                    placeholder="Insira o CPF"
                    size="md"
                    borderRadius="md"
                    bg="white"
                    h="12"
                    fontSize="xl"
                  />
                  <Text fontSize="md" color="gray.500">
                    Apenas números
                  </Text>
                </Box>
              </GridItem>
              <GridItem h="full">
                <Box h="full" mb={6} display="flex" flexDir="column" gap={2}>
                  <Text fontSize="md" mb={2}>
                    Ordernação
                  </Text>
                  <FormControl w="fit-content">
                    <Controller
                      name="orderBy"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={onChange} value={value}>
                          <Stack direction="row">
                            <Radio value="ASC" colorScheme="green">
                              <Text
                                fontFamily="Source Sans 3, sans-serif"
                                fontSize="xl"
                              >
                                Crescente
                              </Text>
                            </Radio>
                            <Radio value="DESC" colorScheme="green">
                              <Text
                                fontFamily="Source Sans 3, sans-serif"
                                fontSize="xl"
                              >
                                Decrescente
                              </Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Box>
              </GridItem>
            </Grid>

            {/* Date Fields */}
            <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={6}>
              <InputDate
                control={control}
                name="createdAt"
                label="Data de criação"
                getValues={getValues}
              />
              <InputDate
                control={control}
                name="quitDate"
                label="Data da quitação"
                getValues={getValues}
              />
              <InputDate
                control={control}
                name="debtDate"
                label="Data de vencimento"
                getValues={getValues}
              />
            </Grid>

            <Box>
              <FormControl as="fieldset">
                <FormLabel as="legend">Nome da empresa</FormLabel>
                <Select
                  {...register("companyId")}
                  placeholder="Selecione a empresa"
                  bg="white"
                >
                  <option value={+import.meta.env.VITE_IFOOD_COMPANY_ID}>
                    IFood
                  </option>
                </Select>
              </FormControl>

              <FormControl as="fieldset">
                <FormLabel as="legend">Status do débito</FormLabel>
                <Select
                  {...register("debtStatus")}
                  placeholder="Selecione o status"
                  bg="white"
                >
                  <option value="all">Todos</option>
                  {Object.entries(DebtStatusMapperEnum).map(
                    ([label, value]) => {
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* Action Buttons */}
            <Divider orientation="horizontal" my="7" />
            <Stack
              direction="row"
              justify="flex-end"
              alignItems="center"
              gap={4}
            >
              {isFormFilled && (
                <Button
                  variant="outline"
                  bg="#D73030"
                  color="white"
                  display="flex"
                  gap={2}
                  onClick={() => {
                    Object.keys(defaultValues).forEach((key) =>
                      resetField(key as keyof FilterFormInputsValidation)
                    );
                  }}
                  borderRadius="3xl"
                  px={8}
                  py={6}
                  _hover={{ bg: "#D73030" }}
                >
                  <Text fontSize="medium">Limpar filtro</Text>
                  <Trash size={24} />
                </Button>
              )}
              <Button
                variant="outline"
                size="md"
                onClick={onClose}
                borderRadius="3xl"
                border="1px solid #000"
                fontSize="medium"
                px={8}
                py={6}
              >
                Cancelar
              </Button>
              <Button
                bg={isFormFilled ? "green.500" : "gray.200"}
                _hover={{ bg: isFormFilled ? "green.600" : "gray.300" }}
                color={isFormFilled ? "white" : "black"}
                isDisabled={!isFormFilled}
                size="md"
                fontSize="medium"
                borderRadius="3xl"
                px={8}
                py={6}
                onClick={() => {
                  refetchs.forEach((refetchFn) => refetchFn());
                  onClose();
                  setIsFilterActive(true);
                }}
              >
                Filtrar
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PortalManager>
  );
};
export default CloudManagementDebtsFilterModal;
