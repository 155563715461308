import { useRef, useState } from "react";
import { Flex, HStack, Heading, OrderedList, Text } from "@chakra-ui/layout";
import {
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  List,
  ListItem,
  ListIcon,
  FormControl,
  Select,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  CaretLeft,
  CheckFat,
  Download,
  WarningOctagon,
} from "@phosphor-icons/react";
import { DragDrop, IRef } from "../../../components/forms/fileUpload";
import { AxiosError } from "axios";
import { DeliverymanDto } from "../../../dto/deliveryman-dto";
import { formatCNPJ, formatCPF } from "../../../utils/format-document";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosDataError } from "../../../dto/axios-error-dto";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { getWithAuth } from "../../../services/basicService";
import { Shipper } from "../../../dto/shipper-dto";
import { CreateShipperModalButton } from "../../shipper/components/create-shipper-modal-button";
import { useCurrentUserStore } from "../../../store/current-user.store";

const selectShipperFormSchema = z.object({
  shipperId: z.string(),
});

type selectShipperFormInputs = z.infer<typeof selectShipperFormSchema>;

export function CreateManyDeliverymans() {
  const [deliverymans, setDeliverymans] = useState<DeliverymanDto[]>([]);
  const deliverymansRef = useRef<IRef>(null);
  const uuid = useRef<string>(crypto.randomUUID());
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const me = useCurrentUserStore((state) => state.user);

  const client = useQueryClient();

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const readErrors = useDisclosure({ id: "read-errors" });

  async function handleOnClose() {
    onClose();
    client.refetchQueries(["delivery-mans-data"]);
  }

  async function handleUpload() {
    setErrors([]);
    const csvFounded = deliverymansRef.current?.hasFiles();

    if (!csvFounded) {
      toast({
        status: "error",
        title: "Planilha faltante",
        description: "É necessário enviar uma planilha primeiro",
      });
      return;
    }
    setIsLoading(true);
    try {
      const response = (await deliverymansRef.current?.processUpload({
        sendId: uuid.current,
        extraParam: selectedShipper,
      })) as { createdDeliverymans: DeliverymanDto[]; invalidRows: string[] };
      if (response.createdDeliverymans.length > 0) {
        setDeliverymans(response.createdDeliverymans);
        toast({
          status: "success",
          title: "Recebedores cadastrados com sucesso",
          description: `${
            response.invalidRows.length > 0
              ? "Porém alguns recebedores nao foram cadastrados"
              : "Todos os recebedores foram cadastrados com sucesso"
          }`,
          duration: 4000,
        });
        onOpen();
      }
      if (response.invalidRows.length > 0) {
        setErrors(response.invalidRows);
        toast({
          status: "error",
          title: "Alguns recebedores não foram cadastrados",
          description: "Verifique o botão 'Erros da planilha'",
          duration: 4000,
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      const errResponse = err.response?.data as AxiosDataError;
      let description = "";

      if (err.response?.status === 409 || err.response?.status === 422) {
        description = 'Verifique os erros no botão "Erros da planilha"';
      }

      toast({
        status: "error",
        title: "Não foi possível cadastrar usuários",
        description: description || errResponse.message,
        duration: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const { data: shippers } = useQuery({
    queryKey: ["all-shippers"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/shipper");
      return response?.data as Shipper[];
    },
    initialData: [],
  });

  const { register, watch } = useForm<selectShipperFormInputs>({
    resolver: zodResolver(selectShipperFormSchema),
  });

  const selectedShipper = watch("shipperId");

  return (
    <Flex flexDir="column" alignItems="flex-start" w="full" h="full" gap={8}>
      <Modal
        blockScrollOnMount={true}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastros realizados com sucesso</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold" mb="1rem">
              Total de entregadores cadastrados: {deliverymans?.length}
            </Text>
            <List spacing={3}>
              {deliverymans.length > 0 ? (
                deliverymans.map((deliveryman) => (
                  <ListItem key={deliveryman.id}>
                    <ListIcon as={CheckFat} color="green.600" />
                    Nome: {deliveryman.nameTrampay};{" "}
                    {deliveryman.document &&
                      `CPF: ${formatCPF(deliveryman.document)};`}{" "}
                    {deliveryman.cnpj &&
                      `CNPJ: ${formatCNPJ(deliveryman.cnpj)};`}
                    {deliveryman.pixKey && ` Pix: ${deliveryman.pixKey};`}
                  </ListItem>
                ))
              ) : (
                <></>
              )}
            </List>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleOnClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <HStack gap={8} mt={4}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Criar Entregadores em lote</Heading>
      </HStack>
      <Flex gap={4}>
        <Button
          color="white"
          as="a"
          href="https://storage.googleapis.com/planilhas-prod/modelo-criacao-recebedores.csv"
          download
          gap={4}
        >
          <Download weight="bold" size={24} />
          Modelo
        </Button>
        {me?.company?.hasShippers && <CreateShipperModalButton />}
      </Flex>
      <Flex
        w="full"
        h="full"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        gap="30px"
        bg="whiteAlpha.800"
        border="1px"
        borderRadius={8}
        borderColor={"gray.300"}
      >
        <Heading size="lg" w="full" textAlign="center">
          Insira seu CSV com as informações dos entregadores
        </Heading>
        {errors.length > 0 && (
          <Button
            size="lg"
            variant="ghost"
            bg="red.400"
            _hover={{
              bgColor: "red.300",
            }}
            onClick={readErrors.onOpen}
            display="flex"
            gap={4}
          >
            <WarningOctagon color="#ffff00" size={24} />
            Erros da planilha
          </Button>
        )}
        {me?.company?.hasShippers && (
          <FormControl as="fieldset" maxW={"md"} w="fit-content">
            <Select {...register("shipperId")}>
              <option value="">Selecione um embarcador (opcional)</option>
              {shippers?.map((shipper) => (
                <option
                  value={shipper.id}
                  key={shipper.id}
                  disabled={shipper.status !== "active"}
                >
                  {shipper.status !== "active"
                    ? `${shipper.name} - Inativado`
                    : shipper.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <DragDrop
          uploadUrl="/api/v1/deliveryMan/create-many/by/csv"
          maxFiles={1}
          ref={deliverymansRef}
        />
        <Button
          color="white"
          onClick={handleUpload}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Enviar .CSV
        </Button>
      </Flex>

      <Modal
        isOpen={readErrors.isOpen}
        onClose={readErrors.onClose}
        size="5xl"
        id="read-errors"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent px={4}>
          <ModalHeader>Erros na planilha</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <OrderedList>
              {errors.map((message: string) => (
                <ListItem key={message} fontSize="medium">
                  {message}
                </ListItem>
              ))}
            </OrderedList>
          </ModalBody>

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={readErrors.onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
