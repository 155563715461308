import { TransactionStatus } from "../../../../../../enums/transaction-status";

export function transactionStatusMapper(status: TransactionStatus) {
  const statusMap: Partial<
    Record<
      TransactionStatus,
      { label: string; background: string; color: string }
    >
  > = {
    [TransactionStatus.SUCCESS]: {
      label: "Aprovado",
      background: "#e9f9f0",
      color: "#1DC161",
    },
    [TransactionStatus.PROCESSING]: {
      label: "Processando",
      background: "#f0f0f0",
      color: "#6f6f6f",
    },
    [TransactionStatus.ERROR]: {
      label: "ERRO",
      background: "#FFF3ED",
      color: "red",
    },
  };

  return (
    statusMap[status] ?? {
      label: "Desconhecido",
      background: "#f0f0f0",
      color: "#6f6f6f",
    }
  );
}
