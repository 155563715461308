import {
  Box,
  VStack,
  IconButton,
  Text,
  Flex,
  Tooltip,
  FormControl,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Image,
  useDisclosure,
  Button,
  Spinner,
  InputRightElement,
} from "@chakra-ui/react";
import {
  DownloadSimple,
  MagnifyingGlass,
  Question,
  Trash,
  X,
  PlusSquare,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../services/basicService";
import dayjs from "dayjs";
import { formatCPF } from "../../../../utils/format-document";
import { convertCentsToReais } from "../../../../utils/convert-money";
import { useState } from "react";
import { Pagination } from "../../../../components-ui/pagination";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import EmptySearchIcon from "../../../../assets/empty-search-icon.svg";
import {
  ComposableTable,
  ComposableTableBody,
  ComposableTableCell,
  ComposableTableHead,
  ComposableTableHeaderCell,
  ComposableTableRow,
} from "../../../../components-ui/table";
import StatusBadge from "./status-badge";
import { useCustomToast } from "./toast";
import { LiaSlidersHSolid } from "react-icons/lia";
import { IconBaseProps } from "react-icons";
import FilterModal from "./filter";
import ReopenDebtModal from "./reopen-debt";
import { GetDebtsWithoutPaymentDto } from "../../../../types/get-debts-without-payment.type";
import { DebtStatus } from "../../../../enums/debt-status";

const LiaSlidersHSolidIcon: React.FC<IconBaseProps> =
  LiaSlidersHSolid as unknown as React.FC<IconBaseProps>;

const formSchema = z.object({
  name: z.string().optional(),
  debtId: z.string().optional(),
  document: z.string().optional(),
  createdAt: z.date().optional(),
  transactionDate: z.date().optional(),
  debtDate: z.date().optional(),
  contractCode: z.string().optional(),
  companyId: z.string().optional(),
  debtStatus: z.string().optional(),
  sortDirection: z.string().optional(),
});

export type FilterFormInputsValidation = z.infer<typeof formSchema>;

export function DebtWithoutPayment() {
  const { showCustomToast } = useCustomToast();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure({ id: "modal-details" });
  const { isOpen, onOpen, onClose } = useDisclosure({ id: "filter" });

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [paramsToReopen, setParamsToReopen] = useState({
    id: 0,
    amount: 0,
  });

  const {
    register,
    getValues,
    reset,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FilterFormInputsValidation>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      document: "",
      createdAt: undefined,
      transactionDate: undefined,
      debtDate: undefined,
      contractCode: "",
      companyId: "",
      debtStatus: "",
    },
  });

  const queryParams = () => {
    let query = "";

    const formValues = getValues();

    for (const [key, value] of Object.entries(formValues)) {
      if (value) {
        query += `${key}=${value}&`;
      }
    }

    return query;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [`divergent-account`, page, limit],
    queryFn: async () => {
      let PATH = `/api/v1/debt/not-paid?limit=${limit}&page=${page}&`;
      PATH += queryParams();
      const res = await getWithAuth(PATH);

      return res?.data as GetDebtsWithoutPaymentDto;
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (_: FilterFormInputsValidation) => refetch();

  const [isLoadingExport, setIsloadingExport] = useState(false);

  const exportDataCSV = () => {
    setIsloadingExport(true);
    if (!data?.data || data?.data.length === 0) {
      showCustomToast("Sem dados para exportar", "error");
      console.log("Nenhum dado para exportar.");
      setIsloadingExport(false);
      return;
    }

    const headers = Object.keys(data?.data[0]);
    const csvRows = [headers.join(",")];

    data?.data?.forEach((item) => {
      const values = headers.map((header) => {
        const headerValue = (item as unknown as Record<string, unknown>)[
          header
        ];
        let cell = headerValue?.toString() || "";
        cell = cell?.replace(/"/g, '""');
        return `"${cell}"`;
      });
      csvRows.push(values.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "debitos_sem_pagamento.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsloadingExport(false);
  };

  return (
    <VStack
      gap={6}
      w="full"
      alignItems="flex-start"
      marginTop={6}
      h="calc(100dvh - 183px)"
    >
      <Flex
        bg="white"
        w="full"
        h="full"
        flexDirection="column"
        overflowY="auto"
        overflowX="auto"
        borderRadius="2xl"
      >
        {/* Cards de valores */}
        <Flex
          w="full"
          py={4}
          px={12}
          bg="#FBFBFB"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="3xl"
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
        >
          <Flex gap={8}>
            <Box
              bg="white"
              border="2px solid"
              borderColor="#E2E2E0"
              borderRadius="md"
              py={2}
              px={6}
              background={"var(--Red-50, #FDF4F4)"}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                fontWeight="extrabold"
              >
                Valor total de Débitos
              </Text>
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                color="#D73030"
                fontWeight="bold"
              >
                {isLoading
                  ? "R$ 0,00"
                  : convertCentsToReais(
                      data?.data?.reduce((a, b) => a + (b?.amount ?? 0), 0) || 0
                    )}
              </Text>
            </Box>
          </Flex>

          <Flex gap={4} alignItems="center" w="max-content">
            <Button
              w="fit-content"
              alignItems="center"
              px={7}
              py={2}
              display="flex"
              justifyContent="center"
              gap={3}
              bg="white"
              border="1px"
              borderColor="black"
              borderRadius="3xl"
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              isLoading={isLoadingExport}
              loadingText="Exportando"
              disabled={isLoadingExport}
              onClick={() => {
                exportDataCSV();
              }}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontWeight="extrabold"
                fontSize="medium"
                color="#0B0B0B"
              >
                Exportar .CSV
              </Text>
              <DownloadSimple size={25} weight="bold" color="black" />
            </Button>

            <Tooltip
              hasArrow
              placement="top"
              textAlign="center"
              fontSize="md"
              label="Os dados exportados serão baseados no filtro dessa página."
              borderRadius="md"
            >
              <Question size={20} color="#718096" cursor="pointer" />
            </Tooltip>

            <Box
              as="form"
              display="flex"
              flexDir="column"
              gap={8}
              w="fit-content"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={!!errors.name}>
                <InputGroup display="flex" alignItems="center">
                  <InputLeftElement
                    h="full"
                    display="flex"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => refetch()}
                  >
                    <IconButton
                      as="button"
                      aria-label="Ver detalhes"
                      bg="-moz-initial"
                      icon={
                        <MagnifyingGlass
                          size={16}
                          color="#6f6f6f"
                          weight="bold"
                        />
                      }
                      size="md"
                      variant="ghost"
                      type="submit"
                    />
                  </InputLeftElement>
                  <Input
                    id="name"
                    w={350}
                    size="lg"
                    bg="white"
                    _placeholder={{ color: "gray.500" }}
                    {...register("name")}
                    title="name"
                    placeholder="Buscar por nome"
                  />
                  {(watch("name")?.length || 0) > 0 && (
                    <InputRightElement
                      h="full"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                      onClick={() => {
                        reset();
                        refetch();
                      }}
                    >
                      <X size={20} />
                    </InputRightElement>
                  )}
                </InputGroup>
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <IconButton
              aria-label="Ver detalhes"
              bg="-moz-initial"
              icon={<LiaSlidersHSolidIcon size={30} color="#6F6F6F" />}
              size="md"
              variant="ghost"
              onClick={onOpen}
            />

            {isFilterActive && (
              <Button
                bg="red"
                color="white"
                w="fit-content"
                display="flex"
                gap={2}
                px={7}
                borderRadius="3xl"
                _hover={{ bg: "red.700" }}
                onClick={() => {
                  setIsFilterActive(false);
                  reset();
                  refetch();
                }}
              >
                <Text fontFamily="Source Sans 3, sans-serif" fontSize="md">
                  Limpar filtro
                </Text>
                <Trash size={20} weight="bold" color="white" />
              </Button>
            )}

            <FilterModal
              isOpen={isOpen}
              onClose={onClose}
              control={control}
              refetch={refetch}
              register={register}
              getValues={getValues}
              setIsFilterActive={setIsFilterActive}
            />
          </Flex>
        </Flex>

        {/* Tabela */}
        {!isLoading && data?.totalRecords === 0 ? (
          <Flex
            w="full"
            h="full"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Image
              src={EmptySearchIcon}
              alt="Lupa de busca"
              objectFit="cover"
              loading="eager"
            />
            <Text
              fontFamily="Source Sans 3, sans-serif"
              fontWeight="semibold"
              fontSize="xl"
            >
              Nenhum resultado encontrado...
            </Text>
          </Flex>
        ) : !isLoading ? (
          <ComposableTable>
            <ComposableTableHead>
              <ComposableTableHeaderCell>ID débito</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Nome</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Documento</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Total empréstimo
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>
                Data vencimento
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Empresa</ComposableTableHeaderCell>
              <ComposableTableHeaderCell>Status</ComposableTableHeaderCell>
              <ComposableTableHeaderCell _after={{ border: "none" }}>
                Reabrir débito
              </ComposableTableHeaderCell>
              {/*          <ComposableTableHeaderCell>
                Vendido para
              </ComposableTableHeaderCell> */}
            </ComposableTableHead>
            <ComposableTableBody>
              {data?.data?.map((item, index) => (
                <ComposableTableRow key={item?.id} isStriped={index % 2 !== 0}>
                  <ComposableTableCell>{item?.id}</ComposableTableCell>
                  <ComposableTableCell>
                    {item?.deliveryman?.nameTrampay}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    {item?.deliveryman && formatCPF(item.deliveryman?.document)}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    <Flex alignItems="center" justifyContent="center" gap={1}>
                      {" R$ " + (item.amount || 0) / 100}
                    </Flex>
                  </ComposableTableCell>

                  <ComposableTableCell>
                    {item.debt_date &&
                      dayjs(item.debt_date).format("DD/MM/YYYY")}
                  </ComposableTableCell>
                  <ComposableTableCell>
                    {item?.company?.name}
                  </ComposableTableCell>

                  <ComposableTableCell>
                    <StatusBadge status={item?.status || DebtStatus.CLOSED} />
                  </ComposableTableCell>
                  <ComposableTableCell _after={{ border: "none", padding: 0 }}>
                    <IconButton
                      aria-label="Reabrir debito"
                      bg="-moz-initial"
                      icon={
                        <PlusSquare size={20} weight="bold" color="#6F6F6F" />
                      }
                      size="md"
                      onClick={() => {
                        setParamsToReopen({
                          id: item.id,
                          amount: item.amount,
                        });
                        openModal();
                      }}
                    />
                  </ComposableTableCell>
                </ComposableTableRow>
              ))}
            </ComposableTableBody>
          </ComposableTable>
        ) : (
          <Flex w="full" h="full" alignItems="center" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
      </Flex>
      {isModalOpen && (
        <ReopenDebtModal
          isOpen={isModalOpen}
          onClose={closeModal}
          id={paramsToReopen.id}
          amount={paramsToReopen.amount}
          refetch={refetch}
        />
      )}
      {/* Paginação */}
      {!isLoading && (data?.totalRecords || 0) > 0 && (
        <Pagination
          currentPage={page}
          totalPages={data?.totalPages ?? 0}
          itemsPerPage={limit}
          setPage={setPage}
          setItemsPerPage={setLimit}
        />
      )}
    </VStack>
  );
}
