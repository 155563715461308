/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spinner,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { CaretLeft, Minus, Moped, Plus, Warning } from "@phosphor-icons/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePlazaCachedService } from "../../../services/api/plaza";
import { useSubplazaCachedService } from "../../../services/api/subplazas";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { useCompaniesStore } from "../../../store/company.store";
import { useDeliverymansStore } from "../../../store/deliveryman.store";
import {
  DeliverymanPlazaDto,
  DeliverymanSubplazaDto,
  EditDeliverymanDto,
} from "../../../dto/deliveryman-dto";
import { AxiosDataError } from "../../../dto/axios-error-dto";
import { UserDetailsDisplayOnly } from "../../support/dock-users/details/display-only";
import {
  Item,
  MultiSelect,
} from "../../../components/multiple-select/multiple-select";
import { Chip } from "../../../components/chip/chip";
import { Plaza } from "../../../dto/plaza-dto";
import { WEEKDAYS } from "../../../utils/constants/weekdays";
import { TRAMPAY_COMPANY_ID } from "../../../utils/constants/trampay-company-id";
import { DeliverymanDetails } from "../../support/deliveryman/details";
import { INTEGRATION } from "../../../enums/company-integration";
import { PixKeyType } from "../../../enums/pix-type";
import { TestCNPJ } from "../../../utils/cnpj-validator";
import { useIndicationCode } from "./hook/indication-code.hook";
import { getWithAuth } from "../../../services/basicService";
import { CompanyDto } from "../../../dto/company-dto";
import { GetAuditUserActionType } from "../../../types/get-audit-user-action.type";
import { LogInfo } from "../../support/portfolio-management/activity-history/log-info";

const validationPixError: Record<PixKeyType, string> = {
  cpf: "Chave inválida. A chave CPF precisa ser o mesmo CPF do entregador.",
  email: "Chave inválida. Verifique se o email foi preenchido corretamente.",
  telefone:
    "Chave inválida. Verifique se digitou o ddd + número, totalizando 11 dígitos.",
  aleatorio:
    "Chave inválida. Verifique se a chave aleatória foi copiada corretamente.",
  cnpj: "Chave inválida. Verifique se o CNPJ foi preenchido corretamente",
};

const createDeliverymanFormSchema = z
  .object({
    allowedWorkdays: z.object({
      weekdays: z.array(z.object({ id: z.number(), value: z.string() })),
    }),
    deliverymanSubplazas: z.array(
      z.object({ deliverymanId: z.number(), subplazaId: z.number() })
    ),
    deliverymanPlazas: z.array(
      z.object({ deliverymanId: z.number(), plazaId: z.number() })
    ),
    plazaId: z.string().optional(),
    idEntregador: z.string().optional(),
    nameIfood: z
      .string()
      .nonempty("O nome do entregador no ifood é obrigatório"),
    nameTrampay: z
      .string()
      .nonempty("O nome do entregador na Trampay é obrigatório"),
    document: z
      .string()
      .optional()
      .transform((val) => (val === "" ? null : val)),
    cnpj: z
      .string()
      .optional()
      .transform((val) => (val === "" ? null : val)),
    phone: z.string().nullable().optional().default(""),
    email: z.string().nullable().optional().default(""),
    pixType: z
      .nativeEnum(PixKeyType)
      .optional()
      .or(z.literal(""))
      .transform((val) => (val === "" ? undefined : val)),
    pixKey: z.string().optional(),
    stars: z.number().optional(),
    companyId: z.number().optional(),
  })
  .refine(
    (data) => {
      const isCPFKeyAndIsTheSameAsDelirymanDocument =
        data.pixType === PixKeyType.CPF && data.pixKey !== data.document;

      const isEmailKeyAndIsValid = data?.pixKey
        ? data.pixType === PixKeyType.EMAIL &&
          !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data?.pixKey)
        : false;

      const isPhoneKeyAndIsValid =
        data.pixType === PixKeyType.TELEFONE && data.pixKey?.length !== 11;

      const isRandomKeyAndIsValid = data.pixKey
        ? data.pixType === PixKeyType["CHAVE ALEATORIA"] &&
          !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(
            data.pixKey
          )
        : false;

      const isPixTypeUndefined = data.pixType === undefined && !!data.pixKey;

      const isCNPJKeyAndIsValid =
        data.pixType === PixKeyType.CNPJ && !TestCNPJ(data.pixKey);

      const isDocumentOrCNPJValid =
        (!!data.document && data.document.length === 11) ||
        (!!data.cnpj && data.cnpj.length === 14);

      if (
        isCPFKeyAndIsTheSameAsDelirymanDocument ||
        isEmailKeyAndIsValid ||
        isPhoneKeyAndIsValid ||
        isRandomKeyAndIsValid ||
        isPixTypeUndefined ||
        isCNPJKeyAndIsValid ||
        !isDocumentOrCNPJValid
      ) {
        return false;
      }

      return true;
    },
    (data) => {
      if (!data.document && !data.cnpj) {
        return {
          message: "Preencha pelo menos um dos campos: CPF ou CNPJ.",
          path: ["document"],
        };
      }

      if (data.pixType === undefined) {
        return {
          message: "Selecione o tipo da chave Pix.",
          path: ["pixType"],
        };
      }
      return {
        message: validationPixError[data.pixType as PixKeyType],
        path: ["pixKey"],
      };
    }
  );

type createDeliverymanFormInputs = z.infer<typeof createDeliverymanFormSchema>;

export function EditDeliveryman() {
  const { getIndicationCodeByDocument, indicationCode, indications } =
    useIndicationCode();

  const user = useCurrentUserStore((state) => state.user);
  const deliveryman = useDeliverymansStore((state) => state.deliveryman);

  console.log("deliveryman", deliveryman?.company);

  const companyPermissions = useCompaniesStore(
    (state) => state.companyPermissions
  );

  const isNotUserTrampay = Number(user?.companyId) !== TRAMPAY_COMPANY_ID;
  const hasScaleTool = companyPermissions?.some((cp) => cp.key === "scales");
  const hasIntegration =
    (!!deliveryman?.company?.integration || INTEGRATION.NENHUMA) !==
      INTEGRATION.NENHUMA && isNotUserTrampay;
  const integrationLogged =
    !!deliveryman?.company?.integrations?.ifood?.renewable;

  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();
  const { useQueryAction: usePlazaQueryAction } = usePlazaCachedService();

  const { data: plazasData = [] } = usePlazaQueryAction("filter", {
    companyId: deliveryman?.company?.id,
  });

  const { data: subplazasData = [] } = useSubplazaQueryAction("filter", {
    plazaId: deliveryman?.deliverymanPlazas?.map((dp) => dp.plaza?.id),
  });

  const [plazas, setPlazas] = useState<Plaza[]>([]);
  const [plazasOptions, setPlazasOptions] = useState<Item[]>([]);
  const [selectedPlazaId, setSelectedPlazaId] = useState<number | null>(null);
  const [subplazasOptions, setSubplazasOptions] = useState<Item[]>([]);

  useEffect(() => {
    getIndicationCodeByDocument(deliveryman?.document ?? "");
    setPlazas(deliveryman?.deliverymanPlazas?.map((dp) => dp.plaza) || []);
  }, [deliveryman]);

  useEffect(() => {
    setPlazasOptions(
      plazasData?.map(({ name, id }) => ({ value: name, id: Number(id) })) || []
    );
  }, [plazasData]);

  useEffect(() => {
    setSubplazasOptions(
      subplazasData?.map(({ name, id }) => ({ value: name, id: Number(id) })) ||
        []
    );
  }, [subplazasData]);

  useEffect(() => {
    if (selectedPlazaId) {
      const subplazasForPlaza = subplazasData?.filter(
        (subplaza) => subplaza.plazaId === selectedPlazaId
      );

      setSubplazasOptions(
        subplazasForPlaza?.map(({ name, id }) => ({
          value: name,
          id: Number(id),
        })) || []
      );
    }
  }, [selectedPlazaId, subplazasData]);

  const editDeliveryman = useDeliverymansStore(
    (state) => state.editDeliveryman
  );
  const syncDataDeliveryman = useDeliverymansStore(
    (state) => state.syncDataDeliveryman
  );
  const queryClient = useQueryClient();

  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(deliveryman?.company?.name || "");
  }, [deliveryman]);

  const {
    data: franchisesToSelect,
    isLoading: loadingFranchises,
    refetch: refetchFranchises,
  } = useQuery({
    queryKey: ["franchises" + inputValue],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/companies/list/franchisees/" + inputValue
      );

      return response?.data as Partial<CompanyDto>[];
    },
    enabled: inputValue.length > 0,
  });

  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const options =
    franchisesToSelect?.map((franchise) => ({
      value: franchise.id,
      label: franchise.name,
    })) || [];

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<createDeliverymanFormInputs>({
    resolver: zodResolver(createDeliverymanFormSchema),
    defaultValues: {
      idEntregador: deliveryman?.idEntregador || undefined,
      nameIfood: deliveryman?.nameIfood || undefined,
      nameTrampay: deliveryman?.nameIfood || undefined,
      document: deliveryman?.document || undefined,
      phone: deliveryman?.phone || undefined,
      email: deliveryman?.email || undefined,
      deliverymanPlazas: deliveryman?.deliverymanPlazas || [],
      deliverymanSubplazas: deliveryman?.deliverymanSubplazas || [],
      allowedWorkdays: {
        weekdays: deliveryman?.allowedWorkdays?.weekdays || WEEKDAYS,
      },
      pixType: deliveryman?.pixType || undefined,
      pixKey: deliveryman?.pixKey || undefined,
      stars: deliveryman?.stars || 1,
      cnpj: deliveryman?.cnpj || undefined,
      companyId: deliveryman?.company?.id,
    },
  });

  const [isSyncing, setIsSyncing] = useState(false);

  async function onSubmit(data: createDeliverymanFormInputs) {
    const { plazaId, ...fields } = data;

    const newDeliveryman: EditDeliverymanDto = {
      ...fields,
      deliverymanSubplazas:
        fields?.deliverymanSubplazas as DeliverymanSubplazaDto[],
      deliverymanPlazas: (Number(fields?.deliverymanPlazas.length) > 0
        ? fields?.deliverymanPlazas
        : plazaId
          ? [{ plazaId: Number(plazaId), deliverymanId: Number(id) }]
          : []) as DeliverymanPlazaDto[],
      id: Number(id),
      pixKey:
        fields.pixType === PixKeyType.TELEFONE
          ? `+55${fields.pixKey}`
          : fields.pixKey,
    };

    try {
      if (!id) {
        return toast({
          status: "error",
          title: "Não foi possível editar o entregador",
          description:
            "Não foi possível editar o entregador, contate o suporte",
          duration: 2000,
        });
      }

      await editDeliveryman(newDeliveryman);
      await queryClient.invalidateQueries(["delivery-mans-data"]);
      toast({
        status: "success",
        title: "Entregador editado com sucesso!",
        duration: 2000,
      });
      navigate("/deliverymans");
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error.response?.data as AxiosDataError;

      toast({
        status: "error",
        title: "Não foi possível editar o entregador",
        description: errorData.message,
        duration: 4000,
      });
    }
  }

  async function applySync() {
    const id = deliveryman?.id;

    try {
      if (!id) {
        return toast({
          status: "error",
          title:
            "Não foi possível sincronizar os dados do profissional de entrega",
          description: "Entregador não selecionado",
          duration: 2000,
        });
      }

      setIsSyncing(true);

      await syncDataDeliveryman(id);
      toast({
        status: "success",
        title: "Dados atualizados com sucesso!",
        duration: 2000,
      });
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error.response?.data as AxiosDataError;

      toast({
        status: "error",
        title:
          "Não foi possível sincronizar os dados do profissional de entrega",
        description: errorData.message,
        duration: 4000,
      });
    }

    setIsSyncing(false);
  }

  const { data: dataLog, isLoading: isLoadingLog } = useQuery({
    queryKey: [`divergent-account-log`, "DELIVERYMAN", deliveryman?.id],
    queryFn: async () => {
      const PATH = `/api/v1/audit-user-action?entityChanged=DELIVERYMAN&entityId=${deliveryman?.id}`;

      const res = await getWithAuth(PATH);
      console.log(res?.data);

      return res?.data as GetAuditUserActionType[];
    },
  });

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Editar Entregador</Heading>
      </HStack>

      {!deliveryman?.idEntregador && (
        <Flex
          w="full"
          bg="red.600"
          gap={2}
          p={8}
          borderRadius={8}
          shadow="base"
          color="whiteAlpha.900"
          fontSize="lg"
          flexDir="column"
        >
          <Heading display="flex" alignItems="center" gap={2} size="lg">
            <Warning weight="bold" /> Atenção
          </Heading>
          <Text>
            Por favor insira um ID do entregador, não cadastrar um ID do
            entregador pode acarretar em duplicação de cadastro e/ou erro ao
            adicionar saldos
          </Text>
        </Flex>
      )}
      <Flex
        position="relative"
        as="form"
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
        id="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex gap={8} zIndex={0}>
          <FormControl as="fieldset" isInvalid={!!errors.nameIfood}>
            <FormLabel>Nome</FormLabel>
            <Input {...register("nameIfood")} disabled={hasIntegration} />
            {errors.nameIfood ? (
              <FormErrorMessage>{errors.nameIfood.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.document}>
            <FormLabel>CPF</FormLabel>
            <Input
              {...register("document")}
              disabled={hasIntegration}
              placeholder="Apenas números"
            />
            {errors.document ? (
              <FormErrorMessage>{errors.document.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        <Flex gap={8} zIndex={0}>
          <FormControl as="fieldset" isInvalid={!!errors.idEntregador}>
            <FormLabel>ID do Entregador</FormLabel>
            <Input {...register("idEntregador")} disabled={hasIntegration} />
            {errors.idEntregador ? (
              <FormErrorMessage>{errors.idEntregador.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.cnpj}>
            <FormLabel>CNPJ</FormLabel>
            <Input
              {...register("cnpj")}
              disabled={hasIntegration}
              placeholder="Apenas números"
            />
            {errors.cnpj ? (
              <FormErrorMessage>{errors.cnpj.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        <Flex gap={8} zIndex={0}>
          <FormControl as="fieldset" isInvalid={!!errors.phone}>
            <FormLabel>Telefone</FormLabel>
            <Input {...register("phone")} disabled={hasIntegration} />
            {errors.phone ? (
              <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input {...register("email")} disabled={hasIntegration} />
            {errors.email ? (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        <Flex gap={8} zIndex={0}>
          <FormControl as="fieldset" isInvalid={!!errors.pixType}>
            <FormLabel>Tipo da chave PIX</FormLabel>
            <Select
              placeholder="Selecione o tipo da chave"
              {...register("pixType", {
                onChange: ({ target }) => {
                  if (target.value === PixKeyType.CPF) {
                    return setValue("pixKey", deliveryman?.document);
                  }
                },
              })}
            >
              {Object.entries(PixKeyType).map(([label, value]) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
            {errors.pixType ? (
              <FormErrorMessage>{errors.pixType.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.pixKey}>
            <FormLabel>Chave Pix</FormLabel>
            <Input type="text" {...register(`pixKey`)} />
            {errors.pixKey ? (
              <FormErrorMessage>{errors.pixKey.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        {hasScaleTool && (
          <div>
            <Flex gap={6} zIndex={0}>
              <FormControl mb={4} zIndex={15}>
                <FormLabel>Praça</FormLabel>
                {Number(plazas?.length) === 0 ? (
                  <FormControl as="fieldset" isInvalid={!!errors.plazaId}>
                    <Select
                      placeholder="Selecione uma praça"
                      {...register("plazaId")}
                      onChange={(event) => {
                        const selectedPlazaId = Number(event.target.value);
                        setSelectedPlazaId(selectedPlazaId);
                      }}
                    >
                      {plazasOptions?.map((plaza: Item) => (
                        <option key={plaza.id} value={Number(plaza.id)}>
                          {typeof plaza.value === "string" ? plaza.value : ""}
                        </option>
                      ))}
                    </Select>
                    {errors.plazaId ? (
                      <FormErrorMessage>
                        {errors.plazaId.message}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                ) : (
                  plazas.map((plaza) => (
                    <Chip key={plaza.id} value={plaza?.name} />
                  ))
                )}
              </FormControl>
              <FormControl mb={4} as="fieldset" isInvalid={!!errors.stars}>
                <FormLabel>Classificação entregador</FormLabel>
                <Controller
                  control={control}
                  name="stars"
                  render={({ field }) => (
                    <Flex gap={4}>
                      <Button
                        onClick={() => {
                          if (field.value! === 1) return;
                          field.onChange(field.value! - 1);
                        }}
                        variant="ghost"
                      >
                        <Minus />
                      </Button>
                      {[1, 2, 3, 4, 5].map((_, index) => (
                        <Button
                          key={index}
                          onClick={() => field.onChange(index + 1)}
                          color="green.600"
                          variant="unstyled"
                        >
                          <Moped
                            weight={
                              index + 1 <= field.value! ? "fill" : "regular"
                            }
                            size={32}
                            color="currentColor"
                          />
                        </Button>
                      ))}
                      <Button
                        onClick={() => {
                          if (field.value! === 5) return;
                          field.onChange(field.value! + 1);
                        }}
                        variant="ghost"
                      >
                        <Plus />
                      </Button>
                    </Flex>
                  )}
                />
                {errors.stars ? (
                  <FormErrorMessage>{errors.stars.message}</FormErrorMessage>
                ) : null}
              </FormControl>
            </Flex>

            <Flex gap={8} zIndex={0}>
              <FormControl mb={4}>
                <FormLabel>Sub-praças</FormLabel>
                {Number(subplazasOptions?.length) === 0 ? (
                  "😯 - Sem Sub-praças disponíveis para a praça"
                ) : (
                  <Controller
                    name="deliverymanSubplazas"
                    control={control}
                    render={({ field: { value } }) => (
                      <MultiSelect
                        placeholder={"Selecione uma Sub-Praça"}
                        items={subplazasOptions}
                        onChange={(selectedSubplaza) => {
                          const deliverymanSubplazas = selectedSubplaza.map(
                            (sp) => ({
                              deliverymanId: Number(deliveryman?.id),
                              subplazaId: sp.id,
                            })
                          );
                          setValue(
                            "deliverymanSubplazas",
                            deliverymanSubplazas
                          );
                        }}
                        itemsDefault={
                          value.map((deliverymanSubplazas) => ({
                            id: deliverymanSubplazas.subplazaId,
                            value: subplazasOptions.find(
                              (sp) => sp.id === deliverymanSubplazas.subplazaId
                            )?.value,
                          })) as Item[]
                        }
                      />
                    )}
                  />
                )}
              </FormControl>
              <FormControl mb={4} zIndex={15}>
                <FormLabel>Dias de agendamento</FormLabel>
                <Controller
                  name="allowedWorkdays"
                  control={control}
                  render={({ field: { value } }) => (
                    <MultiSelect
                      placeholder={"Selecione um dia da semana"}
                      items={WEEKDAYS}
                      onChange={(selectedWeekdays) => {
                        const allowedWorkdays = {
                          weekdays: selectedWeekdays.map((wd) => {
                            const { id, value } = wd;
                            return { value: value as string, id };
                          }),
                        };
                        setValue("allowedWorkdays", allowedWorkdays);
                      }}
                      itemsDefault={
                        value?.weekdays.map((weekday) =>
                          WEEKDAYS.find((wd) => wd.id === weekday.id)
                        ) as Item[]
                      }
                    />
                  )}
                />
              </FormControl>
            </Flex>
          </div>
        )}

        {!isNotUserTrampay && (
          <>
            <Flex gap={8}>
              <FormControl as="fieldset">
                <FormLabel>Código de indicação</FormLabel>
                <Input type="text" value={indicationCode} />
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel>Números de indicados</FormLabel>
                <Input type="text" value={indications} />
              </FormControl>
            </Flex>
            <Flex>
              <Box mb={6} position="relative" ref={containerRef}>
                <Text fontSize="xl" mb={2}>
                  Empresa
                </Text>
                <Controller
                  name="companyId"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <>
                      <Text>Empresa atual: {deliveryman?.company?.name}</Text>
                      <Input
                        placeholder="Digite para pesquisar..."
                        value={inputValue}
                        onChange={(e) => {
                          setInputValue(e.target.value);
                          setIsOpen(true);
                          refetchFranchises();
                        }}
                        onFocus={() => setIsOpen(true)}
                      />
                      {isOpen && (
                        <Box
                          position="absolute"
                          top="100%"
                          left={0}
                          right={0}
                          bg="white"
                          border="1px solid"
                          borderColor="gray.200"
                          zIndex="dropdown"
                          maxH="200px"
                          overflowY="auto"
                        >
                          {loadingFranchises ? (
                            <Box p={2} display="flex" alignItems="center">
                              <Spinner size="sm" mr={2} /> Carregando...
                            </Box>
                          ) : options.length > 0 ? (
                            options.map((option) => (
                              <Box
                                key={option.value}
                                p={2}
                                _hover={{ bg: "gray.100", cursor: "pointer" }}
                                onClick={() => {
                                  onChange(option.value);
                                  setInputValue(option.label || "");
                                  setIsOpen(false);
                                }}
                              >
                                {option.label}
                              </Box>
                            ))
                          ) : (
                            <Box p={2}>Nenhum resultado</Box>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                />
              </Box>
            </Flex>
            <Flex alignItems={"flex-start"} flexDir={"column"} gap={"0.5rem"}>
              {dataLog &&
                !isLoadingLog &&
                dataLog?.map((log, index) => (
                  <LogInfo key={index} data={log} />
                ))}
            </Flex>
          </>
        )}

        <HStack alignSelf="flex-end">
          <Button
            type="submit"
            form="form"
            size="lg"
            alignSelf="flex-end"
            isLoading={isSubmitting}
          >
            Editar dados
          </Button>
          {hasIntegration ? (
            <Tooltip
              hasArrow
              label="Integração deslogada"
              bg="red.300"
              color="black"
              isDisabled={integrationLogged}
            >
              <Button
                size="lg"
                alignSelf="flex-end"
                isLoading={isSyncing}
                onClick={applySync}
                _disabled={{
                  opacity: 0.3,
                  cursor: "not-allowed",
                }}
                isDisabled={!integrationLogged}
              >
                Sincronizar dados
              </Button>
            </Tooltip>
          ) : null}
        </HStack>
      </Flex>

      {isNotUserTrampay ? (
        <UserDetailsDisplayOnly
          document={deliveryman?.document as string}
          externalId={deliveryman?.idEntregador as string}
          status={deliveryman!.status}
          createdAt={deliveryman!.createdAt}
          lastActiveDate={deliveryman!.lastActiveDate}
        />
      ) : deliveryman ? (
        <DeliverymanDetails deliveryman={deliveryman} />
      ) : null}
    </Flex>
  );
}
