import { useQuery } from "@tanstack/react-query";
import { Box, Flex, Text, VStack } from "@chakra-ui/layout";
import { getWithAuth } from "../../../../../services/basicService";
import {
  ComposableTable,
  ComposableTableBody,
  ComposableTableCell,
  ComposableTableHead,
  ComposableTableHeaderCell,
  ComposableTableRow,
} from "../../../../../components-ui/table";
import {
  DebtCloudInfoDto,
  GetDebtsCloudPaginationDto,
} from "../../../../../types/get-debts-cloud.type";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import { Pagination } from "../../../../../components-ui/pagination";
import { ReactNode, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tooltip,
  useDisclosure,
  Image,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  DotsThreeVertical,
  DownloadSimple,
  MagnifyingGlass,
  Question,
  Trash,
  X,
} from "@phosphor-icons/react";
import { LiaSlidersHSolid } from "react-icons/lia";
import { IconBaseProps } from "react-icons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import CloudManagementDebtsFilterModal from "./filter";
import {
  defaultValues,
  FilterFormInputsValidation,
  formSchema,
} from "./validations/form";
import { useExportCsv } from "../../../../../hooks/adiantamento/use-export-csv";
import { CloudManagementDebtsActionsModal } from "./actions/modal";
import { CloudManagementDebtsActionsDetails } from "./actions/details";
import EmptySearchIcon from "../../../../../assets/empty-search-icon.svg";
import { debtStatusMapper } from "./functions/debt-status-mapper";
import { formatCPF } from "../../../../../utils/format-document";
import dayjs from "dayjs";
import { CloudManagementDebtsActionsCancelContent } from "./actions/modal-content/cancel-content";
import { CloudManagementDebtsActionsModalContentPayOff } from "./actions/modal-content/pay-off-content";
import { CloudManagementDebtsActionsModalContentReverse } from "./actions/modal-content/reverse-content";
import { DebtStatus } from "../../../../../enums/debt-status";

const LiaSlidersHSolidIcon: React.FC<IconBaseProps> =
  LiaSlidersHSolid as unknown as React.FC<IconBaseProps>;

type IUseRefProps = {
  content: ReactNode;
  action: "cancel" | "pay off" | "reverse" | null;
  debtId: number | null;
};

export function CloudManagemetDebts() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: "cloud-management-modal-filter",
  });
  const {
    isOpen: isModalActionOpen,
    onClose: onModalActionClose,
    onOpen: onModalActionOpen,
  } = useDisclosure({
    id: "cloud-management-modal-action",
  });
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
  } = useDisclosure({
    id: "cloud-management-debt-details",
  });
  const modalActionContent = useRef<IUseRefProps>({
    content: null,
    action: null,
    debtId: null,
  });
  const { exportCsv, loading: loadingExportCsv } = useExportCsv();

  const {
    register,
    getValues,
    control,
    resetField,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FilterFormInputsValidation>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const queryParams = () => {
    let query = "";

    const formValues = getValues();

    for (const [key, value] of Object.entries(formValues)) {
      if (key === "debtStatus" && value === "all") {
        continue;
      }

      if (value) {
        query += `${key}=${value}&`;
      }
    }

    return query;
  };

  const { data, refetch, isFetching } = useQuery({
    queryKey: [`cloud-management-debts-pagination`, page, limit],
    queryFn: async () => {
      let PATH = `/api/v1/debt/cloud/management/?limit=${limit}&page=${page}&`;
      PATH += queryParams();

      const res = await getWithAuth(PATH);

      return res?.data as GetDebtsCloudPaginationDto;
    },
  });

  const {
    data: info,
    isFetching: isFetchingInfo,
    refetch: refetchInfo,
  } = useQuery({
    queryKey: [`cloud-management-debts-info`],
    queryFn: async () => {
      let PATH = `/api/v1/debt/cloud/management/info/?`;
      PATH += queryParams();

      const res = await getWithAuth(PATH);

      return res?.data as DebtCloudInfoDto;
    },
  });

  return (
    <VStack
      gap={6}
      w="full"
      alignItems="flex-start"
      marginTop={6}
      h="calc(100dvh - 183px)"
    >
      <Flex
        bg="white"
        w="full"
        h="full"
        flexDirection="column"
        overflowY="auto"
        overflowX="auto"
        borderRadius="2xl"
      >
        <Flex
          w="full"
          py={4}
          px={12}
          bg="#FBFBFB"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="3xl"
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
        >
          <Flex gap={8}>
            <Box
              bg="white"
              border="2px solid"
              borderColor="#E2E2E0"
              borderRadius="md"
              py={2}
              px={6}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                fontWeight="extrabold"
              >
                Total débitos pagos
              </Text>
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                color="#1DC161"
                fontWeight="bold"
              >
                {isFetchingInfo ? (
                  <Flex
                    w="full"
                    h="full"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="md"
                    />
                  </Flex>
                ) : (
                  convertCentsToReais(info?.totalPaidAmount ?? 0)
                )}
              </Text>
            </Box>
            <Box
              bg="#FDF4F4"
              border="2px solid"
              borderColor="#E2E2E0"
              borderRadius="md"
              py={2}
              px={6}
            >
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                fontWeight="extrabold"
              >
                Valor total cobranças indevidas
              </Text>
              <Text
                fontFamily="Source Sans 3, sans-serif"
                fontSize="xl"
                color="#D73030"
                fontWeight="bold"
              >
                {isFetchingInfo ? (
                  <Flex
                    w="full"
                    h="full"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="md"
                    />
                  </Flex>
                ) : (
                  convertCentsToReais(info?.totalRefundAmount ?? 0)
                )}
              </Text>
            </Box>
          </Flex>

          <Flex gap={4} alignItems="center" w="max-content">
            {isFilterActive && (
              <Button
                w="fit-content"
                alignItems="center"
                px={7}
                py={2}
                display="flex"
                justifyContent="center"
                gap={3}
                bg="white"
                border="1px"
                borderColor="black"
                borderRadius="3xl"
                cursor="pointer"
                _hover={{ bg: "gray.50" }}
                isLoading={loadingExportCsv}
                loadingText="Exportando"
                disabled={loadingExportCsv}
                onClick={() => {
                  exportCsv(
                    "/api/v1/debt/cloud/management/csv",
                    "debts-cloud",
                    queryParams()
                  );
                }}
              >
                <Text
                  fontFamily="Source Sans 3, sans-serif"
                  fontWeight="extrabold"
                  fontSize="medium"
                  color="#0B0B0B"
                >
                  Exportar .CSV
                </Text>
                <DownloadSimple size={25} weight="bold" color="black" />
              </Button>
            )}

            <Tooltip
              hasArrow
              placement="top"
              textAlign="center"
              fontSize="md"
              label="Os dados exportados serão baseados no filtro dessa página."
              borderRadius="md"
            >
              <Question size={20} color="#718096" cursor="pointer" />
            </Tooltip>

            <Box
              as="form"
              display="flex"
              flexDir="column"
              gap={8}
              w="fit-content"
              onSubmit={handleSubmit(() => {
                refetch();
                refetchInfo();
              })}
            >
              <FormControl isInvalid={!!errors.nameOrDocument}>
                <InputGroup display="flex" alignItems="center">
                  <InputLeftElement
                    h="full"
                    display="flex"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => {
                      refetch();
                      refetchInfo();
                    }}
                  >
                    <IconButton
                      as="button"
                      aria-label="Ver detalhes"
                      bg="-moz-initial"
                      icon={
                        <MagnifyingGlass
                          size={16}
                          color="#6f6f6f"
                          weight="bold"
                        />
                      }
                      size="md"
                      variant="ghost"
                      type="submit"
                    />
                  </InputLeftElement>
                  <Input
                    id="nameOrDocument"
                    w={350}
                    size="lg"
                    bg="white"
                    _placeholder={{
                      color: "gray.500",
                    }}
                    {...register("nameOrDocument")}
                    title="nameOrDocument"
                    placeholder="Buscar por nome ou documento"
                  />
                  {(watch("nameOrDocument")?.length ?? 0) > 0 && (
                    <InputRightElement
                      h="full"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                      onClick={() => {
                        Object.keys(defaultValues).forEach((key) =>
                          resetField(key as keyof FilterFormInputsValidation)
                        );
                        refetch();
                        refetchInfo();
                      }}
                    >
                      <X size={20} />
                    </InputRightElement>
                  )}
                </InputGroup>
                <FormErrorMessage>
                  {errors.nameOrDocument?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <IconButton
              aria-label="Ver detalhes"
              bg="-moz-initial"
              icon={<LiaSlidersHSolidIcon size={30} color="#6F6F6F" />}
              size="md"
              variant="ghost"
              onClick={onOpen}
            />

            {isFilterActive && (
              <Button
                bg="red"
                color="white"
                w="fit-content"
                display="flex"
                gap={2}
                px={7}
                borderRadius="3xl"
                _hover={{ bg: "red.700" }}
                onClick={() => {
                  setIsFilterActive(false);
                  Object.keys(defaultValues).forEach((key) =>
                    resetField(key as keyof FilterFormInputsValidation)
                  );
                  refetch();
                  refetchInfo();
                }}
              >
                <Text fontFamily="Source Sans 3, sans-serif" fontSize="md">
                  Limpar filtro
                </Text>
                <Trash size={20} weight="bold" color="white" />
              </Button>
            )}
            <CloudManagementDebtsFilterModal
              control={control}
              getValues={getValues}
              isOpen={isOpen}
              onClose={onClose}
              refetchs={[refetch, refetchInfo]}
              register={register}
              resetField={resetField}
              setIsFilterActive={setIsFilterActive}
            />
          </Flex>
        </Flex>
        {!isFetching && data?.debts.totalRecords === 0 ? (
          <Flex
            w="full"
            h="full"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Image
              src={EmptySearchIcon}
              alt="Lupa de busca"
              objectFit="cover"
              loading="eager"
            />
            <Text
              fontFamily="Source Sans 3, sans-serif"
              fontWeight="semibold"
              fontSize="xl"
            >
              Nenhum resultado encontrado...
            </Text>
          </Flex>
        ) : !isFetching ? (
          <ComposableTable>
            <ComposableTableHead>
              <ComposableTableHeaderCell fontSize="sm">
                ID débito
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Nome
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Documento
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Total débito
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Data quitação
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Data vencimento
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Empresa
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Data lançamento
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Status
              </ComposableTableHeaderCell>
              <ComposableTableHeaderCell fontSize="sm">
                Ações
              </ComposableTableHeaderCell>
            </ComposableTableHead>
            <ComposableTableBody>
              {data?.debts?.result?.map((item, index) => {
                const { label, color, background } = debtStatusMapper(
                  item.status
                );

                return (
                  <ComposableTableRow key={item.id} isStriped={index % 2 !== 0}>
                    <ComposableTableCell fontSize="md">
                      {item.id}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      {item.deliveryman?.nameIfood}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      {formatCPF(item.deliveryman!.document)}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      {convertCentsToReais(item.amount)}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      {item.quit_date &&
                        dayjs(item.quit_date).format("DD/MM/YYYY")}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      {item.debt_date &&
                        dayjs(item.debt_date).format("DD/MM/YYYY")}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      {item.company.name}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      {dayjs(item.created_at).format("DD/MM/YYYY")}
                    </ComposableTableCell>
                    <ComposableTableCell fontSize="md">
                      <Flex w="full" justifyContent="center">
                        <Text
                          w="fit-content"
                          px={6}
                          py={2}
                          bg={background}
                          color={color}
                          borderRadius="xl"
                          fontFamily="Source Sans 3, sans-serif"
                          fontWeight="bold"
                          fontSize="md"
                        >
                          {label}
                        </Text>
                      </Flex>
                    </ComposableTableCell>
                    <ComposableTableCell display="flex" justifyContent="center">
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          variant="ghost"
                          backgroundColor="inherit"
                          icon={
                            <DotsThreeVertical
                              size={28}
                              color="#6f6f6f"
                              weight="bold"
                              cursor="pointer"
                            />
                          }
                        />
                        <MenuList
                          p={3}
                          display="flex"
                          flexDirection="column"
                          gap={4}
                          fontFamily="Source Sans 3, sans-serif"
                        >
                          <MenuItem
                            fontSize="medium"
                            onClick={() => {
                              modalActionContent.current.debtId = item.id;
                              onDrawerOpen();
                            }}
                          >
                            Detalhes da dívida
                          </MenuItem>
                          {item.status !== DebtStatus.CANCELED && (
                            <MenuItem
                              fontSize="medium"
                              onClick={() => {
                                modalActionContent.current = {
                                  action: "cancel",
                                  debtId: item.id,
                                  content: (
                                    <CloudManagementDebtsActionsCancelContent
                                      debtId={item.id}
                                    />
                                  ),
                                };
                                onModalActionOpen();
                              }}
                            >
                              Cancelar débito
                            </MenuItem>
                          )}
                          {item.status !== DebtStatus.CLOSED && (
                            <MenuItem
                              fontSize="medium"
                              onClick={() => {
                                modalActionContent.current = {
                                  action: "pay off",
                                  debtId: item.id,
                                  content: (
                                    <CloudManagementDebtsActionsModalContentPayOff
                                      debt={item}
                                      debitReceivingAccountId={
                                        data.debitReceivingAccountId
                                      }
                                    />
                                  ),
                                };
                                onModalActionOpen();
                              }}
                            >
                              Quitar débito
                            </MenuItem>
                          )}
                          <MenuItem
                            fontSize="medium"
                            onClick={() => {
                              modalActionContent.current = {
                                action: "reverse",
                                debtId: item.id,
                                content: (
                                  <CloudManagementDebtsActionsModalContentReverse
                                    debt={item}
                                    debitReceivingAccountId={
                                      data.debitReceivingAccountId
                                    }
                                  />
                                ),
                              };
                              onModalActionOpen();
                            }}
                          >
                            Estornar débito
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </ComposableTableCell>
                  </ComposableTableRow>
                );
              })}
            </ComposableTableBody>
          </ComposableTable>
        ) : (
          <Flex w="full" h="full" alignItems="center" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
        <CloudManagementDebtsActionsModal
          isOpen={isModalActionOpen}
          onClose={onModalActionClose}
          action={modalActionContent.current.action}
          debtId={modalActionContent.current.debtId}
        >
          {modalActionContent.current.content}
        </CloudManagementDebtsActionsModal>
      </Flex>
      {!isFetching && (data?.debts?.totalRecords ?? 0) > 0 && (
        <Pagination
          currentPage={page}
          totalPages={data?.debts.totalPages ?? 0}
          itemsPerPage={limit}
          setPage={setPage}
          setItemsPerPage={setLimit}
        />
      )}
      {modalActionContent.current.debtId && (
        <CloudManagementDebtsActionsDetails
          debtId={modalActionContent.current.debtId}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
        />
      )}
    </VStack>
  );
}
