export enum DivergenceLog {
  RESOLVED = "resolved",
  PENDING = "pending",
}

export interface GetDuplicatedAccount {
  id?: number;
  debtId?: number;
  userName?: string;
  document?: string;
  debtValue?: number;
  paymentDate?: Date;
  dueDate?: Date;
  companyName?: string;
  createdAt?: Date;
  status?: DivergenceLog;
}

export interface GetDuplicatedAccountDto {
  data: GetDuplicatedAccount[];
  page: number;
  limit: number;
  totalRecords: number;
  totalPages: number;
  nextPage: boolean;
}
